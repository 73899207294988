import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import * as StaffStore from '../../../store/reducers/staff'
import * as ReportsStore from '../../../store/reducers/reports'
import {
  Dimmer,
  Loader,
  Table,
  Container,
  Header,
  Divider
} from 'semantic-ui-react'
const qs = require('qs')
const _ = require('underscore')

type ReportProps =
    { report: any, connected: boolean, options: any, settings: any } &
    typeof StaffStore.actionCreators &
    typeof ReportsStore.actionCreators &
    WithRouterProps<{}>

class DescriptionsHTML extends React.PureComponent<ReportProps> {
    public componentDidMount() {
        this.props.fetchFacilities()
        this.props.fetchSettings()
        this.props.socketConnect()
    }

    public componentDidUpdate(prevProps:any) {
      if (!prevProps.connected && this.props.connected && !this.props.report) {
          this.props.generateDescriptions()
      }
    }

    public render() {
      if (!this.props.report || this.props.report.loading) {
        return (
          <Fragment>
            <Dimmer active inverted>
              <Loader content='Generating report...' />
            </Dimmer>
          </Fragment>
        )
      } else {
        let params = qs.parse(window.location.search.substr(1, window.location.search.length));
        let data = this.props.report.generated
        let options = this.props.options
        let facilities = typeof params.facilities == 'string' ? [params.facilities] : params.facilities

        return (
          <Container fluid>
            <div style={{textAlign:'center',marginBottom:15}}>
              <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14,marginBottom:5}}>Class Descriptions</div><div style={{fontSize:12}}>{`${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
            </div>
            <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
              <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Filter by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.filterBy == 'templates' ? 'Active templates' : 'Active classes'}</div></div>
              <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
              <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.programs == 0 ? 'All' : _.map((typeof params.programs == 'string' ? [params.programs] : params.programs), (id:any) => _.any(data.programs, (p:any) => p.recordID == id) ? _.find(data.programs, (p:any) => p.recordID == id).name : '').join(', ')}</div></div>
            </div>
            {data.programs.map((program:any, p:number) => (
              <Fragment key={p}>
                <div style={{padding:'18px 0 18px 10px',backgroundColor:'#efefef',borderTop:'1px solid rgba(34, 36, 38, 0.1)',borderBottom:'1px solid rgba(34, 36, 38, 0.1)'}} key={p}>
                  <Header as='h1' style={{marginBottom:0}}>{program.name}</Header>
                  {program.description ? <div style={{marginTop:3}}>{program.description}</div> : null}
                </div>
                {program.courses.map((course:any, c:number) => (
                  <Fragment key={c}>
                    <div style={{padding:'12px 0 12px 40px',backgroundColor:'#fafafa',borderTop:'1px solid rgba(34, 36, 38, 0.1)',borderBottom:'1px solid rgba(34, 36, 38, 0.1)'}} key={c}>
                      <Header as='h2' style={{marginBottom:0}}>{course.name}</Header>
                      {course.description ? <div style={{marginTop:3}}>{course.description}</div> : null}
                    </div>
                    <div style={{padding:'5px 0 15px',backgroundColor:'white'}}>
                      {course.templates.map((template:any, t:number) => (
                        <Fragment key={t}>
                          <div style={{padding:'6px 0 6px 70px'}} key={t}>
                            <Header as='h3' style={{marginBottom:0,display:'inline-block',borderBottom:'thin solid black'}}>{template.name}</Header>
                            {template.description ? <div style={{marginTop:3}} dangerouslySetInnerHTML={{__html: template.description}}></div> : null}
                          </div>
                          <div style={{padding:'5px 0 15px',backgroundColor:'white'}}>
                            {template.classes.map((klass:any, k:number) => (
                              <div style={{padding:'3px 0 3px 100px'}} key={k}>
                                <Header as='h5' style={{marginBottom:0}}>{klass.name}</Header>
                              </div>
                            ))}
                          </div>
                        </Fragment>
                      ))}
                    </div>
                  </Fragment>
                ))}
              </Fragment>
            ))}
            <div className='footer'>
              <div className='left'>
                  {new Date().today() + " @ " + new Date().timeNow()}
              </div>
              <div className='right'>
                  {data.staffID} / {data.printID}
              </div>
              <div className='center'>{this.props.report.copyright}</div>
            </div>
          </Container>
        )
      }
    }
}

export default connect(
  (state: ApplicationState) => { return { report: state.reports.reports.descriptions, connected: state.reports.connected, options: state.reports.options, settings: state.staff.settings } },
  { ...StaffStore.actionCreators, ...ReportsStore.actionCreators }
)(DescriptionsHTML as any)
