import React from 'react'
import { Action, Reducer } from 'redux'
import { AppThunkAction } from '../'
import { actionCreators as paneActions } from './panes'
import { actionCreators as staffActions } from './staff'
import { createEditorState } from 'medium-draft'
import { toast } from 'react-toastify'
import { Button } from 'semantic-ui-react'
import agent from '../../agent'
const store = require('store2')
const _ = require('underscore')

interface ToggleAction { type: 'TOGGLE_DASHBOARD' }
interface SetFacilityAction { type: 'SET_DASHBOARD_FACILITY', facility: string }
interface ToggleExtendAction { type: 'TOGGLE_DASHBOARD_EXTEND' }
interface ToggleNotificationAction { type: 'TOGGLE_DASHBOARD_NOTIFICATION' }
interface ToggleSearchAction { type: 'TOGGLE_DASHBOARD_SEARCH' }
interface ToggleArchiveAction { type: 'TOGGLE_DASHBOARD_ARCHIVE' }
interface ToggleExpireAction { type: 'TOGGLE_DASHBOARD_EXPIRE' }
interface ToggleClassAction { type: 'TOGGLE_DASHBOARD_CLASS' }
interface ToggleOrderAction { type: 'TOGGLE_DASHBOARD_ORDER', table: string, sorts: any[] }
interface SelectClassAction { type: 'SELECT_EXTEND_CLASS', class: any }
interface SelectExtendAction { type: 'SELECT_EXTEND_STAFF', staff: any, date: any }
interface SelectArchiveAction { type: 'SELECT_ARCHIVE_STAFF', staff: any }
interface SelectExpireAction { type: 'SELECT_EXPIRE_STAFF', staff: any }
interface UpdateNotificationDataAction { type: 'UPDATE_NOTIFICATION_DATA', key: string, value: any }
interface UpdateExtendAction { type: 'UPDATE_DASHBOARD_EXTEND', key: string, value: any }
interface UpdateClassAction { type: 'UPDATE_DASHBOARD_CLASS', key: string, value: any }
export interface FetchNotificationsAction { type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: any }
interface FetchNewsAction { type: 'FETCH_DASHBOARD_NEWS', announcements: any, notices: any }
interface FetchStaffAction { type: 'FETCH_DASHBOARD_STAFF', data: any[], notify: boolean, collapse: boolean }
interface FetchOrphansAction { type: 'FETCH_DASHBOARD_ORPHANS', data: any[], notify: boolean, collapse: boolean }
interface FetchApprovalsAction { type: 'FETCH_DASHBOARD_APPROVALS', data: any[], notify: boolean, collapse: boolean }
interface FetchClassesAction { type: 'FETCH_DASHBOARD_CLASSES', data: any[], notify: boolean, collapse: boolean }
interface FetchTABEsAction { type: 'FETCH_DASHBOARD_TABES', data: any[] }
interface FetchSchoolsAction { type: 'FETCH_DASHBOARD_SCHOOLS', data: any[], notify: boolean, collapse: boolean }
interface FetchTranscriptsAction { type: 'FETCH_DASHBOARD_TRANSCRIPTS', data: any[], notify: boolean, collapse: boolean }
interface FetchFundingAction { type: 'FETCH_DASHBOARD_FUNDING', data: any[], notify: boolean, collapse: boolean }
interface FetchInstructorsAction { type: 'FETCH_DASHBOARD_INSTRUCTORS', data: any[], notify: boolean, collapse: boolean }
interface FetchArrivalsAction { type: 'FETCH_DASHBOARD_ARRIVALS', data: any[], notify: boolean, collapse: boolean }
interface FetchPendingAction { type: 'FETCH_DASHBOARD_PENDING', data: any[], notify: boolean, collapse: boolean }
interface FetchAttendanceAction { type: 'FETCH_DASHBOARD_ATTENDANCE', data: any[], notify: boolean, collapse: boolean }
interface FetchANDsAction { type: 'FETCH_DASHBOARD_ANDS', data: any[] }
interface FetchSurveysAction { type: 'FETCH_DASHBOARD_SURVEYS', data: any[] }
interface FetchOptionsAction { type: 'FETCH_DASHBOARD_OPTIONS', facilities: any[], order: any[] }
interface SelectSearchAction { type: 'SELECT_SEARCH_RESULT', key: string, value: any }
interface SelectInmateAction { type: 'SELECT_ORPHAN_INMATE', recordID: number, key: string, value: any }
interface SelectInmateNotificationAction { type: 'SELECT_ORPHAN_INMATE_NOTIFICATION', recordID: number, key: string, value: any }
interface InmatesLoadingAction { type: 'SEARCH_ORPHANS_LOADING', recordID: number, value: any }
interface InmatesLoadingNotificationAction { type: 'SEARCH_ORPHANS_LOADING_NOTIFICATION', recordID: number, value: any }
interface SearchInmatesAction { type: 'SEARCH_ORPHANS', recordID: number, inmates: any[] }
interface SearchInmatesNotificationAction { type: 'SEARCH_ORPHANS_NOTIFICATION', recordID: number, inmates: any[] }
interface SearchUniversalAction { type: 'SEARCH_UNIVERSAL_RESULTS', results: any[] }
interface SearchUniversalLoadingAction { type: 'SEARCH_UNIVERSAL_LOADING', value: any }
interface OpenUniversalAction { type: 'OPEN_UNIVERSAL_RESULTS' }
interface CloseUniversalAction { type: 'CLOSE_UNIVERSAL_RESULTS' }
interface OpenSurveyAction { type: 'OPEN_SURVEY', id: number, name: string, questions: any[], grids: any[] }
interface OpenNotificationAction { type: 'OPEN_NOTIFICATION', notification: any, num: number }
interface CloseSurveyAction { type: 'CLOSE_SURVEY' }
interface SelectOptionAction { type: 'SELECT_OPTION', questionID: number, optionID: number }
interface SelectGridAction { type: 'SELECT_GRID_OPTION', gridID: number, questionID: number, optionID: number }
interface UpdateNotificationAction { type: 'UPDATE_STAFF_NOTIFICATION', name: string, checked: boolean }
interface LoadingDashboardReportAction { type: 'LOADING_DASHBOARD_REPORT', report: string, loading: boolean }
interface GenerateDashboardReportAction { type: 'GENERATE_DASHBOARD_REPORT', report: string, data: any }
interface SelectPendingNotificationAction { type: 'SELECT_PENDING_NOTIFICATION', sessionID: number, notes: string, rosters: any[] }
interface PendingChangeNotificationAction { type: 'PENDING_CHANGE_NOTIFICATION', rosterID: number, log: string, selected: any }
interface PendingClassChangeNotificationAction { type: 'PENDING_CLASS_CHANGE_NOTIFICATION', rosterID: number, log: string, selected: any }
interface UpdatePendingNotesNotificationAction { type: 'UPDATE_PENDING_NOTES_NOTIFICATION', value: any }
interface ToggleCollapseAction { type: 'TOGGLE_DASHBOARD_COLLAPSE', table: DashboardIndex }
interface UpdateFilterAction { type: 'UPDATE_DASHBOARD_FILTER', table: DashboardIndex, key: string, value: any }
interface SetURLAction { type: 'SET_DASHBOARD_URL', report: string, url: string }
interface SetRefreshAction { type: 'SET_DASHBOARD_REFRESH', refresh: number }
interface SubmitPendingAction { type: 'SUBMIT_DASHBOARD_ATTENDANCE', sessionID: number }

type KnownAction = SubmitPendingAction | ToggleCollapseAction | UpdateFilterAction | SetRefreshAction | SetURLAction | SelectPendingNotificationAction | UpdatePendingNotesNotificationAction | PendingChangeNotificationAction | PendingClassChangeNotificationAction | LoadingDashboardReportAction | GenerateDashboardReportAction | UpdateNotificationAction | FetchOptionsAction | OpenSurveyAction | OpenNotificationAction | CloseSurveyAction | SelectOptionAction | SelectGridAction | SearchInmatesAction | SearchInmatesNotificationAction | InmatesLoadingAction | InmatesLoadingNotificationAction | SelectSearchAction | SelectInmateAction | SelectInmateNotificationAction | ToggleAction | SetFacilityAction | ToggleOrderAction | ToggleClassAction | ToggleArchiveAction | ToggleExpireAction | ToggleExtendAction | ToggleSearchAction | ToggleNotificationAction | SelectClassAction | SelectArchiveAction | SelectExpireAction | SelectExtendAction | UpdateClassAction | UpdateExtendAction | UpdateNotificationDataAction | FetchANDsAction | FetchSurveysAction | FetchNotificationsAction | FetchNewsAction | FetchStaffAction | FetchOrphansAction | FetchApprovalsAction | FetchTABEsAction | FetchFundingAction | FetchInstructorsAction | FetchSchoolsAction | FetchTranscriptsAction | FetchClassesAction | FetchArrivalsAction | FetchPendingAction | FetchAttendanceAction | SearchUniversalAction | SearchUniversalLoadingAction | OpenUniversalAction | CloseUniversalAction

export type DashboardIndex = 'staff'|'pending'|'orphans'|'approvals'|'schools'|'transcripts'|'instructors'|'funding'|'classes'|'arrivals'|'attendance'

export interface DashboardState {
    open: boolean,
    facility: string,
    refresh: number,
    order: any[],
    options: {
        facilities: any[]
    },
    notifications: {
        list: any[]
    },
    news: {
        announcements: any,
        notices:any
    },
    staff: {
        loading: boolean,
        notify: boolean,
        collapse: boolean,
        sorts: {column:string,dir:string}[],
        data: any
    },
    orphans: {
        loading: boolean,
        notify: boolean,
        collapse: boolean,
        sorts: {column:string,dir:string}[],
        data: any
    },
    approvals: {
        loading: boolean,
        notify: boolean,
        collapse: boolean,
        sorts: {column:string,dir:string}[],
        data: any
    },
    tabes: {
        loading: boolean,
        data: any
    },
    schools: {
        loading: boolean,
        notify: boolean,
        collapse: boolean,
        sorts: {column:string,dir:string}[],
        data: any
    },
    transcripts: {
        loading: boolean,
        notify: boolean,
        collapse: boolean,
        sorts: {column:string,dir:string}[],
        data: any
    },
    funding: {
        loading: boolean,
        notify: boolean,
        collapse: boolean,
        sorts: {column:string,dir:string}[],
        data: any
    },
    instructors: {
        loading: boolean,
        notify: boolean,
        collapse: boolean,
        sorts: {column:string,dir:string}[],
        data: any
    },
    classes: {
        loading: boolean,
        notify: boolean,
        collapse: boolean,
        sorts: {column:string,dir:string}[],
        data: any
    },
    arrivals: {
        loading: boolean,
        notify: boolean,
        collapse: boolean,
        sorts: {column:string,dir:string}[],
        data: any
    },
    pending: {
        loading: boolean,
        notify: boolean,
        collapse: boolean,
        sorts: {column:string,dir:string}[],
        data: any
    },
    attendance: {
        loading: boolean,
        notify: boolean,
        collapse: boolean,
        sorts: {column:string,dir:string}[],
        data: any
    },
    ands: {
        loading: boolean,
        data: any
    },
    surveys: {
        data: any
    },
    modals: {
        search: {
            open: boolean,
            data: any
        },
        universal: {
            open: boolean,
            data: any
        },
        notification: {
            open: boolean,
            num: number,
            linkID: number,
            message: string,
            type: string,
            data: any
        },
        extend: {
            open: boolean,
            id: number,
            name: string,
            expDate: string,
            date: any,
            all: boolean
        },
        archive: {
            open: boolean,
            id: number,
            name: string
        },
        expire: {
            open: boolean,
            id: number,
            name: string
        },
        class: {
            open: boolean,
            id: number,
            name: string,
            date: any
        },
        survey: {
            open: boolean,
            id: number,
            name: string,
            questions: any[],
            grids: any[]
        }
    },
    pdfs: {
        warning: {
            generating: boolean,
            ready: boolean,
            url: string,
            data: any
        }
    }
}

var typingTimeout: any

const buildParams = (sorts:any, params: URLSearchParams) => {
    _.each(sorts, (sort:any) => {
        params.append('sorts', `${sort.column}:${sort.dir}`)
    })
    return params
}

export const actionCreators = {
    setDashboardURL: (report: string, url: string) => ({ type: 'SET_DASHBOARD_URL', report: report, url: url } as SetURLAction),
    pendingChangeNotification: (rosterID: number, log: string, selected: any) => ({ type: 'PENDING_CHANGE_NOTIFICATION', rosterID: rosterID, log: log, selected: selected } as PendingChangeNotificationAction),
    pendingClassChangeNotification: (rosterID: number, log: string, selected: any) => ({ type: 'PENDING_CLASS_CHANGE_NOTIFICATION', rosterID: rosterID, log: log, selected: selected } as PendingClassChangeNotificationAction),
    updatePendingNotesNotification: (value: any) => ({ type: 'UPDATE_PENDING_NOTES_NOTIFICATION', value: value } as UpdatePendingNotesNotificationAction),
    toggleDashboard: () => ({ type: 'TOGGLE_DASHBOARD' } as ToggleAction),
    toggleExtendModal: () => ({ type: 'TOGGLE_DASHBOARD_EXTEND' } as ToggleExtendAction),
    toggleNotificationModal: () => ({ type: 'TOGGLE_DASHBOARD_NOTIFICATION' } as ToggleNotificationAction),
    toggleSearchModal: () => ({ type: 'TOGGLE_DASHBOARD_SEARCH' } as ToggleSearchAction),
    toggleDashArchiveModal: () => ({ type: 'TOGGLE_DASHBOARD_ARCHIVE' } as ToggleArchiveAction),
    toggleDashExpireModal: () => ({ type: 'TOGGLE_DASHBOARD_EXPIRE' } as ToggleExpireAction),
    toggleClassModal: () => ({ type: 'TOGGLE_DASHBOARD_CLASS' } as ToggleClassAction),
    closeSurveyModal: () => ({ type: 'CLOSE_SURVEY' } as CloseSurveyAction),
    openSurvey: (survey: any) => ({ type: 'OPEN_SURVEY', id: survey.recordID, name: survey.name, questions: survey.questions, grids: survey.grids } as OpenSurveyAction),
    openNotificationModal: (notification: any, num: number) => ({ type: 'OPEN_NOTIFICATION', notification: notification, num: num } as OpenNotificationAction),
    selectOption: (questionID: number, optionID: number) => ({ type: 'SELECT_OPTION', questionID: questionID, optionID: optionID } as SelectOptionAction),
    selectGridOption: (gridID: number, questionID: number, optionID: number) => ({ type: 'SELECT_GRID_OPTION', gridID: gridID, questionID: questionID, optionID: optionID } as SelectGridAction),
    selectExtendStaff: (staff:any, date:any = null) => ({ type: 'SELECT_EXTEND_STAFF', staff: staff, date: date } as SelectExtendAction),
    selectExpireStaff: (staff:any) => ({ type: 'SELECT_EXPIRE_STAFF', staff: staff } as SelectExpireAction),
    selectArchiveStaff: (staff:any) => ({ type: 'SELECT_ARCHIVE_STAFF', staff: staff } as SelectArchiveAction),
    selectExtendClass: (klass:any) => ({ type: 'SELECT_EXTEND_CLASS', class: klass } as SelectClassAction),
    updateNotificationData: (key:string, value:any) => ({ type: 'UPDATE_NOTIFICATION_DATA', key: key, value: value } as UpdateNotificationDataAction),
    updateExtend: (key:string, value:any) => ({ type: 'UPDATE_DASHBOARD_EXTEND', key: key, value: value } as UpdateExtendAction),
    updateClass: (key:string, value:any) => ({ type: 'UPDATE_DASHBOARD_CLASS', key: key, value: value } as UpdateClassAction),
    selectOrphanInmate: (recordID: number, data: any) => ({ type: 'SELECT_ORPHAN_INMATE', recordID: recordID, key: data.key, value: data.title } as SelectInmateAction),
    selectOrphanInmateNotification: (recordID: number, data: any) => ({ type: 'SELECT_ORPHAN_INMATE_NOTIFICATION', recordID: recordID, key: data.key, value: data.title } as SelectInmateNotificationAction),
    closeUniversalResults: () => ({ type: 'CLOSE_UNIVERSAL_RESULTS' } as CloseUniversalAction),
    reorder: (order: any[]): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let params = new URLSearchParams()
        params.append('facility', state.facility)
        const { error } = await agent.Dashboard.reorder(order)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            const { facilities, order } = await agent.Dashboard.fetchOptions()
            dispatch({ type: 'FETCH_DASHBOARD_OPTIONS', facilities: facilities, order: order } as FetchOptionsAction)
            const { notifications } = await agent.Dashboard.fetchNotifications(params)
            dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
            dispatch({ type: 'SET_DASHBOARD_REFRESH', refresh: Date.now() } as SetRefreshAction)
        }
    },
    toggleOrder: (table: DashboardIndex, orderBy: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let sorts = state[table].sorts
        let sort = _.find(sorts, (sort:any) => sort.column == orderBy)
        if (sort == null) {
            sorts.unshift({ column: orderBy, dir: 'asc' })
        } else {
            if (sort.dir == 'asc') {
                sorts = _.map(sorts, (sort:any) => sort.column == orderBy ? { column: sort.column, dir: 'desc' } : sort)
            } else {
                sorts = _.filter(sorts, (sort:any) => sort.column != orderBy)
            }
        }

        let params = new URLSearchParams()
        params.append('facility', state.facility)
        buildParams(sorts, params)
        dispatch({ type: 'TOGGLE_DASHBOARD_ORDER', table: table, sorts: sorts } as ToggleOrderAction)

        switch (table) {
        case 'staff':
            const { staffData, staffNotify, staffCollapse } = await agent.Dashboard.fetchStaff(params)
            dispatch({ type: 'FETCH_DASHBOARD_STAFF', data: staffData, notify: staffNotify, collapse: staffCollapse } as FetchStaffAction)
            break;
        case 'orphans':
            const { orphanData, orphanNotify, orphanCollapse } = await agent.Dashboard.fetchOrphans(params)
            dispatch({ type: 'FETCH_DASHBOARD_ORPHANS', data: orphanData, notify: orphanNotify, collapse: orphanCollapse } as FetchOrphansAction)
            break;
        case 'approvals':
            const { approvalData, approvalNotify, approvalCollapse } = await agent.Dashboard.fetchApprovals(params)
            dispatch({ type: 'FETCH_DASHBOARD_APPROVALS', data: approvalData, notify: approvalNotify, collapse: approvalCollapse } as FetchApprovalsAction)
            break;
        case 'pending':
            const { pendingData, pendingNotify, pendingCollapse } = await agent.Dashboard.fetchPending(params)
            dispatch({ type: 'FETCH_DASHBOARD_PENDING', data: pendingData, notify: pendingNotify, collapse: pendingCollapse } as FetchPendingAction)
            break;
        case 'transcripts':
            var { requestData, requestNotify, requestCollapse } = await agent.Dashboard.fetchTranscripts(params)
            dispatch({ type: 'FETCH_DASHBOARD_TRANSCRIPTS', data: requestData, notify: requestNotify, collapse: requestCollapse } as FetchTranscriptsAction)
            break;
        case 'schools':
            var { requestData, requestNotify, requestCollapse } = await agent.Dashboard.fetchSchools(params)
            dispatch({ type: 'FETCH_DASHBOARD_SCHOOLS', data: requestData, notify: requestNotify, collapse: requestCollapse } as FetchSchoolsAction)
            break;
        case 'instructors':
            const { instructorData, instructorNotify, instructorCollapse } = await agent.Dashboard.fetchInstructors(params)
            dispatch({ type: 'FETCH_DASHBOARD_INSTRUCTORS', data: instructorData, notify: instructorNotify, collapse: instructorCollapse } as FetchInstructorsAction)
            break;
        case 'funding':
            const { fundingData, fundingNotify, fundingCollapse } = await agent.Dashboard.fetchFunding(params)
            dispatch({ type: 'FETCH_DASHBOARD_FUNDING', data: fundingData, notify: fundingNotify, collapse: fundingCollapse } as FetchFundingAction)
            break;
        case 'classes':
            const { classData, classNotify, classCollapse } = await agent.Dashboard.fetchClasses(params)
            dispatch({ type: 'FETCH_DASHBOARD_CLASSES', data: classData, notify: classNotify, collapse: classCollapse } as FetchClassesAction)
            break;
        case 'arrivals':
            const { arrivalData, arrivalNotify, arrivalCollapse } = await agent.Dashboard.fetchArrivals(params)
            dispatch({ type: 'FETCH_DASHBOARD_ARRIVALS', data: arrivalData, notify: arrivalNotify, collapse: arrivalCollapse } as FetchArrivalsAction)
            break;
        case 'attendance':
            const { attendanceData, attendanceNotify, attendanceCollapse } = await agent.Dashboard.fetchAttendance(params)
            dispatch({ type: 'FETCH_DASHBOARD_ATTENDANCE', data: attendanceData, notify: attendanceNotify, collapse: attendanceCollapse } as FetchAttendanceAction)
            break;
        }
    },
    setFacility: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let id = getState().staff.staff ? getState().staff.staff!.recordID : 0
        let state = getState().dashboard
        let params = new URLSearchParams()
        params.append('facility', facility)
        store(`${id}:filters:dashboard:facility`, facility)
        dispatch({ type: 'SET_DASHBOARD_FACILITY', facility: facility } as SetFacilityAction)
    },
    fetchSurveys: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const { surveys } = await agent.Dashboard.fetchSurveys()
        dispatch({ type: 'FETCH_DASHBOARD_SURVEYS', data: surveys } as FetchSurveysAction)
    },
    fetchNotifications: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let params = new URLSearchParams()
        params.append('facility', facility)
        const { notifications } = await agent.Dashboard.fetchNotifications(params)
        dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
    },
    fetchNews: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let params = new URLSearchParams()
        params.append('facility', facility)
        const { announcements, notices } = await agent.Dashboard.fetchNews(params)
        dispatch({ type: 'FETCH_DASHBOARD_NEWS', announcements: announcements ? createEditorState(JSON.parse(announcements.messageContent)) : null, notices: notices ? createEditorState(JSON.parse(notices.messageContent)) : null } as FetchNewsAction)
    },
    fetchDashboardStaff: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let params = new URLSearchParams()
        params.append('facility', facility)
        buildParams(state.staff.sorts, params)
        const { staffData, staffNotify, staffCollapse } = await agent.Dashboard.fetchStaff(params)
        dispatch({ type: 'FETCH_DASHBOARD_STAFF', data: staffData, notify: staffNotify, collapse: staffCollapse } as FetchStaffAction)
    },
    fetchOrphans: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let params = new URLSearchParams()
        params.append('facility', facility)
        buildParams(state.orphans.sorts, params)
        const { orphanData, orphanNotify, orphanCollapse } = await agent.Dashboard.fetchOrphans(params)
        dispatch({ type: 'FETCH_DASHBOARD_ORPHANS', data: orphanData, notify: orphanNotify, collapse: orphanCollapse } as FetchOrphansAction)
    },
    fetchApprovals: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let params = new URLSearchParams()
        params.append('facility', facility)
        buildParams(state.approvals.sorts, params)
        const { approvalData, approvalNotify, approvalCollapse } = await agent.Dashboard.fetchApprovals(params)
        dispatch({ type: 'FETCH_DASHBOARD_APPROVALS', data: approvalData, notify: approvalNotify, collapse: approvalCollapse } as FetchApprovalsAction)
    },
    fetchTABEs: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let params = new URLSearchParams()
        params.append('facility', facility)
        const { tabeData } = await agent.Dashboard.fetchTABEs(params)
        dispatch({ type: 'FETCH_DASHBOARD_TABES', data: tabeData } as FetchTABEsAction)
    },
    fetchSchools: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let params = new URLSearchParams()
        params.append('facility', facility)
        buildParams(state.schools.sorts, params)
        const { requestData, requestNotify, requestCollapse } = await agent.Dashboard.fetchSchools(params)
        dispatch({ type: 'FETCH_DASHBOARD_SCHOOLS', data: requestData, notify: requestNotify, collapse: requestCollapse } as FetchSchoolsAction)
    },
    fetchTranscripts: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let params = new URLSearchParams()
        params.append('facility', facility)
        buildParams(state.transcripts.sorts, params)
        const { requestData, requestNotify, requestCollapse } = await agent.Dashboard.fetchTranscripts(params)
        dispatch({ type: 'FETCH_DASHBOARD_TRANSCRIPTS', data: requestData, notify: requestNotify, collapse: requestCollapse } as FetchTranscriptsAction)
    },
    fetchFunding: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let params = new URLSearchParams()
        params.append('facility', facility)
        buildParams(state.funding.sorts, params)
        const { fundingData, fundingNotify, fundingCollapse } = await agent.Dashboard.fetchFunding(params)
        dispatch({ type: 'FETCH_DASHBOARD_FUNDING', data: fundingData, notify: fundingNotify, collapse: fundingCollapse } as FetchFundingAction)
    },
    fetchInstructors: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let params = new URLSearchParams()
        params.append('facility', facility)
        buildParams(state.instructors.sorts, params)
        const { instructorData, instructorNotify, instructorCollapse } = await agent.Dashboard.fetchInstructors(params)
        dispatch({ type: 'FETCH_DASHBOARD_INSTRUCTORS', data: instructorData, notify: instructorNotify, collapse: instructorCollapse } as FetchInstructorsAction)
    },
    fetchClasses: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let params = new URLSearchParams()
        params.append('facility', facility)
        buildParams(state.classes.sorts, params)
        const { classData, classNotify, classCollapse } = await agent.Dashboard.fetchClasses(params)
        dispatch({ type: 'FETCH_DASHBOARD_CLASSES', data: classData, notify: classNotify, collapse: classCollapse } as FetchClassesAction)
    },
    fetchArrivals: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let params = new URLSearchParams()
        params.append('facility', facility)
        buildParams(state.arrivals.sorts, params)
        const { arrivalData, arrivalNotify, arrivalCollapse } = await agent.Dashboard.fetchArrivals(params)
        dispatch({ type: 'FETCH_DASHBOARD_ARRIVALS', data: arrivalData, notify: arrivalNotify, collapse: arrivalCollapse } as FetchArrivalsAction)
    },
    fetchPendingAttendance: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let params = new URLSearchParams()
        params.append('facility', facility)
        buildParams(state.pending.sorts, params)
        const { pendingData, pendingNotify, pendingCollapse } = await agent.Dashboard.fetchPending(params)
        dispatch({ type: 'FETCH_DASHBOARD_PENDING', data: pendingData, notify: pendingNotify, collapse: pendingCollapse } as FetchPendingAction)
    },
    fetchLowAttendance: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let params = new URLSearchParams()
        params.append('facility', facility)
        buildParams(state.attendance.sorts, params)
        const { attendanceData, attendanceNotify, attendanceCollapse } = await agent.Dashboard.fetchAttendance(params)
        dispatch({ type: 'FETCH_DASHBOARD_ATTENDANCE', data: attendanceData, notify: attendanceNotify, collapse: attendanceCollapse } as FetchAttendanceAction)
    },
    fetchAnds: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let params = new URLSearchParams()
        params.append('facility', facility)
        const { andData } = await agent.Dashboard.fetchAnds(params)
        dispatch({ type: 'FETCH_DASHBOARD_ANDS', data: andData } as FetchANDsAction)
    },
    extendClass: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard.modals.class
        let params = new URLSearchParams()
        params.append('facility', facility)
        buildParams(getState().dashboard.classes.sorts, params)

        const removeSchedule = async (data:any) => {
            const { error } = await agent.Inmates.removeSchedule(data)
            if (error != null) {
                toast.error(error, { autoClose: false })
            } else {
                const { error, conflicts } = await agent.Dashboard.extendClass(state.id, state)
                if (error == null && conflicts.length == 0) {
                    toast.success('Class extended')
                    const { classData, classNotify, classCollapse } = await agent.Dashboard.fetchClasses(params)
                    dispatch({ type: 'FETCH_DASHBOARD_CLASSES', data: classData, notify: classNotify, collapse: classCollapse } as FetchClassesAction)
                }
            }
        }

        const { error, conflicts } = await agent.Dashboard.extendClass(state.id, state)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else if (conflicts.length > 0) {
            dispatch({ type: 'TOGGLE_DASHBOARD_CLASS' } as ToggleClassAction)
            conflicts.forEach((conflict:any) => {
                const data1 = { inmateID: conflict.inmateID, classID: state.id, scheduleID: conflict.scheduleID }
                const data2 = { inmateID: conflict.inmateID, classID: conflict.classID, scheduleID: conflict.conflictID }
                const content = ({closeToast}: (any)) => (
                    <div>
                        {conflict.message}
                        <div style={{marginTop:10}}>
                            <span><b>Remove conflicting schedule from: </b></span>
                            <Button color='blue' size='mini' content={`${state.name} [${state.id}]`} style={{marginTop:5}} onClick={() => { closeToast(); removeSchedule(data1);  }} />
                            <Button color='blue' size='mini' content={`${conflict.className} [${conflict.classID}]`} style={{marginTop:5}} onClick={() => { closeToast(); removeSchedule(data2);  }} />
                            <Button color='black' size='mini' content={`Close all`} onClick={() => { toast.dismiss() }} />
                        </div>
                    </div>
                )
                toast.error(content, { autoClose: false })
            })
        } else {
            toast.success('Class extended')
            const { classData, classNotify, classCollapse } = await agent.Dashboard.fetchClasses(params)
            dispatch({ type: 'FETCH_DASHBOARD_CLASSES', data: classData, notify: classNotify, collapse: classCollapse } as FetchClassesAction)
            dispatch({ type: 'TOGGLE_DASHBOARD_CLASS' } as ToggleClassAction)
        }
    },
    extendClassNotification: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let staff = getState().staff.staff
        let notification = state.modals.notification
        let num = notification.num
        let notificationList = _.filter(state.notifications.list, (n:any) => n.type == notification.type)
        let next = notificationList[notification.num+1]
        if (next == undefined) {
            num -= 1
            next = notificationList[notification.num-1]
        }
        var facility = state.facility
        if (!facility) {
            if (staff && staff.admin) {
                facility = 'all'
            } else {
                facility = 'my'
            }
        }
        let params = new URLSearchParams()
        params.append('facility', facility)

        const removeSchedule = async (data:any) => {
            const { error } = await agent.Inmates.removeSchedule(data)
            if (error != null) {
                toast.error(error, { autoClose: false })
            } else {
                const { error, conflicts } = await agent.Dashboard.extendClass(notification.linkID, state)
                if (error == null && conflicts.length == 0) {
                    toast.success(`Class extended: ${notification.message}`)
                    const { notifications } = await agent.Dashboard.fetchNotifications(params)
                    dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
                }
            }
        }

        const { error, conflicts } = await agent.Dashboard.extendClass(notification.linkID, state)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else if (conflicts.length > 0) {
            if (next == undefined) {
                dispatch({ type: 'TOGGLE_DASHBOARD_NOTIFICATION' } as ToggleNotificationAction)
            } else {
                dispatch({ type: 'OPEN_NOTIFICATION', notification: next, num: num } as OpenNotificationAction)
            }
            const { notifications } = await agent.Dashboard.fetchNotifications(params)
            dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
            conflicts.forEach((conflict:any) => {
                const data1 = { inmateID: conflict.inmateID, classID: notification.linkID, scheduleID: conflict.scheduleID }
                const data2 = { inmateID: conflict.inmateID, classID: conflict.classID, scheduleID: conflict.conflictID }
                const content = ({closeToast}: (any)) => (
                    <div>
                        {conflict.message}
                        <div style={{marginTop:10}}>
                            <span><b>Remove conflicting schedule from: </b></span>
                            <Button color='blue' size='mini' content={`${notification.data.className} [${notification.linkID}]`} style={{marginTop:5}} onClick={() => { closeToast(); removeSchedule(data1);  }} />
                            <Button color='blue' size='mini' content={`${conflict.className} [${conflict.classID}]`} style={{marginTop:5}} onClick={() => { closeToast(); removeSchedule(data2);  }} />
                            <Button color='black' size='mini' content={`Close all`} onClick={() => { toast.dismiss() }} />
                        </div>
                    </div>
                )
                toast.error(content, { autoClose: false })
            })
        } else {
            toast.success('Class extended')
            if (next == undefined) {
                dispatch({ type: 'TOGGLE_DASHBOARD_NOTIFICATION' } as ToggleNotificationAction)
            } else {
                dispatch({ type: 'OPEN_NOTIFICATION', notification: next, num: num } as OpenNotificationAction)
            }
            const { notifications } = await agent.Dashboard.fetchNotifications(params)
            dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
        }
    },
    extendStaffNotification: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let staff = getState().staff.staff
        let notification = state.modals.notification
        let num = notification.num
        let notificationList = _.filter(state.notifications.list, (n:any) => n.type == notification.type)
        let next = notificationList[notification.num+1]
        if (next == undefined) {
            num -= 1
            next = notificationList[notification.num-1]
        }
        var facility = state.facility
        if (!facility) {
            if (staff && staff.admin) {
                facility = 'all'
            } else {
                facility = 'my'
            }
        }
        let params = new URLSearchParams()
        params.append('facility', facility)
        const { error } = await agent.Dashboard.extendStaff(notification.linkID, notification.data.date, notification.data.all)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success(`Staff extended: ${notification.message}`)
            if (next == undefined) {
                dispatch({ type: 'TOGGLE_DASHBOARD_NOTIFICATION' } as ToggleNotificationAction)
            } else {
                dispatch({ type: 'OPEN_NOTIFICATION', notification: next, num: num } as OpenNotificationAction)
            }
            const { notifications } = await agent.Dashboard.fetchNotifications(params)
            dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
        }
    },
    archiveStaffNotification: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let staff = getState().staff.staff
        let notification = state.modals.notification
        let num = notification.num
        let notificationList = _.filter(state.notifications.list, (n:any) => n.type == notification.type)
        let next = notificationList[notification.num+1]
        if (next == undefined) {
            num -= 1
            next = notificationList[notification.num-1]
        }
        var facility = state.facility
        if (!facility) {
            if (staff && staff.admin) {
                facility = 'all'
            } else {
                facility = 'my'
            }
        }
        let params = new URLSearchParams()
        params.append('facility', facility)
        const { error } = await agent.Dashboard.archiveStaff(notification.linkID)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success(`Staff archived: ${notification.message}`)
            if (next == undefined) {
                dispatch({ type: 'TOGGLE_DASHBOARD_NOTIFICATION' } as ToggleNotificationAction)
            } else {
                dispatch({ type: 'OPEN_NOTIFICATION', notification: next, num: num } as OpenNotificationAction)
            }
            const { notifications } = await agent.Dashboard.fetchNotifications(params)
            dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
        }
    },
    extendStaff: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let params = new URLSearchParams()
        params.append('facility', facility)
        buildParams(getState().dashboard.staff.sorts, params)
        let state = getState().dashboard.modals.extend
        const { error } = await agent.Dashboard.extendStaff(state.id, state.date, state.all)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Staff extended')
            const { staffData, staffNotify, staffCollapse } = await agent.Dashboard.fetchStaff(params)
            dispatch({ type: 'FETCH_DASHBOARD_STAFF', data: staffData, notify: staffNotify, collapse: staffCollapse } as FetchStaffAction)
            dispatch({ type: 'TOGGLE_DASHBOARD_EXTEND' } as ToggleExtendAction)
            const { notifications } = await agent.Dashboard.fetchNotifications(params)
            dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
        }
    },
    archiveStaff: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let params = new URLSearchParams()
        params.append('facility', facility)
        buildParams(getState().dashboard.staff.sorts, params)
        let state = getState().dashboard.modals.archive
        const { error } = await agent.Dashboard.archiveStaff(state.id)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Staff archived')
            const { staffData, staffNotify, staffCollapse } = await agent.Dashboard.fetchStaff(params)
            dispatch({ type: 'FETCH_DASHBOARD_STAFF', data: staffData, notify: staffNotify, collapse: staffCollapse } as FetchStaffAction)
            dispatch({ type: 'TOGGLE_DASHBOARD_ARCHIVE' } as ToggleArchiveAction)
        }
    },
    expireStaff: (facility: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let params = new URLSearchParams()
        params.append('facility', facility)
        let state = getState().dashboard.modals.expire
        const { error } = await agent.Dashboard.expireStaff(state.id)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Staff expired')
            const { staffData, staffNotify, staffCollapse } = await agent.Dashboard.fetchStaff(params)
            dispatch({ type: 'FETCH_DASHBOARD_STAFF', data: staffData, notify: staffNotify, collapse: staffCollapse } as FetchStaffAction)
            dispatch({ type: 'TOGGLE_DASHBOARD_EXPIRE' } as ToggleExpireAction)
        }
    },
    suspendInmateNotification: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let staff = getState().staff.staff
        let notification = state.modals.notification
        let num = notification.num
        let notificationList = _.filter(state.notifications.list, (n:any) => n.type == notification.type)
        let next = notificationList[notification.num+1]
        if (next == undefined) {
            num -= 1
            next = notificationList[notification.num-1]
        }
        var facility = state.facility
        if (!facility) {
            if (staff && staff.admin) {
                facility = 'all'
            } else {
                facility = 'my'
            }
        }
        let params = new URLSearchParams()
        params.append('facility', facility)
        const { error } = await agent.Classes.suspendRoster(notification.data.classID, { recordID: notification.data.rosterID })
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success(`Inmate suspended: ${notification.message}`)
            if (next == undefined) {
                dispatch({ type: 'TOGGLE_DASHBOARD_NOTIFICATION' } as ToggleNotificationAction)
            } else {
                dispatch({ type: 'OPEN_NOTIFICATION', notification: next, num: num } as OpenNotificationAction)
            }
            const { notifications } = await agent.Dashboard.fetchNotifications(params)
            dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
        }
    },
    assignInstructorNotification: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let staff = getState().staff.staff
        let notification = state.modals.notification
        let num = notification.num
        let notificationList = _.filter(state.notifications.list, (n:any) => n.type == notification.type)
        let next = notificationList[notification.num+1]
        if (next == undefined) {
            num -= 1
            next = notificationList[notification.num-1]
        }
        var facility = state.facility
        if (!facility) {
            if (staff && staff.admin) {
                facility = 'all'
            } else {
                facility = 'my'
            }
        }
        let params = new URLSearchParams()
        params.append('facility', facility)
        const { error } = await agent.Dashboard.assignInstructor(notification.data)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success(`Instructor assigned: ${notification.message}`)
            if (next == undefined) {
                dispatch({ type: 'TOGGLE_DASHBOARD_NOTIFICATION' } as ToggleNotificationAction)
            } else {
                dispatch({ type: 'OPEN_NOTIFICATION', notification: next, num: num } as OpenNotificationAction)
            }
            const { notifications } = await agent.Dashboard.fetchNotifications(params)
            dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
        }
    },
    assignFundingNotification: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let staff = getState().staff.staff
        let notification = state.modals.notification
        let num = notification.num
        let notificationList = _.filter(state.notifications.list, (n:any) => n.type == notification.type)
        let next = notificationList[notification.num+1]
        if (next == undefined) {
            num -= 1
            next = notificationList[notification.num-1]
        }
        var facility = state.facility
        if (!facility) {
            if (staff && staff.admin) {
                facility = 'all'
            } else {
                facility = 'my'
            }
        }
        let params = new URLSearchParams()
        params.append('facility', facility)
        const { error } = await agent.Dashboard.assignFunding(notification.data)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success(`Funding assigned: ${notification.message}`)
            if (next == undefined) {
                dispatch({ type: 'TOGGLE_DASHBOARD_NOTIFICATION' } as ToggleNotificationAction)
            } else {
                dispatch({ type: 'OPEN_NOTIFICATION', notification: next, num: num } as OpenNotificationAction)
            }
            const { notifications } = await agent.Dashboard.fetchNotifications(params)
            dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
        }
    },
    assignOrphanNotification: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let staff = getState().staff.staff
        let notification = state.modals.notification
        let num = notification.num
        let notificationList = _.filter(state.notifications.list, (n:any) => n.type == notification.type)
        let next = notificationList[notification.num+1]
        if (next == undefined) {
            num -= 1
            next = notificationList[notification.num-1]
        }
        var facility = state.facility
        if (!facility) {
            if (staff && staff.admin) {
                facility = 'all'
            } else {
                facility = 'my'
            }
        }
        let params = new URLSearchParams()
        params.append('facility', facility)
        const { error } = await agent.Dashboard.assignOrphan(notification.data)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success(`Orphan assigned: ${notification.message}`)
            if (next == undefined) {
                dispatch({ type: 'TOGGLE_DASHBOARD_NOTIFICATION' } as ToggleNotificationAction)
            } else {
                dispatch({ type: 'OPEN_NOTIFICATION', notification: next, num: num } as OpenNotificationAction)
            }
            const { notifications } = await agent.Dashboard.fetchNotifications(params)
            dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
        }
    },
    assignOrphan: (orphan: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        const { error } = await agent.Dashboard.assignOrphan(orphan)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Orphan assigned')
            let params = new URLSearchParams()
            params.append('facility', state.facility)
            buildParams(state.orphans.sorts, params)
            const { orphanData, orphanNotify, orphanCollapse } = await agent.Dashboard.fetchOrphans(params)
            dispatch({ type: 'FETCH_DASHBOARD_ORPHANS', data: orphanData, notify: orphanNotify, collapse: orphanCollapse } as FetchOrphansAction)
        }
    },
    searchOrphanInmates: (recordID: number, value: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SEARCH_ORPHANS_LOADING', recordID: recordID, value: value } as InmatesLoadingAction)
        clearTimeout(typingTimeout)
        typingTimeout = setTimeout(async () => {
            let params = new URLSearchParams()
            params.append('query', value)
            const { inmates } = await agent.Data.fetchInmates(params)
            dispatch({ type: 'SEARCH_ORPHANS', recordID: recordID, inmates: inmates } as SearchInmatesAction)
        }, 1500)
    },
    searchOrphanInmatesNotification: (recordID: number, value: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SEARCH_ORPHANS_LOADING_NOTIFICATION', recordID: recordID, value: value } as InmatesLoadingNotificationAction)
        clearTimeout(typingTimeout)
        typingTimeout = setTimeout(async () => {
            let params = new URLSearchParams()
            params.append('query', value)
            const { inmates } = await agent.Data.fetchInmates(params)
            dispatch({ type: 'SEARCH_ORPHANS_NOTIFICATION', recordID: recordID, inmates: inmates } as SearchInmatesNotificationAction)
        }, 1500)
    },
    searchHistory: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let staff = getState().staff.staff!
        let data = state.modals.universal.data
        if (!data.value || data.value == '') {
            let categorized:any = {}
            staff.searches.forEach((search:any) => {
                if (!categorized[search.entity]) { categorized[search.entity] = { name: search.entity, results: [] } }
                categorized[search.entity].results.push({ record: search.entityID, title: search.query, entity: search.entity, description: search.description, favorite: search.favorite })
            })
            dispatch({ type: 'SEARCH_UNIVERSAL_RESULTS', results: categorized } as SearchUniversalAction)
        }
        dispatch({ type: 'OPEN_UNIVERSAL_RESULTS' } as OpenUniversalAction)
    },
    searchUniversalResults: (value: string|null, entity: string = "Universal"): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let staff = getState().staff.staff!
        if (value === null) value = getState().dashboard.modals.universal.data.value;
        dispatch({ type: 'SEARCH_UNIVERSAL_LOADING', value: value } as SearchUniversalLoadingAction)
        clearTimeout(typingTimeout)
        typingTimeout = setTimeout(async () => {
            let categorized:any = {}
            if (value === '') {
              staff.searches.forEach((search:any) => {
                  if (!categorized[search.entity]) { categorized[search.entity] = { name: search.entity, results: [] } }
                  categorized[search.entity].results.push({ record: search.entityID, title: search.query, entity: search.entity, description: search.description, favorite: search.favorite })
              })
              dispatch({ type: 'SEARCH_UNIVERSAL_RESULTS', results: categorized } as SearchUniversalAction)
            } else {
              let params = new URLSearchParams()
              params.append('query', value as string)
              params.append('entity', entity)
              const { results } = await agent.Data.fetchUniversal(params)
              results.forEach((result:any) => {
                  categorized[result.category] = {
                      name: result.category,
                      results: result.results
                 }
              })
            }
            dispatch({ type: 'SEARCH_UNIVERSAL_RESULTS', results: categorized } as SearchUniversalAction)
            setTimeout(function(){(document as any).querySelector('.search input').click()},100)
        }, 1500);
    },
    selectSearchResult: (num: 1|2, data: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SELECT_SEARCH_RESULT', key: data.record, value: data.title } as SelectSearchAction)
        let params = new URLSearchParams()
        params.append('entityID', data.record)
        params.append('entity', data.entity)
        params.append('query', data.title)
        await agent.Data.saveSearch(params)
        switch (data.entity) {
        case 'Inmate':
            dispatch(paneActions.fetchInmate(num, data.record) as any)
            break;
        case 'Class':
            dispatch(paneActions.fetchClass(num, data.record) as any)
            break;
        case 'Program':
            dispatch(paneActions.fetchProgram(num, data.record) as any)
            break;
        case 'Course':
            dispatch(paneActions.fetchCourse(num, data.record) as any)
            break;
        case 'Staff':
            dispatch(paneActions.fetchStaff(num, data.record) as any)
            break;
        case 'Facility':
            dispatch(paneActions.fetchFacility(num, data.record) as any)
            break;
        case 'Template':
            dispatch(paneActions.fetchTemplate(num, data.record) as any)
            break;
        }
        dispatch(staffActions.fetchStaff() as any)
    },
    combineSuggestion: (suggestionID: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        const { error } = await agent.Dashboard.combineSuggestion(suggestionID)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Suggestion combined')
            let params = new URLSearchParams()
            params.append('facility', state.facility)
            const { tabeData } = await agent.Dashboard.fetchTABEs(params)
            dispatch({ type: 'FETCH_DASHBOARD_TABES', data: tabeData } as FetchTABEsAction)
        }
    },
    ignoreSuggestion: (suggestionID: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        const { error } = await agent.Dashboard.ignoreSuggestion(suggestionID)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Suggestion ignored')
            let params = new URLSearchParams()
            params.append('facility', state.facility)
            const { tabeData } = await agent.Dashboard.fetchTABEs(params)
            dispatch({ type: 'FETCH_DASHBOARD_TABES', data: tabeData } as FetchTABEsAction)
        }
    },
    submitSurvey: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let questions = _.flatten(_.map(state.modals.survey.grids, (grid:any) => grid.questions).concat(state.modals.survey.questions))
        const { error } = await agent.Dashboard.submitSurvey(state.modals.survey.id, { questions: questions })
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Survey submitted')
            dispatch({ type: 'CLOSE_SURVEY' } as CloseSurveyAction)
            const { surveys } = await agent.Dashboard.fetchSurveys()
            dispatch({ type: 'FETCH_DASHBOARD_SURVEYS', data: surveys } as FetchSurveysAction)
        }
    },
    refuseSurvey: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        const { error } = await agent.Dashboard.refuseSurvey(state.modals.survey.id)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Survey refused')
            dispatch({ type: 'CLOSE_SURVEY' } as CloseSurveyAction)
            const { surveys } = await agent.Dashboard.fetchSurveys()
            dispatch({ type: 'FETCH_DASHBOARD_SURVEYS', data: surveys } as FetchSurveysAction)
        }
    },
    fetchDashboardOptions: (): AppThunkAction<KnownAction> => async (dispatch) => {
        const { facilities, order } = await agent.Dashboard.fetchOptions()
        dispatch({ type: 'FETCH_DASHBOARD_OPTIONS', facilities: facilities, order: order } as FetchOptionsAction)
    },
    submitPendingNotification: (selected: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let staff = getState().staff.staff
        let notification = state.modals.notification
        let num = notification.num
        let notificationList = _.filter(state.notifications.list, (n:any) => n.type == notification.type)
        let next = notificationList[notification.num+1]
        if (next == undefined) {
            num -= 1
            next = notificationList[notification.num-1]
        }
        var facility = state.facility
        if (!facility) {
            if (staff && staff.admin) {
                facility = 'all'
            } else {
                facility = 'my'
            }
        }
        let params = new URLSearchParams()
        params.append('facility', facility)

        const { errors } = await agent.Classes.submitPending(notification.data.classID, notification.data.selected)
        if (errors.length > 0) {
            _.each(errors, (error:string) => { toast.error(error, { autoClose: false }) })
        } else {
            toast.success(`Attendance submitted: ${notification.message}`)
            dispatch({ type: 'SUBMIT_DASHBOARD_ATTENDANCE', sessionID: selected.sessionID } as SubmitPendingAction)
            if (notification.data.pending.length <= 1) {
                if (next == undefined) {
                    dispatch({ type: 'TOGGLE_DASHBOARD_NOTIFICATION' } as ToggleNotificationAction)
                } else {
                    dispatch({ type: 'OPEN_NOTIFICATION', notification: next, num: num } as OpenNotificationAction)
                }
                const { notifications } = await agent.Dashboard.fetchNotifications(params)
                dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
            }
        }
    },
    approveDashApprovalNotification: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let staff = getState().staff.staff
        let notification = state.modals.notification
        let num = notification.num
        let notificationList = _.filter(state.notifications.list, (n:any) => n.type == notification.type)
        let next = notificationList[notification.num+1]
        if (next == undefined) {
            num -= 1
            next = notificationList[notification.num-1]
        }
        var facility = state.facility
        if (!facility) {
            if (staff && staff.admin) {
                facility = 'all'
            } else {
                facility = 'my'
            }
        }
        let params = new URLSearchParams()
        params.append('facility', facility)
        const { error } = await agent.Classes.approveClassApproval(notification.data.classID, notification.data.approvalID)
        if (error == null) {
            toast.success(`Inmate approved: ${notification.message}`)
            if (next == undefined) {
                dispatch({ type: 'TOGGLE_DASHBOARD_NOTIFICATION' } as ToggleNotificationAction)
            } else {
                dispatch({ type: 'OPEN_NOTIFICATION', notification: next, num: num } as OpenNotificationAction)
            }
            const { notifications } = await agent.Dashboard.fetchNotifications(params)
            dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
        } else {
            toast.error(error, { autoClose: false })
        }
    },
    denyDashApprovalNotification: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let staff = getState().staff.staff
        let notification = state.modals.notification
        let num = notification.num
        let notificationList = _.filter(state.notifications.list, (n:any) => n.type == notification.type)
        let next = notificationList[notification.num+1]
        if (next == undefined) {
            num -= 1
            next = notificationList[notification.num-1]
        }
        var facility = state.facility
        if (!facility) {
            if (staff && staff.admin) {
                facility = 'all'
            } else {
                facility = 'my'
            }
        }
        let params = new URLSearchParams()
        params.append('facility', facility)
        const { error } = await agent.Classes.denyClassApproval(notification.data.classID, notification.data.approvalID)
        if (error == null) {
            toast.success(`Inmate denied: ${notification.message}`)
            if (next == undefined) {
                dispatch({ type: 'TOGGLE_DASHBOARD_NOTIFICATION' } as ToggleNotificationAction)
            } else {
                dispatch({ type: 'OPEN_NOTIFICATION', notification: next, num: num } as OpenNotificationAction)
            }
            const { notifications } = await agent.Dashboard.fetchNotifications(params)
            dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
        } else {
            toast.error(error, { autoClose: false })
        }
    },
    approveDashApproval: (classID: number, approvalID: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let params = new URLSearchParams()
        params.append('facility', state.facility)
        buildParams(state.approvals.sorts, params)
        const { error } = await agent.Classes.approveClassApproval(classID, approvalID)
        if (error == null) {
            toast.success('Inmate approved')
            const { approvalData, approvalNotify, approvalCollapse } = await agent.Dashboard.fetchApprovals(params)
            dispatch({ type: 'FETCH_DASHBOARD_APPROVALS', data: approvalData, notify: approvalNotify, collapse: approvalCollapse } as FetchApprovalsAction)
        } else {
            toast.error(error, { autoClose: false })
        }
    },
    denyDashApproval: (classID: number, approvalID: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let params = new URLSearchParams()
        params.append('facility', state.facility)
        buildParams(state.approvals.sorts, params)
        const { error } = await agent.Classes.denyClassApproval(classID, approvalID)
        if (error == null) {
            toast.success('Inmate denied')
            const { approvalData, approvalNotify, approvalCollapse } = await agent.Dashboard.fetchApprovals(params)
            dispatch({ type: 'FETCH_DASHBOARD_APPROVALS', data: approvalData, notify: approvalNotify, collapse: approvalCollapse } as FetchApprovalsAction)
        } else {
            toast.error(error, { autoClose: false })
        }
    },
   receiveTranscriptNotification: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let staff = getState().staff.staff
        let notification = state.modals.notification
        let num = notification.num
        let notificationList = _.filter(state.notifications.list, (n:any) => n.type == notification.type)
        let next = notificationList[notification.num+1]
        if (next == undefined) {
            num -= 1
            next = notificationList[notification.num-1]
        }
        var facility = state.facility
        if (!facility) {
            if (staff && staff.admin) {
                facility = 'all'
            } else {
                facility = 'my'
            }
        }
        let params = new URLSearchParams()
        params.append('facility', facility)
        const { error } = await agent.Inmates.receiveRequest(notification.data.inmateID, notification.data.requestID, notification.data)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success(`Request received: ${notification.message}`)
            if (next == undefined) {
                dispatch({ type: 'TOGGLE_DASHBOARD_NOTIFICATION' } as ToggleNotificationAction)
            } else {
                dispatch({ type: 'OPEN_NOTIFICATION', notification: next, num: num } as OpenNotificationAction)
            }
            const { notifications } = await agent.Dashboard.fetchNotifications(params)
            dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
        }
    },
    addSchoolNotification: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let staff = getState().staff.staff
        let notification = state.modals.notification
        let num = notification.num
        let notificationList = _.filter(state.notifications.list, (n:any) => n.type == notification.type)
        let next = notificationList[notification.num+1]
        if (next == undefined) {
            num -= 1
            next = notificationList[notification.num-1]
        }
        var facility = state.facility
        if (!facility) {
            if (staff && staff.admin) {
                facility = 'all'
            } else {
                facility = 'my'
            }
        }
        let params = new URLSearchParams()
        params.append('facility', facility)
        const { errors } = await agent.Admin.addRequest(Object.assign(_.clone(notification.data), { override: false }))
        if (errors.length > 0) {
            var msg = errors[0]
            if (msg == 'duplicate_names') { msg = 'School name already exists' }
            if (msg == 'duplicate_addresses') { msg = 'School address already exists' }
            if (msg == 'duplicate_phones') { msg = 'School phone already exists' }
            if (msg == 'duplicate_emails') { msg = 'School email already exists' }
            toast.error(msg, { autoClose: false })
        } else {
            toast.success(`School added: ${notification.message}`)
            if (next == undefined) {
                dispatch({ type: 'TOGGLE_DASHBOARD_NOTIFICATION' } as ToggleNotificationAction)
            } else {
                dispatch({ type: 'OPEN_NOTIFICATION', notification: next, num: num } as OpenNotificationAction)
            }
            const { notifications } = await agent.Dashboard.fetchNotifications(params)
            dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
        }
    },
    removeSchoolNotification: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let staff = getState().staff.staff
        let notification = state.modals.notification
        let num = notification.num
        let notificationList = _.filter(state.notifications.list, (n:any) => n.type == notification.type)
        let next = notificationList[notification.num+1]
        if (next == undefined) {
            num -= 1
            next = notificationList[notification.num-1]
        }
        var facility = state.facility
        if (!facility) {
            if (staff && staff.admin) {
                facility = 'all'
            } else {
                facility = 'my'
            }
        }
        let params = new URLSearchParams()
        params.append('facility', facility)
        const { error } = await agent.Admin.removeRequest(notification.data)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success(`Request removed: ${notification.message}`)
            if (next == undefined) {
                dispatch({ type: 'TOGGLE_DASHBOARD_NOTIFICATION' } as ToggleNotificationAction)
            } else {
                dispatch({ type: 'OPEN_NOTIFICATION', notification: next, num: num } as OpenNotificationAction)
            }
            const { notifications } = await agent.Dashboard.fetchNotifications(params)
            dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
        }
    },
    updateStaffCollapse: (name:DashboardIndex): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let staff = getState().staff.staff
        let checked = !state[name].collapse
        var facility = state.facility
        if (!facility) {
            if (staff && staff.admin) {
                facility = 'all'
            } else {
                facility = 'my'
            }
        }
        let params = new URLSearchParams()
        params.append('facility', facility)
        buildParams(state[name].sorts, params)
        const { error } = await agent.Dashboard.updateStaffCollapse({ name:name, active:checked })
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            dispatch({ type: 'TOGGLE_DASHBOARD_COLLAPSE', table: name } as ToggleCollapseAction)
        }
    },
    updateStaffNotification: (name:DashboardIndex, checked:boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let staff = getState().staff.staff
        var facility = state.facility
        if (!facility) {
            if (staff && staff.admin) {
                facility = 'all'
            } else {
                facility = 'my'
            }
        }
        let params = new URLSearchParams()
        params.append('facility', facility)
        buildParams(state[name].sorts, params)
        const { error } = await agent.Dashboard.updateStaffNotification({ name:name, active:checked })
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            switch (name) {
            case 'staff':
                toast.success('Staff expiring notification updated')
                const { staffData, staffNotify, staffCollapse } = await agent.Dashboard.fetchStaff(params)
                dispatch({ type: 'FETCH_DASHBOARD_STAFF', data: staffData, notify: staffNotify, collapse: staffCollapse } as FetchStaffAction)
                break;
            case 'pending':
                toast.success('Pending attendance notification updated')
                const { pendingData, pendingNotify, pendingCollapse } = await agent.Dashboard.fetchPending(params)
                dispatch({ type: 'FETCH_DASHBOARD_PENDING', data: pendingData, notify: pendingNotify, collapse: pendingCollapse } as FetchPendingAction)
                break;
            case 'orphans':
                toast.success('TABE orphans notification updated')
                const { orphanData, orphanNotify, orphanCollapse } = await agent.Dashboard.fetchOrphans(params)
                dispatch({ type: 'FETCH_DASHBOARD_ORPHANS', data: orphanData, notify: orphanNotify, collapse: orphanCollapse } as FetchOrphansAction)
                break;
            case 'approvals':
                toast.success('Class approvals notification updated')
                const { approvalData, approvalNotify, approvalCollapse } = await agent.Dashboard.fetchApprovals(params)
                dispatch({ type: 'FETCH_DASHBOARD_APPROVALS', data: approvalData, notify: approvalNotify, collapse: approvalCollapse } as FetchApprovalsAction)
                break;
            case 'schools':
                toast.success('School requests notification updated')
                var { requestData, requestNotify, requestCollapse } = await agent.Dashboard.fetchSchools(params)
                dispatch({ type: 'FETCH_DASHBOARD_SCHOOLS', data: requestData, notify: requestNotify, collapse: requestCollapse } as FetchSchoolsAction)
                break;
            case 'transcripts':
                toast.success('Transcript requests notification updated')
                var { requestData, requestNotify, requestCollapse } = await agent.Dashboard.fetchTranscripts(params)
                dispatch({ type: 'FETCH_DASHBOARD_TRANSCRIPTS', data: requestData, notify: requestNotify, collapse: requestCollapse } as FetchTranscriptsAction)
                break;
            case 'instructors':
                toast.success('Class instructors notification updated')
                const { instructorData, instructorNotify, instructorCollapse } = await agent.Dashboard.fetchInstructors(params)
                dispatch({ type: 'FETCH_DASHBOARD_INSTRUCTORS', data: instructorData, notify: instructorNotify, collapse: instructorCollapse } as FetchInstructorsAction)
                break;
            case 'funding':
                toast.success('Class funding notification updated')
                const { fundingData, fundingNotify, fundingCollapse } = await agent.Dashboard.fetchFunding(params)
                dispatch({ type: 'FETCH_DASHBOARD_FUNDING', data: fundingData, notify: fundingNotify, collapse: fundingCollapse } as FetchFundingAction)
                break;
            case 'classes':
                toast.success('Classes ending notification updated')
                const { classData, classNotify, classCollapse } = await agent.Dashboard.fetchClasses(params)
                dispatch({ type: 'FETCH_DASHBOARD_CLASSES', data: classData, notify: classNotify, collapse: classCollapse } as FetchClassesAction)
                break;
            case 'arrivals':
                toast.success('Recent transfers notification updated')
                const { arrivalData, arrivalNotify, arrivalCollapse } = await agent.Dashboard.fetchArrivals(params)
                dispatch({ type: 'FETCH_DASHBOARD_ARRIVALS', data: arrivalData, notify: arrivalNotify, collapse: arrivalCollapse } as FetchArrivalsAction)
                break;
            case 'attendance':
                toast.success('Low attendance notification updated')
                const { attendanceData, attendanceNotify, attendanceCollapse } = await agent.Dashboard.fetchAttendance(params)
                dispatch({ type: 'FETCH_DASHBOARD_ATTENDANCE', data: attendanceData, notify: attendanceNotify, collapse: attendanceCollapse } as FetchAttendanceAction)
                break;
            }
            dispatch({ type: 'UPDATE_STAFF_NOTIFICATION', name: name, checked: checked } as UpdateNotificationAction)
            const { notifications } = await agent.Dashboard.fetchNotifications(params)
            dispatch({ type: 'FETCH_DASHBOARD_NOTIFICATIONS', notifications: notifications } as FetchNotificationsAction)
        }
    },
    selectPendingNotification: (sessionID: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let notification = state.modals.notification
        let num = notification.num
        let notificationList = _.filter(state.notifications.list, (n:any) => n.type == notification.type)
        let next = notificationList[notification.num+1]
        if (next == undefined) {
            num -= 1
            next = notificationList[notification.num-1]
        }
        const { pending, notes } = await agent.Classes.fetchSession(notification.data.classID, sessionID)
        dispatch({ type: 'SELECT_PENDING_NOTIFICATION', sessionID: sessionID, notes: notes, rosters: pending } as SelectPendingNotificationAction)
    },
    generateWarning: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().dashboard
        let notification = state.modals.notification
        let params = new URLSearchParams()
        params.append('type', 'warning')
        params.append('rosters', notification.data.rosterID.toString())
        dispatch({ type: 'LOADING_DASHBOARD_REPORT', report: 'warning', loading: true } as LoadingDashboardReportAction)
        const reportData = await agent.Reports.fetchNotificationsWarning(params)
        dispatch({ type: 'GENERATE_DASHBOARD_REPORT', report: 'warning', data: reportData } as GenerateDashboardReportAction)
    },
    loadFilter: (defaults: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let id = getState().staff.staff ? getState().staff.staff!.recordID : 0
        let facility = store(`${id}:filters:dashboard:facility`) || defaults
        let params = new URLSearchParams()
        params.append('facility', facility)
        dispatch({ type: 'SET_DASHBOARD_FACILITY', facility: facility } as SetFacilityAction)
    }
}

export const reducer: Reducer<DashboardState> = (state: DashboardState | undefined, incomingAction: Action): DashboardState => {
    if (state === undefined) {
        return {
            open: true,
            facility: '',
            refresh: Date.now(),
            order: [],
            options: {
                facilities: []
            },
            notifications: { list: [] },
            news: { announcements: null, notices: null },
            staff: { loading: true, notify: false, collapse: false, sorts: [{ column: 'date', dir: 'desc' },{ column: 'facilities', dir: 'asc' }], data: {} },
            orphans: { loading: true, notify: false, collapse: false, sorts: [{ column: 'date', dir: 'desc' },{ column: 'facility', dir: 'asc' }], data: {} },
            approvals: { loading: true, notify: false, collapse: false, sorts: [{ column: 'date', dir: 'desc' },{ column: 'class', dir: 'asc' }], data: {} },
            transcripts: { loading: true, notify: false, collapse: false, sorts: [{ column: 'followup', dir: 'desc' },{ column: 'date', dir: 'desc' }], data: {} },
            pending: { loading: true, notify: false, collapse: false, sorts: [{ column: 'facility', dir: 'asc' },{ column: 'class', dir: 'asc' }], data: {} },
            instructors: { loading: true, notify: false, collapse: false, sorts: [{ column: 'facility', dir: 'asc' },{ column: 'name', dir: 'asc' }], data: {} },
            funding: { loading: true, notify: false, collapse: false, sorts: [{ column: 'facility', dir: 'asc' },{ column: 'name', dir: 'asc' }], data: {} },
            arrivals: { loading: true, notify: false, collapse: false, sorts: [{ column: 'date', dir: 'desc'},{ column: 'name', dir: 'asc' }], data: {} },
            classes: { loading: true, notify: false, collapse: false, sorts: [{ column: 'endDate', dir: 'desc' },{ column: 'facility', dir: 'asc' }], data: {} },
            attendance: { loading: true, notify: false, collapse: false, sorts: [{ column: 'inmate', dir: 'asc' },{ column: 'facility', dir: 'asc' }], data: {} },
            schools: { loading: true, notify: false, collapse: false, sorts: [{ column: 'id', dir: 'desc' }], data: {} },
            tabes: { loading: true, data: {} },
            ands: { loading: true, data: {} },
            surveys: { data: [] },
            modals: {
                notification: { open: false, num: 0, linkID: 0, message: '', type: '', data: {} },
                extend: { open: false, id: 0, expDate: '', name: '', date: null, all: false },
                archive: { open: false, id: 0, name: '' },
                expire: { open: false, id: 0, name: '' },
                class: { open: false, id: 0, name: '', date: null },
                survey: { open: false, id: 0, name: '', questions: [], grids: [] },
                universal: { open: false, data: {} },
                search: { open: false, data: {} }
            },
            pdfs: {
                warning: {
                    generating: false,
                    ready: false,
                    url: '',
                    data: {}
                }
            }
        }
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'TOGGLE_DASHBOARD':
            return {
                ...state,
                open: !state.open
            }
        case 'SET_DASHBOARD_FACILITY':
            return {
                ...state,
                facility: action.facility,
                staff: { loading: true, notify: false, collapse: false, sorts: [{ column: 'date', dir: 'desc' },{ column: 'facilities', dir: 'asc' }], data: {} },
                orphans: { loading: true, notify: false, collapse: false, sorts: [{ column: 'date', dir: 'desc' },{ column: 'facility', dir: 'asc' }], data: {} },
                approvals: { loading: true, notify: false, collapse: false, sorts: [{ column: 'date', dir: 'desc' },{ column: 'class', dir: 'asc' }], data: {} },
                transcripts: { loading: true, notify: false, collapse: false, sorts: [{ column: 'followup', dir: 'desc' },{ column: 'date', dir: 'desc' }], data: {} },
                pending: { loading: true, notify: false, collapse: false, sorts: [{ column: 'facility', dir: 'asc' },{ column: 'class', dir: 'asc' }], data: {} },
                instructors: { loading: true, notify: false, collapse: false, sorts: [{ column: 'facility', dir: 'asc' },{ column: 'name', dir: 'asc' }], data: {} },
                funding: { loading: true, notify: false, collapse: false, sorts: [{ column: 'facility', dir: 'asc' },{ column: 'name', dir: 'asc' }], data: {} },
                arrivals: { loading: true, notify: false, collapse: false, sorts: [{ column: 'date', dir: 'desc'},{ column: 'name', dir: 'asc' }], data: {} },
                classes: { loading: true, notify: false, collapse: false, sorts: [{ column: 'endDate', dir: 'desc' },{ column: 'facility', dir: 'asc' }], data: {} },
                attendance: { loading: true, notify: false, collapse: false, sorts: [{ column: 'inmate', dir: 'asc' },{ column: 'facility', dir: 'asc' }], data: {} },
                schools: { loading: true, notify: false, collapse: false, sorts: [{ column: 'id', dir: 'desc' }], data: {} },
                tabes: { loading: true, data: {} },
                ands: { loading: true, data: {} },
            }
        case 'TOGGLE_DASHBOARD_EXTEND':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    extend: {
                        ...state.modals.extend,
                        open: !state.modals.extend.open
                    }
                }
            }
        case 'TOGGLE_DASHBOARD_SEARCH':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    search: {
                        ...state.modals.search,
                        open: !state.modals.search.open
                    }
                }
            }
        case 'TOGGLE_DASHBOARD_NOTIFICATION':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    notification: {
                        ...state.modals.notification,
                        open: !state.modals.notification.open
                    }
                }
            }
        case 'TOGGLE_DASHBOARD_ARCHIVE':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    archive: {
                        ...state.modals.archive,
                        open: !state.modals.archive.open
                    }
                }
            }
        case 'TOGGLE_DASHBOARD_EXPIRE':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    expire: {
                        ...state.modals.expire,
                        open: !state.modals.expire.open
                    }
                }
            }
        case 'TOGGLE_DASHBOARD_CLASS':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    class: {
                        ...state.modals.class,
                        open: !state.modals.class.open
                    }
                }
            }
        case 'TOGGLE_DASHBOARD_COLLAPSE':
            return {
                ...state,
                [action.table]: {
                    ...state[action.table as DashboardIndex],
                    collapse: !state[action.table as DashboardIndex].collapse
                }
            }
        case 'TOGGLE_DASHBOARD_ORDER':
            return {
                ...state,
                [action.table]: {
                    ...state[action.table as DashboardIndex],
                    sorts: action.sorts
                }
            }
        case 'UPDATE_NOTIFICATION_DATA':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    notification: {
                        ...state.modals.notification,
                        data: {
                            ...state.modals.notification.data,
                            [action.key]: action.value
                        }
                    }
                }
            }
        case 'UPDATE_DASHBOARD_EXTEND':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    extend: {
                        ...state.modals.extend,
                        [action.key]: action.value
                    }
                }
            }
        case 'UPDATE_DASHBOARD_CLASS':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    class: {
                        ...state.modals.class,
                        [action.key]: action.value
                    }
                }
            }
        case 'SELECT_EXTEND_STAFF':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    extend: {
                        open: true,
                        id: action.staff.recordID,
                        name: action.staff.name,
                        expDate: action.staff.expDate,
                        date: action.date,
                        all: false
                    }
                }
            }
        case 'SELECT_ARCHIVE_STAFF':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    archive: {
                        open: true,
                        id: action.staff.recordID,
                        name: action.staff.name
                    }
                }
            }
        case 'SELECT_EXPIRE_STAFF':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    expire: {
                        open: true,
                        id: action.staff.recordID,
                        name: action.staff.name
                    }
                }
            }
        case 'SELECT_ORPHAN_INMATE':
            return {
                ...state,
                orphans: {
                    ...state.orphans,
                    data: {
                        ...state.orphans.data,
                        list: _.map(state.orphans.data.list, (orphan:any) => orphan.recordID == action.recordID ? Object.assign(orphan, { id: action.key, value: action.value }) : orphan),
                        inmates: []
                    }
                }
            }
        case 'SELECT_ORPHAN_INMATE_NOTIFICATION':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    notification: {
                        ...state.modals.notification,
                        data: {
                            ...state.modals.notification.data,
                            id: action.key,
                            value: action.value,
                            inmates: []
                        }
                    }
                }
            }
        case 'SELECT_SEARCH_RESULT':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    universal: {
                        ...state.modals.universal,
                        open: false,
                        data: {
                            ...state.modals.universal.data,
                            id: action.key,
                            value: action.value,
                            results: []
                        }
                    }
                }
            }
        case 'SEARCH_ORPHANS_LOADING':
            return {
                ...state,
                orphans: {
                    ...state.orphans,
                    data: {
                        ...state.orphans.data,
                        list: _.map(state.orphans.data.list, (orphan:any) => orphan.recordID == action.recordID ? Object.assign(orphan, { id: 0, value: action.value, loading: true }) : orphan)
                    }
                }
            }
        case 'SEARCH_ORPHANS_LOADING_NOTIFICATION':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    notification: {
                        ...state.modals.notification,
                        data: {
                            ...state.modals.notification.data,
                            id: 0,
                            value: action.value,
                            loading: true
                        }
                    }
                }
            }
        case 'SEARCH_ORPHANS':
            return {
                ...state,
                orphans: {
                    ...state.orphans,
                    data: {
                        ...state.orphans.data,
                        list: _.map(state.orphans.data.list, (orphan:any) => orphan.recordID == action.recordID ? Object.assign(orphan, { loading: false }) : orphan),
                        inmates: action.inmates
                    }
                }
            }
        case 'SEARCH_ORPHANS_NOTIFICATION':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    notification: {
                        ...state.modals.notification,
                        data: {
                            ...state.modals.notification.data,
                            loading: false,
                            inmates: action.inmates
                        }
                    }
                }
            }
        case 'SEARCH_UNIVERSAL_LOADING':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    universal: {
                        ...state.modals.universal,
                        data: {
                            ...state.modals.universal.data,
                            id: 0,
                            value: action.value,
                            loading: true
                        }
                    }
                }
            }
        case 'SEARCH_UNIVERSAL_RESULTS':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    universal: {
                        ...state.modals.universal,
                        data: {
                            ...state.modals.universal.data,
                            loading: false,
                            results: action.results
                        }
                    }
                }
            }
        case 'OPEN_UNIVERSAL_RESULTS':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    universal: {
                        ...state.modals.universal,
                        open: true
                    }
                }
            }
        case 'CLOSE_UNIVERSAL_RESULTS':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    universal: {
                        ...state.modals.universal,
                        open: false
                    }
                }
            }
        case 'SELECT_EXTEND_CLASS':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    class: {
                        open: true,
                        id: action.class.recordID,
                        name: action.class.className,
                        date: null
                    }
                }
            }
        case 'FETCH_DASHBOARD_NOTIFICATIONS':
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    list: action.notifications
                }
            }
        case 'FETCH_DASHBOARD_NEWS':
            return {
                ...state,
                news: {
                    ...state.news,
                    announcements: action.announcements,
                    notices: action.notices
                }
            }
        case 'FETCH_DASHBOARD_STAFF':
            return {
                ...state,
                staff: {
                    ...state.staff,
                    loading: false,
                    notify: action.notify,
                    collapse: action.collapse,
                    data: action.data
                }
            }
        case 'FETCH_DASHBOARD_ORPHANS':
            return {
                ...state,
                orphans: {
                    ...state.orphans,
                    loading: false,
                    notify: action.notify,
                    collapse: action.collapse,
                    data: {
                        id: 0,
                        value: '',
                        loading: false,
                        list: action.data
                    }
                }
            }
        case 'FETCH_DASHBOARD_APPROVALS':
            return {
                ...state,
                approvals: {
                    ...state.approvals,
                    loading: false,
                    notify: action.notify,
                    collapse: action.collapse,
                    data: action.data
                }
            }
        case 'FETCH_DASHBOARD_TABES':
            return {
                ...state,
                tabes: {
                    ...state.tabes,
                    loading: false,
                    data: action.data
                }
            }
        case 'FETCH_DASHBOARD_SCHOOLS':
            return {
                ...state,
                schools: {
                    ...state.schools,
                    loading: false,
                    notify: action.notify,
                    collapse: action.collapse,
                    data: action.data
                }
            }
        case 'FETCH_DASHBOARD_TRANSCRIPTS':
            return {
                ...state,
                transcripts: {
                    ...state.transcripts,
                    loading: false,
                    notify: action.notify,
                    collapse: action.collapse,
                    data: action.data
                }
            }
        case 'FETCH_DASHBOARD_FUNDING':
            return {
                ...state,
                funding: {
                    ...state.funding,
                    loading: false,
                    notify: action.notify,
                    collapse: action.collapse,
                    data: action.data
                }
            }
        case 'FETCH_DASHBOARD_INSTRUCTORS':
            return {
                ...state,
                instructors: {
                    ...state.instructors,
                    loading: false,
                    notify: action.notify,
                    collapse: action.collapse,
                    data: action.data
                }
            }
        case 'FETCH_DASHBOARD_CLASSES':
            return {
                ...state,
                classes: {
                    ...state.classes,
                    loading: false,
                    notify: action.notify,
                    collapse: action.collapse,
                    data: action.data
                }
            }
        case 'FETCH_DASHBOARD_ARRIVALS':
            return {
                ...state,
                arrivals: {
                    ...state.arrivals,
                    loading: false,
                    notify: action.notify,
                    collapse: action.collapse,
                    data: action.data
                }
            }
        case 'FETCH_DASHBOARD_PENDING':
            return {
                ...state,
                pending: {
                    ...state.pending,
                    loading: false,
                    notify: action.notify,
                    collapse: action.collapse,
                    data: action.data
                }
            }
        case 'FETCH_DASHBOARD_ATTENDANCE':
            return {
                ...state,
                attendance: {
                    ...state.attendance,
                    loading: false,
                    notify: action.notify,
                    collapse: action.collapse,
                    data: action.data
                }
            }
        case 'FETCH_DASHBOARD_ANDS':
            return {
                ...state,
                ands: {
                    ...state.ands,
                    loading: false,
                    data: action.data
                }
            }
        case 'FETCH_DASHBOARD_SURVEYS':
            return {
                ...state,
                surveys: {
                    ...state.surveys,
                    data: action.data
                }
            }
        case 'FETCH_DASHBOARD_OPTIONS':
            return {
                ...state,
                order: action.order,
                options: {
                    facilities: action.facilities
                }
            }
        case 'UPDATE_STAFF_NOTIFICATION':
            return {
                ...state,
                [action.name]: {
                    ...state[action.name as 'staff'],
                    notify: action.checked
                }
            }
        case 'OPEN_SURVEY':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    survey: {
                        open: true,
                        id: action.id,
                        name: action.name,
                        questions: action.questions,
                        grids: action.grids
                    }
                }
            }
        case 'OPEN_NOTIFICATION':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    notification: {
                        open: true,
                        num: action.num,
                        linkID: action.notification.linkID,
                        message: action.notification.message,
                        type: action.notification.type,
                        data: action.notification.data
                    }
                }
            }
        case 'CLOSE_SURVEY':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    survey: {
                        open: false,
                        id: 0,
                        name: '',
                        questions: [],
                        grids: []
                    }
                }
            }
        case 'SELECT_OPTION':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    survey: {
                        ...state.modals.survey,
                        questions: _.map(state.modals.survey.questions, (question:any) => question.recordID == action.questionID ? Object.assign(question, { answerID: action.optionID }) : question)
                    }
                }
            }
        case 'SELECT_GRID_OPTION':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    survey: {
                        ...state.modals.survey,
                        grids: _.map(state.modals.survey.grids, (grid:any) => grid.recordID == action.gridID ? Object.assign(grid, { questions: _.map(grid.questions, (question:any) => question.recordID == action.questionID ? Object.assign(question, { answerID: action.optionID }) : question) }) : grid)
                    }
                }
            }
        case 'SET_DASHBOARD_REFRESH':
            return {
                ...state,
                refresh: action.refresh
            }
        case 'SET_DASHBOARD_URL':
            return {
                ...state,
                pdfs: {
                    ...state.pdfs,
                    [action.report]: {
                        generating: false,
                        ready: true,
                        url: action.url,
                        data: {}
                    }
                }
            }
        case 'LOADING_DASHBOARD_REPORT':
            return {
                ...state,
                pdfs: {
                    ...state.pdfs,
                    [action.report]: {
                        generating: true,
                        ready: false,
                        url: '',
                        data: {}
                    }
                }
            }
        case 'GENERATE_DASHBOARD_REPORT':
            return {
                ...state,
                pdfs: {
                    ...state.pdfs,
                    [action.report]: {
                        generating: false,
                        ready: true,
                        url: '',
                        data: action.data
                    }
                }
            }
        case 'SELECT_PENDING_NOTIFICATION':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    notification: {
                        ...state.modals.notification,
                        data: {
                            ...state.modals.notification.data,
                            selected: {
                                sessionID: action.sessionID,
                                notes: action.notes,
                                rosters: action.rosters
                            }
                        }
                    }
                }
            }
        case 'PENDING_CHANGE_NOTIFICATION':
            var roster = _.find(action.selected.rosters, (roster:any) => roster.recordID == action.rosterID)
            return {
                ...state,
                modals: {
                    ...state.modals,
                    notification: {
                        ...state.modals.notification,
                        data: {
                            ...state.modals.notification.data,
                            selected: {
                                ...state.modals.notification.data.selected,
                                rosters: roster.pendingLog != null ? action.selected.rosters : _.map(action.selected.rosters, (roster:any) => roster.recordID == action.rosterID ? Object.assign(_.clone(roster), { log: action.log }) : Object.assign(_.clone(roster), { log: roster.pendingLog != null ? roster.pendingLog : ['A','X','Z'].includes(roster.log) ? roster.log : 'A' }))
                            }
                        }
                    }
                }
            }
        case 'PENDING_CLASS_CHANGE_NOTIFICATION':
            var roster = _.find(action.selected.rosters, (roster:any) => roster.recordID == action.rosterID)
            return {
                ...state,
                modals: {
                    ...state.modals,
                    notification: {
                        ...state.modals.notification,
                        data: {
                            ...state.modals.notification.data,
                            selected: {
                                ...state.modals.notification.data.selected,
                                rosters: roster.pendingLog != null || ['S','Q'].includes(action.log) ? action.selected.rosters : _.map(action.selected.rosters, (roster:any) => roster.recordID == action.rosterID ? Object.assign(_.clone(roster), { log: action.log }) : Object.assign(_.clone(roster), { log: roster.pendingLog != null ? roster.log : action.log }))
                            }
                        }
                    }
                }
            }
        case 'UPDATE_PENDING_NOTES_NOTIFICATION':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    notification: {
                        ...state.modals.notification,
                        data: {
                            ...state.modals.notification.data,
                            selected: {
                                ...state.modals.notification.data.selected,
                                notes: action.value
                            }
                        }
                    }
                }
            }
        case 'SUBMIT_DASHBOARD_ATTENDANCE':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    notification: {
                        ...state.modals.notification,
                        data: {
                            ...state.modals.notification.data,
                            pending: _.filter(state.modals.notification.data.pending, (session:any) => session.sessionID != action.sessionID)
                        }
                    }
                }
            }
        default:
            return state
    }
}
