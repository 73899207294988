import * as React from 'react'
import { connect } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import { withRouter, WithRouterProps } from './withRouter'
import * as StaffStore from './store/reducers/staff'
import { ApplicationState } from './store'
import Home from './components/Home'
import AuthGoogle from './components/auth/Google'
import ResetPassword from './components/ResetPassword'
import AttendanceHTML from './components/reports/html/Attendance'
import QuarterlyHTML from './components/reports/html/Quarterly'
import RosterHTML from './components/reports/html/Roster'
import TabeHTML from './components/reports/html/TABE'
import GedHTML from './components/reports/html/GED'
import GraduatedHTML from './components/reports/html/Graduated'
import HisetHTML from './components/reports/html/HiSET'
import KamakaniHTML from './components/reports/html/Kamakani'
import DescriptionsHTML from './components/reports/html/Descriptions'
import UtilizationHTML from './components/reports/html/Utilization'
import TracingHTML from './components/reports/html/Tracing'
import CsprHTML from './components/reports/html/CSPR'
import FundingHTML from './components/reports/html/Funding'
import ANDsHTML from './components/reports/html/ANDs'
import RecidivismHTML from './components/reports/html/Recidivism'
import { ToastContainer } from 'react-toastify'

//import 'semantic-ui-css/semantic.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css';
import 'medium-draft/lib/index.css'
import './custom.css'

type AppProps =
    typeof StaffStore.actionCreators &
    WithRouterProps<{}>

class App extends React.PureComponent<AppProps> {
    public componentDidMount() {
        this.props.fetchStaff()
        this.props.fetchSettings()
    }

    public render() {
      return (
        <>
          <ToastContainer position='bottom-right' />
          <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/panes/:mode/:pane1' element={<Home />} />
              <Route path='/panes/:mode/:pane1/:pane2' element={<Home />} />
              <Route path='/billing' element={<Home />} />
              <Route path='/billing/projects/:projectID' element={<Home />} />
              <Route path='/billing/projects/:projectID/features/:featureID' element={<Home />} />
              <Route path='/billing/projects/:projectID/issues/:issueID' element={<Home />} />
              <Route path='/billing/projects/:projectID/tasks/:taskID' element={<Home />} />
              <Route path='/auth/google' element={<AuthGoogle />} />
              <Route path='/reset/:code' element={<ResetPassword />} />
              <Route path='/reports/attendance/html' element={<AttendanceHTML />} />
              <Route path='/reports/quarterly/html' element={<QuarterlyHTML />} />
              <Route path='/reports/roster/html' element={<RosterHTML />} />
              <Route path='/reports/tabe/html' element={<TabeHTML />} />
              <Route path='/reports/graduated/html' element={<GraduatedHTML />} />
              <Route path='/reports/ged/html' element={<GedHTML />} />
              <Route path='/reports/hiset/html' element={<HisetHTML />} />
              <Route path='/reports/kamakani/html' element={<KamakaniHTML />} />
              <Route path='/reports/descriptions/html' element={<DescriptionsHTML />} />
              <Route path='/reports/utilization/html' element={<UtilizationHTML />} />
              <Route path='/reports/tracing/html' element={<TracingHTML />} />
              <Route path='/reports/cspr/html' element={<CsprHTML />} />
              <Route path='/reports/funding/html' element={<FundingHTML />} />
              <Route path='/reports/ands/html' element={<ANDsHTML />} />
              <Route path='/reports/recidivism/html' element={<RecidivismHTML />} />
              {/*<Redirect from="*" to="/" />*/}
          </Routes>
        </>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return {  } },
  StaffStore.actionCreators
)(App as any)
