import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import ClassData from './ClassData'
import ClassRoster from './ClassRoster'
import ClassAttendance from './ClassAttendance'
import ClassCompletions from './ClassCompletions'
import ClassTranscripts from './ClassTranscripts'
import {
  Container,
  Icon,
  Header,
  Tab,
  Grid,
  Message,
  Button,
  Checkbox,
  Popup
} from 'semantic-ui-react'

type PaneProps =
    { class: PanesStore.Class, num: 1 | 2, print: boolean, hide: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassPane extends React.PureComponent<PaneProps> {
    private dayOfWeek3 = (day:number) => {
        switch(day) {
          case 0: {
            return 'Sun'
          }
          case 1: {
            return 'Mon'
          }
          case 2: {
            return 'Tue'
          }
          case 3: {
            return 'Wed'
          }
          case 4: {
            return 'Thu'
          }
          case 5: {
            return 'Fri'
          }
          case 6: {
            return 'Sat'
          }
          default: {
            return ''
          }
        }
    }

    public render() {
        if (this.props.class.info.noAttendance) {
          var panes = [
            { menuItem: 'Class', render: () => <Tab.Pane><ClassData class={this.props.class} num={this.props.num} print={this.props.print} hide={this.props.hide} /></Tab.Pane> },
            { menuItem: 'Roster', render: () => <Tab.Pane><ClassRoster class={this.props.class} num={this.props.num} print={this.props.print} /></Tab.Pane> },
            { menuItem: 'Completions', render: () => <Tab.Pane><ClassCompletions class={this.props.class} num={this.props.num} print={this.props.print} /></Tab.Pane> },
            { menuItem: 'Transcripts', render: () => <Tab.Pane><ClassTranscripts class={this.props.class} num={this.props.num} print={this.props.print} /></Tab.Pane> }
          ]
        } else {
          var panes = [
            { menuItem: 'Class', render: () => <Tab.Pane><ClassData class={this.props.class} num={this.props.num} print={this.props.print} hide={this.props.hide} /></Tab.Pane> },
            { menuItem: 'Roster', render: () => <Tab.Pane><ClassRoster class={this.props.class} num={this.props.num} print={this.props.print} /></Tab.Pane> },
            { menuItem: 'Attendance', render: () => <Tab.Pane><ClassAttendance class={this.props.class} num={this.props.num} print={this.props.print} /></Tab.Pane> },
            { menuItem: 'Completions', render: () => <Tab.Pane><ClassCompletions class={this.props.class} num={this.props.num} print={this.props.print} /></Tab.Pane> },
            { menuItem: 'Transcripts', render: () => <Tab.Pane><ClassTranscripts class={this.props.class} num={this.props.num} print={this.props.print} /></Tab.Pane> }
          ]
        }

        return (
          <Container fluid>
            {this.props.print ? null : <div className='close' style={{position:'absolute',top:0,left:0,zIndex:1}}>
              <Button circular color='red' icon='close' size='mini' onClick={() => this.props.togglePane(this.props.num)} />
            </div>}
            <Popup content={this.props.print ? `Exit from print screen mode.` : `Enter into print screen mode.`} position="bottom right" trigger={<Button color='blue' circular icon='print' onClick={() => this.props.togglePrint(this.props.num)} style={{float:'right',fontSize:'1.2em',marginTop:15}} />} />
            {this.props.print ? null : this.props.class.favorite ? <Popup content={`Remove the class from the favorites list.`} position="bottom right" trigger={<Icon className='favorite' name='star' color='blue' onClick={() => this.props.unfavoriteClass(this.props.num)} style={{float:'right',fontSize:'2em',marginTop:27,marginRight:18}} />} /> : <Popup content={`Add class to the favorites list.`} position="bottom right" trigger={<Icon className='favorite' name='star outline' color='blue' onClick={() => this.props.favoriteClass(this.props.num)} style={{float:'right',fontSize:'2em',marginTop:27,marginRight:18}} />} />}
            {this.props.print ? <Checkbox label='Hide empty tables' checked={this.props.hide} onChange={() => this.props.togglePrintHide(this.props.num)} style={{marginRight:20,marginTop:25,float:'right'}} /> : null}
            {this.props.class.data.active ? null : <Message warning compact header='Archived' style={{float:'right',marginRight:10}} />}
            <Grid style={{marginBottom:0}}>
              <Grid.Row style={{paddingBottom:0}}>
                <Grid.Column>
                  <Header as='h2' style={{display:'inline-block',marginTop:0,whiteSpace:'normal'}}><div><Icon name='book' /> {this.props.class.data.subTitle} {this.props.class.data.sex == "M" || this.props.class.data.sex == "F" || this.props.class.data.facilitySex == "M" || this.props.class.data.facilitySex == "F" ? <img src={this.props.class.data.sex == "M" || this.props.class.data.facilitySex == "M" ? "/assets/male.png" : "/assets/female.png"} style={{height:20,width:20,marginLeft:8,marginTop:-2,display:'inline-block'}} /> : null} <span style={{fontSize:'0.8em',color:'#ccc',marginLeft:10,verticalAlign:'middle'}}>{this.props.class.info.recordID}</span></div></Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  {this.props.class.data.dateBegins == null ? null : <div><span style={{width:70,display:'inline-block',marginBottom:5}}><b>Start Date:</b></span> {this.props.class.data.dateBegins}</div>}
                  {this.props.class.data.dateEnds == null ? null : <div><span style={{width:70,display:'inline-block',marginBottom:5}}><b>End Date:</b></span> {this.props.class.data.dateEnds}</div>}
                </Grid.Column>
                {this.props.class.data.hiatusBegins == null ? null : <Grid.Column width={4}>
                  <div><span style={{width:90,display:'inline-block',marginBottom:5}}><b>Hiatus Begins:</b></span> {this.props.class.data.hiatusBegins}</div>
                  <div><span style={{width:90,display:'inline-block',marginBottom:5}}><b>Hiatus Ends:</b></span> {this.props.class.data.hiatusEnds}</div>
                </Grid.Column>}
                <Grid.Column width={12} style={{display:'flex',flexWrap:'wrap'}}>
                  {this.props.class.schedules.map((schedule:any, i:number) => (
                    <Fragment key={i}>
                      <div style={{marginBottom:5,marginLeft:20}}><span style={{width:36,display:'inline-block'}}><b>{this.dayOfWeek3(schedule.dayOfWeek)}.</b></span> {`${schedule.startTime} - ${schedule.endTime}${schedule.instructor ? ` ~ ${schedule.instructor}` : ``}${schedule.location ? ` ~ ${schedule.location}` : ``}`}</div>
                      {schedule.grouped.map((schedule:any, i:number) => (
                        <div key={i} style={{marginBottom:5,marginLeft:20}}><span style={{width:36,display:'inline-block'}}><b>{this.dayOfWeek3(schedule.dayOfWeek)}.</b></span> {`${schedule.startTime} - ${schedule.endTime}${schedule.instructor ? ` ~ ${schedule.instructor}` : ``}${schedule.location ? ` ~ ${schedule.location}` : ``}`}</div>
                      ))}
                    </Fragment>
                  ))}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Tab menu={{ attached: false }} panes={this.props.print ? [panes[this.props.class.index]] : panes} activeIndex={this.props.print ? 0 : this.props.class.index} onTabChange={(e, { activeIndex }) => { if (typeof activeIndex == 'number') this.props.switchClassTab(this.props.num, activeIndex) }} className='blue' style={{clear:'both'}} />
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, print: ownProps.options.print, hide: ownProps.options.hide } },
  PanesStore.actionCreators
)(ClassPane as any)