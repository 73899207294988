import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { Link } from 'react-router-dom'
import { ApplicationState } from '../../store'
import * as ReportsStore from '../../store/reducers/reports'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import { Editor } from 'medium-draft'
import mediumDraftExporter from 'medium-draft/lib/exporter';
import {
  Header,
  Form,
  Button,
  Select,
  Input,
  Divider,
  Search
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { admin: boolean } &
    ReportsStore.ReportsState &
    typeof ReportsStore.actionCreators &
    WithRouterProps<{}>

class Roster extends React.PureComponent<ReportProps> {
    public params() {
      let params = new URLSearchParams()
      for (let [key, value] of Object.entries(this.props.reports.roster.data)) {
        if (value != undefined && value != null && value != '' && key != 'reportTypes' && key != 'classes') {
            if (typeof value == 'object') {
                for (let ovalue of Object.values(value as any)) {
                  params.append(key, ovalue as string)
                }
            } else {
                params.append(key, (value as any).toString())
            }
        }
      }
      return params.toString()
    }

    public render() {
      return (
        <Fragment>
          <Form>
            <Form.Field
              control={Select}
              label='Report type'
              placeholder='Report type'
              options={this.props.reports.roster.data.startDate && this.props.reports.roster.data.endDate ? [{key:0,value:'name',text:'Name'},{key:1,value:'facility',text:'Facility'}] : (this.props.admin ? [{key:0,value:'name',text:'Name'},{key:1,value:'facility',text:'Facility'},{key:2,value:'housing',text:'Housing'},{key:3,value:'class',text:'Class'},{key:4,value:'staff',text:'Staff'}] : [{key:0,value:'name',text:'Name'},{key:1,value:'facility',text:'Facility'},{key:2,value:'housing',text:'Housing'},{key:3,value:'class',text:'Class'}])}
              value={this.props.reports.roster.data.reportType}
              onChange={(e:any, data:any) => this.props.dataChange('roster', 'reportType', data.value)}
              width={4}
            />
          </Form>
          <Header as="h2">Roster Report &#8212; {_.find(this.props.reports[this.props.report].data.reportTypes, (type:any) => type.value == this.props.reports[this.props.report].data.reportType).text}</Header>
          {this.props.admin ? <Fragment>
            <div style={{border:'thin solid #ddd',padding:1,borderRadius:5,marginBottom:12}}>
              <Editor placeholder="Enter a report description..." editorState={this.props.reports.roster.description} sideButtons={[]} onChange={(state:any) => this.props.updateDescription(state)} />
            </div>
            <Button positive content='Save' onClick={this.props.saveDescription} />
          </Fragment> : null}
          {this.props.admin ? null : <div style={{whiteSpace:'normal'}} dangerouslySetInnerHTML={{__html: mediumDraftExporter(this.props.reports.roster.description.getCurrentContent())}} />}
          <Divider />
          <Form>
            <Form.Group>
              {this.props.reports.roster.data.reportType == 'name' || this.props.reports.roster.data.reportType == 'facility' ? <Form.Field
                control={DatePicker}
                label="Beginning date"
                name="startDate"
                placeholder="MM/DD/YYYY"
                selected={this.props.reports.roster.data.startDate ? new Date(this.props.reports.roster.data.startDate) : null}
                onChange={(date:any) => this.props.dataChange('roster', 'startDate', date ? format(date, 'MM/dd/yyyy') : null)}
                width={4}
              /> : null}
              {this.props.reports.roster.data.reportType == 'name' || this.props.reports.roster.data.reportType == 'facility' ? <Form.Field
                control={DatePicker}
                label="Ending date"
                name="endDate"
                placeholder="MM/DD/YYYY"
                selected={this.props.reports.roster.data.endDate ? new Date(this.props.reports.roster.data.endDate) : null}
                onChange={(date:any) => this.props.dataChange('roster', 'endDate', date ? format(date, 'MM/dd/yyyy') : null)}
                width={4}
              /> : null}
              <Form.Field
                control={Select}
                label='Display type'
                placeholder='Display type'
                options={[{key:0,value:'html',text:'HTML'},{key:1,value:'pdf',text:'PDF'}]}
                value={this.props.reports.roster.data.displayType}
                onChange={(e:any, data:any) => this.props.dataChange('roster', 'displayType', data.value)}
                width={4}
              />
              {/*this.props.reports.roster.data.displayType == 'pdfs' ? <Form.Field
                control={Select}
                label='Orientation'
                placeholder='Orientation'
                options={[{key:0,value:'landscape',text:'Landscape'},{key:1,value:'portrait',text:'Portrait'}]}
                value={this.props.reports.roster.data.orientation}
                onChange={(e:any, data:any) => this.props.dataChange('roster', 'orientation', data.value)}
                width={4}
              /> : null*/}
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Select}
                label={`${this.props.reports.roster.data.reportType == 'staff' ? 'Staff' : 'Inmate'} filter`}
                placeholder='Inmate filter'
                options={this.props.reports.roster.data.reportType == 'staff' ? [{key:0,value:'active',text:'Active'}] : [{key:0,value:'active',text:'Active'},{key:1,value:'recent',text:'Recent Arrivals'},{key:2,value:'younger',text:'Younger than XX'},{key:3,value:'verified',text:'Verified Diploma/HSE'},{key:4,value:'unverified',text:'Unverified Diploma/HSE'},{key:5,value:'obtained',text:'Obtained Diploma/HSE'},{key:6,value:'previous',text:'Previous GED/HiSET'},{key:7,value:'contract',text:'No Signed Ed. Contract'}]}
                value={this.props.reports.roster.data.filterBy}
                onChange={(e:any, data:any) => this.props.dataChange('roster', 'filterBy', data.value)}
                width={3}
              />
              {this.props.reports.roster.data.reportType != 'staff' ? <Form.Field
                control={Select}
                label={`TABE filter`}
                placeholder='TABE filter'
                options={[{key:0,value:'hours',text:'Accumulated hours'},{key:1,value:'none',text:'No TABE'},{key:2,value:'year',text:'Over 1 year since last TABE'}]}
                value={this.props.reports.roster.data.tabeFilters}
                onChange={(e:any, data:any) => this.props.dataChange('roster', 'tabeFilters', data.value)}
                width={4}
                multiple
              /> : null}
              {this.props.reports.roster.data.filterBy == 'contract' ? <Form.Field
                control={Select}
                label={`In classes`}
                placeholder='In classes'
                options={[{key:0,value:true,text:'Yes'},{key:1,value:false,text:'No'}]}
                value={this.props.reports.roster.data.inClasses}
                onChange={(e:any, data:any) => this.props.dataChange('roster', 'inClasses', data.value)}
                width={4}
              /> : null}
              {this.props.reports.roster.data.filterBy == 'recent' ? <Form.Field
                control={Input}
                label='Months old'
                placeholder='Months old'
                value={this.props.reports.roster.data.months}
                onChange={(e:any, data:any) => this.props.dataChange('roster', 'months', data.value.validate())}
                width={2}
              /> : null}
              {this.props.reports.roster.data.filterBy == 'younger' ? <Form.Field
                control={Input}
                label='Inmate age'
                placeholder='Inmate age'
                value={this.props.reports.roster.data.age}
                onChange={(e:any, data:any) => this.props.dataChange('roster', 'age', data.value.validate())}
                width={2}
              /> : null}
              {this.props.reports.roster.data.filterBy == 'younger' ? <Form.Field
                control={Select}
                label='Inmate status'
                placeholder='Inmate status'
                options={[{key:0,value:'all',text:'All'},{key:1,value:'enrolled',text:'Enrolled'}]}
                value={this.props.reports.roster.data.status}
                onChange={(e:any, data:any) => this.props.dataChange('roster', 'status', data.value)}
                width={3}
              /> : null}
              {this.props.reports.roster.data.filterBy == 'obtained' ? <Form.Field
                control={Select}
                label='Location'
                placeholder='Location'
                options={[{key:0,value:'any',text:'Any'},{key:1,value:'facility',text:'While at Facility'}]}
                value={this.props.reports.roster.data.location}
                onChange={(e:any, data:any) => this.props.dataChange('roster', 'location', data.value)}
                width={3}
              /> : null}
              {this.props.reports.roster.data.filterBy == 'verified' || this.props.reports.roster.data.filterBy == 'obtained' ? <Form.Field
                control={Select}
                label='Grad type'
                placeholder='Grad type'
                options={[{key:0,value:'any',text:'Any'},{key:1,value:'D',text:'Diploma'},{key:2,value:'G',text:'GED'},{key:3,value:'H',text:'HiSET'},{key:4,value:'C',text:'CBCSDP'}]}
                value={this.props.reports.roster.data.gradTypes}
                onChange={(e:any, data:any) => this.props.dataChange('roster', 'gradTypes', data.value)}
                width={3}
                multiple
              /> : null}
              {this.props.reports.roster.data.filterBy == 'previous' ? <Form.Field
                control={Select}
                label='Test type'
                placeholder='Test type'
                options={[{key:0,value:'B',text:'Both'},{key:1,value:'G',text:'GED'},{key:2,value:'H',text:'HiSET'}]}
                value={this.props.reports.roster.data.testType}
                onChange={(e:any, data:any) => this.props.dataChange('roster', 'testType', data.value)}
                width={3}
              /> : null}
              {this.props.reports.roster.data.reportType == 'staff' ? <Form.Field
                control={Select}
                label={`Login Enabled`}
                placeholder='Login Enabled'
                options={[{key:0,value:'all',text:'All'},{key:1,value:'yes',text:'Yes'},{key:2,value:'no',text:'No'}]}
                value={this.props.reports.roster.data.login}
                onChange={(e:any, data:any) => this.props.dataChange('roster', 'login', data.value)}
                width={3}
              /> : null}
              <Form.Field
                control={Select}
                label='Facilities'
                placeholder='Facilities'
                options={[{key:0,value:0,text:'All facilities'}].concat(this.props.reports.roster.facilities)}
                value={this.props.reports.roster.data.facilities}
                onChange={(e:any, data:any) => this.props.dataChange('roster', 'facilities', data.value)}
                multiple
                width={10}
              />
            </Form.Group>
            {this.props.reports.roster.data.reportType == 'staff' ? <Form.Group>
              <Form.Field
                control={Select}
                label='Positions'
                placeholder='Positions'
                options={[{key:0,value:0,text:'All positions'}].concat(this.props.reports.roster.positions)}
                value={this.props.reports.roster.data.positions}
                onChange={(e:any, data:any) => this.props.dataChange('roster', 'positions', data.value)}
                multiple
                width={8}
              />
              <Form.Field
                control={Select}
                label='Access Levels'
                placeholder='Access Levels'
                options={[{key:'all',value:'all',text:'All levels'}].concat(this.props.reports.roster.levels)}
                value={this.props.reports.roster.data.levels}
                onChange={(e:any, data:any) => this.props.dataChange('roster', 'levels', data.value)}
                multiple
                width={8}
              />
            </Form.Group> : null}
            {this.props.reports.roster.data.reportType != 'staff' ? <Form.Group>
              <Form.Field
                control={Select}
                label='Include school info'
                options={[{key:0,value:false,text:'No'},{key:1,value:true,text:'Yes'}]}
                value={this.props.reports.roster.data.showSchools}
                onChange={(e:any, data:any) => this.props.dataChange('roster', 'showSchools', data.value)}
                width={3}
              />
              <Form.Field
                control={Select}
                label={`Search filter`}
                placeholder='Search filter'
                options={[{key:0,value:'not',text:'Not Taken'},{key:1,value:'taken',text:'Taken'},{key:2,value:'failed',text:'Not Completed'},{key:3,value:'passed',text:'Completed'}]}
                value={this.props.reports.roster.data.searchFilter}
                onChange={(e:any, data:any) => this.props.dataChange('roster', 'searchFilter', data.value)}
                width={3}
              />
              <Form.Field
                control={Search}
                label='Search Classes'
                loading={this.props.reports.roster.data.classesLoading}
                onResultSelect={(e:any, data:any) => { this.props.selectRosterClass('roster', data.result) }}
                onSearchChange={(e:any, data:any) => { this.props.searchRosterClasses('roster', data.value as string) }}
                results={this.props.reports.roster.data.classes}
                value={this.props.reports.roster.data.class}
                onFocus={() => {}}
                width={8}
                fluid
              />
            </Form.Group> : null}
            {false ? <Form.Group>
              <Form.Field
                control={Select}
                label='Include administrative deletes'
                options={[{key:0,value:false,text:'No'},{key:1,value:true,text:'Yes'}]}
                value={this.props.reports.roster.data.adminDeletes}
                onChange={(e:any, data:any) => this.props.dataChange('roster', 'adminDeletes', data.value)}
                width={4}
              />
            </Form.Group> : null}
            {this.props.reports.roster.data.displayType == 'html' ? <Button color='blue' content='Generate report' as={Link} to={`/reports/roster/html?${this.params()}`} target='_window' /> : null}
            {this.props.reports.roster.data.displayType != 'html' ? <Button color='blue' content='Generate report' onClick={this.props.generateRoster} /> : null}
          </Form>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { ...state.reports, admin: state.staff.staff!.admin } },
  ReportsStore.actionCreators
)(Roster as any)
