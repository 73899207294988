import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { renderToString } from 'react-dom/server'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import { pdfshift } from '../../../agent'
import * as ReportsStore from '../../../store/reducers/reports'
import RecidivismInmateCounts from '../shift/RecidivismInmateCounts'
import RecidivismGlobal from '../shift/RecidivismGlobal'
import RecidivismGlobalFacilities from '../shift/RecidivismGlobalFacilities'
import RecidivismStudentsVsInmates from '../shift/RecidivismStudentsVsInmates'
import RecidivismStudentBreakdowns from '../shift/RecidivismStudentBreakdowns'
import RecidivismFacilityStudentBreakdowns from '../shift/RecidivismFacilityStudentBreakdowns'
import RecidivismBaselineStudents from '../shift/RecidivismBaselineStudents'
import RecidivismBaseline from '../shift/RecidivismBaseline'
import Population from '../shift/Population'
import Footer from '../shift/ReportFooter'
import {
  Icon
} from 'semantic-ui-react'
const _ = require('underscore')

const css = require("!!css-loader!../../../custom.css");

type ReportProps =
    { report: any, options: any, settings: any } &
    typeof ReportsStore.actionCreators &
    WithRouterProps<{}>

class RecidivismPDF extends React.PureComponent<ReportProps> {
    public componentDidUpdate(prevProps:any) {
      if (!prevProps.report.ready && this.props.report.ready) {
        const footer = renderToString(<Footer report={this.props.report} options={this.props.options} settings={this.props.settings} />);
        if (this.props.report.data.reportType == 'population') {
          const population = renderToString(<Population report={this.props.report} options={this.props.options} settings={this.props.settings} />);
          pdfshift({
              source: population,
              footer: { source: footer, height: 70 },
              margin: { top: 30, bottom: 0, left: 30, right: 30 },
              landscape: true,
              css: css.default.toString(),
              format: 'Letter',
              wait_for: 'ready',
              filename: 'population.pdf'
          }).then((response:any) => {
            this.props.setURLs('recidivism', 'Population PDF', response.data.url)
          })
        } else if (this.props.report.data.reportType == 'baseline') {
          const baseline = renderToString(<RecidivismBaseline report={this.props.report} options={this.props.options} settings={this.props.settings} />);
          const baselineStudents = renderToString(<RecidivismBaselineStudents report={this.props.report} options={this.props.options} settings={this.props.settings} />);
          pdfshift({
              source: baseline,
              footer: { source: footer, height: 70 },
              margin: { top: 30, bottom: 0, left: 30, right: 30 },
              landscape: true,
              css: css.default.toString(),
              format: 'Letter',
              wait_for: 'ready',
              filename: 'recidivism-baseline.pdf'
          }).then((response:any) => {
            this.props.setURLs('recidivism', 'Recidivism PDF', response.data.url)
            pdfshift({
                source: baselineStudents,
                footer: { source: footer, height: 70 },
                margin: { top: 30, bottom: 0, left: 30, right: 30 },
                landscape: true,
                css: css.default.toString(),
                format: 'Letter',
                wait_for: 'ready',
                filename: 'recidivism-students.pdf'
            }).then((response:any) => {
              this.props.setURLs('recidivism', 'Recidivism Students PDF', response.data.url)
            })
          })
        } else {
          const inmateCounts = renderToString(<RecidivismInmateCounts report={this.props.report} options={this.props.options} settings={this.props.settings} />);
          const global = renderToString(<RecidivismGlobal report={this.props.report} options={this.props.options} settings={this.props.settings} />);
          const globalFacilities = renderToString(<RecidivismGlobalFacilities report={this.props.report} options={this.props.options} settings={this.props.settings} />);
          const studentsVsInmates = renderToString(<RecidivismStudentsVsInmates report={this.props.report} options={this.props.options} settings={this.props.settings} />);
          const studentBreakdowns = renderToString(<RecidivismStudentBreakdowns report={this.props.report} options={this.props.options} settings={this.props.settings} />);
          const facilityStudentBreakdowns = renderToString(<RecidivismFacilityStudentBreakdowns report={this.props.report} options={this.props.options} settings={this.props.settings} />);

          pdfshift({
              source: inmateCounts,
              footer: { source: footer, height: 70 },
              margin: { top: 30, bottom: 0, left: 30, right: 30 },
              landscape: true,
              css: css.default.toString(),
              format: 'Letter',
              wait_for: 'ready',
              filename: 'recidivism-inmate-counts.pdf'
          }).then((response:any) => {
            this.props.setURLs('recidivism', 'Inmate Counts PDF', response.data.url)
            pdfshift({
                source: global,
                footer: { source: footer, height: 70 },
                margin: { top: 30, bottom: 0, left: 30, right: 30 },
                landscape: true,
                css: css.default.toString(),
                format: 'Letter',
                wait_for: 'ready',
                filename: 'recidivism-global.pdf'
            }).then((response:any) => {
              this.props.setURLs('recidivism', 'Global PDF', response.data.url)
              pdfshift({
                  source: globalFacilities,
                  footer: { source: footer, height: 70 },
                  margin: { top: 30, bottom: 0, left: 30, right: 30 },
                  landscape: true,
                  css: css.default.toString(),
                  format: 'Letter',
                  wait_for: 'ready',
                  filename: 'recidivism-global-facilities.pdf'
              }).then((response:any) => {
                this.props.setURLs('recidivism', 'Global Facilities PDF', response.data.url)
                pdfshift({
                    source: studentsVsInmates,
                    footer: { source: footer, height: 70 },
                    margin: { top: 30, bottom: 0, left: 30, right: 30 },
                    landscape: true,
                    css: css.default.toString(),
                    format: 'Letter',
                    wait_for: 'ready',
                    filename: 'recidivism-students-vs-inmate.pdf'
                }).then((response:any) => {
                  this.props.setURLs('recidivism', 'Students vs. Inmates PDF', response.data.url)
                  pdfshift({
                      source: studentBreakdowns,
                      footer: { source: footer, height: 70 },
                      margin: { top: 30, bottom: 0, left: 30, right: 30 },
                      landscape: true,
                      css: css.default.toString(),
                      format: 'Letter',
                      wait_for: 'ready',
                      filename: 'recidivism-student-breakdowns.pdf'
                  }).then((response:any) => {
                    this.props.setURLs('recidivism', 'Student Breakdowns PDF', response.data.url)
                    pdfshift({
                        source: facilityStudentBreakdowns,
                        footer: { source: footer, height: 70 },
                        margin: { top: 30, bottom: 0, left: 30, right: 30 },
                        landscape: true,
                        css: css.default.toString(),
                        format: 'Letter',
                        wait_for: 'ready',
                        filename: 'recidivism-facility-student-breakdowns.pdf'
                    }).then((response:any) => {
                      this.props.setURLs('recidivism', 'Facility Student Breakdowns PDF', response.data.url)
                    })
                  })
                })
              })
            })
          })
        }
      }
    }

    public render() {
        return (
          <Fragment>
            {this.props.report.loading || (this.props.report.ready && this.props.report.urls.length == 0) ? <div className='pdf generating'>
              <Icon.Group>
                <Icon name='circle notch' color='grey' size='huge' loading  />
                <Icon name='file pdf outline' color='grey' size='big' />
              </Icon.Group>
              <div className='name'><b>Generating PDFs...</b></div>
            </div> : null}
            {this.props.report.ready && this.props.report.urls.length > 0 ? _.map(this.props.report.urls, (pdf:any, i:number) => <a key={i} target='_window' href={pdf.url}>
              <div className='pdf ready'>
                <Icon name='file pdf outline' size='huge' />
                <div className='name'><b>{pdf.title}</b></div>
              </div>
            </a>) : null}
          </Fragment>
        )
    }
}

export default connect(
  (state: ApplicationState) => { return { report: state.reports.reports.recidivism, options: state.reports.options, settings: state.staff.settings } },
  ReportsStore.actionCreators
)(RecidivismPDF as any)