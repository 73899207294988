import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import ClassRosterAdd from './ClassRosterAdd'
import { format } from 'date-fns'
import {
  Container,
  Table,
  Checkbox,
  Radio,
  Popup,
  Label,
  Icon,
  Button,
  Header
} from 'semantic-ui-react'
const _ = require('underscore')

type RosterProps =
    { class: PanesStore.Class, num: 1 | 2, print: boolean, mode: string } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassRosterInmates extends React.PureComponent<RosterProps> {
    public componentDidMount() {
        this.props.fetchRosters(this.props.num, this.props.class.info.recordID)
        this.props.fetchClassWaitlist(this.props.num, this.props.class.info.recordID)
        this.props.fetchClassApprovals(this.props.num, this.props.class.info.recordID)
    }

    private dayOfWeek3 = (day:number) => {
      switch(day) {
        case 0: {
          return 'Sun'
        }
        case 1: {
          return 'Mon'
        }
        case 2: {
          return 'Tue'
        }
        case 3: {
          return 'Wed'
        }
        case 4: {
          return 'Thu'
        }
        case 5: {
          return 'Fri'
        }
        case 6: {
          return 'Sat'
        }
        default: {
          return ''
        }
      }
    }

    public render() {
        const rosters = this.props.class.rosters == undefined ? [] : this.props.class.rosters
        const waitlist = this.props.class.waitlists == undefined ? [] : this.props.class.waitlists
        const approvals = this.props.class.approvals == undefined ? [] : this.props.class.approvals
        var rows:any = []
        var num = -1;

        rosters.map((inmate:any, i:number) => {
          num += 1;
          var row:any = []
          row.push(inmate.sid)
          row.push(`"${inmate.lastName}, ${inmate.firstName}"`)
          rows[num] = row
        })

        var blob = new Blob([rows.map((e:any) => e.join(",")).join("\n")], { type: 'text/csv;charset=utf-8;' });
        var url = URL.createObjectURL(blob);

        return (
          <Container fluid>
            <div style={{float:'right',marginTop:5}}>
              <Radio label="Hours" checked={this.props.class.attnType == "hours"} onChange={() => this.props.toggleAttnType(this.props.num)} />
              <Radio label="Sessions" checked={this.props.class.attnType == "sessions"} onChange={() => this.props.toggleAttnType(this.props.num)} style={{marginLeft:15,marginRight:10}} />
            </div>
            <Header as='h3' style={{display:'inline-block',marginTop:0}}>Students</Header>
            {this.props.print ? null : <Popup content={this.props.class.roster.active.includes('add') ? `Close search fields` : `Toggle to open search fields to add students to the roster.`} position="top left" trigger={<Button positive circular icon='plus' onClick={() => this.props.toggleTab(this.props.num, 'add')} style={{marginLeft:10}} />} />}
            {this.props.print ? null : <Popup position="right center" content="Download roster csv" trigger={<a download={`${this.props.class.info.recordID}_Roster_${format(new Date(), 'yyyyMMdd')}.csv`} href={url}><Button color='blue' circular icon='file alternate outline' style={{marginLeft:10}} /></a>} />}
            {this.props.class.roster.active.includes('add') ? <ClassRosterAdd num={this.props.num} class={this.props.class} /> : null}
            <Table compact celled striped style={{marginBottom:20}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan={9} style={{padding:'5px 7px',background:'#fffaf3'}}><b>Roster</b></Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                  <Table.HeaderCell width={1}><Checkbox checked={this.props.class.bulk.rosters ? this.props.class.bulk.rosters.length == rosters.length : false} onClick={() => this.props.toggleBulkRosters(this.props.num)} /></Table.HeaderCell>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>SID</Table.HeaderCell>
                  <Table.HeaderCell>Housing</Table.HeaderCell>
                  <Table.HeaderCell>Schedule</Table.HeaderCell>
                  <Table.HeaderCell>Start Date</Table.HeaderCell>
                  <Popup position='top center' content='Attended | Excused | Unexcused | Percentage of Classes Attended' trigger={<Table.HeaderCell>Attendance</Table.HeaderCell>} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.filter(rosters, (roster:any) => !roster.suspended && !roster.quarantined).map((roster:any, i:number) => (
                  <Table.Row error={roster.transferred || roster.suspended || roster.quarantined || roster.lowAttendance || (roster.schedules ? roster.schedules.length == 0 : false)} key={roster.recordID}>
                    <Table.Cell>{i+1}</Table.Cell>
                    <Table.Cell><Checkbox checked={this.props.class.bulk.rosters ? this.props.class.bulk.rosters.includes(roster.recordID) : false} onClick={() => this.props.toggleBulkRoster(this.props.num, roster.recordID)} /></Table.Cell>
                    <Table.Cell>
                      <Fragment>
                        {this.props.class.info.requiredHours && roster.aTime >= this.props.class.info.requiredHours ? <Popup content="Inmate has met required hours" position="top center" trigger={<Icon name="calendar check" color="green" />} /> : null}
                        {roster.status == 'G' ? <Popup content="Inmate is in pretrial" position="top center" trigger={<Icon name="gavel" />} /> : null}
                        {roster.ta ? <Popup content="Inmate is a TA" position="top center" trigger={<Icon name="graduation" color="blue" />} /> : null}
                        {roster.additional ? <Popup position="top center" trigger={<Icon name="calendar plus" color="blue" />}>
                          {roster.scheduled.length > 0 ? <>
                            <div style={{fontWeight:'bold'}}>Scheduled:</div>
                            {_.map(roster.scheduled, (schedule:any) => { return <div key={schedule.recordID} style={{whiteSpace:'nowrap'}}>{schedule.meetingDate} / {this.dayOfWeek3(schedule.dayOfWeek)}. {schedule.startTime} - {schedule.endTime}</div> })}
                          </> : null}
                          {roster.scheduled.length > 0 ? <>
                            <div style={{fontWeight:'bold',color:'#DB2828',marginTop:20}}>Removed:</div>
                            {_.map(roster.unscheduled, (schedule:any) => { return <div key={schedule.recordID} style={{whiteSpace:'nowrap'}}>{schedule.meetingDate} / {this.dayOfWeek3(schedule.dayOfWeek)}. {schedule.startTime} - {schedule.endTime}</div> })}
                          </> : null}
                        </Popup> : null}
                        {roster.transferred ? <Popup content="Inmate has been transferred to another facility" position="top center" trigger={<Icon name="exclamation" color="red" />} /> : null}
                        {roster.lowAttendance ? <Popup content="Inmate has low attendance" position="top center" trigger={<Icon name="calendar minus" color="red" />} /> : null}
                        {roster.schedules && roster.schedules.length == 0 ? <Popup content="Inmate has no schedules assigned" position="top center" trigger={<Icon name="calendar times" color="red" />} /> : null}
                        <div className='link' onClick={() => { (document.getElementById(`pane-${this.props.num}-content`) as any).scrollTop = 0; this.props.editRoster(this.props.num, roster) }} style={{whiteSpace:'normal',display:'inline-block'}}>{`${roster.lastName}, ${roster.firstName}`}</div>
                      </Fragment>
                    </Table.Cell>
                    <Table.Cell>{roster.sid}</Table.Cell>
                    <Table.Cell>{roster.housing}</Table.Cell>
                    <Table.Cell>{roster.suspended ? 'Suspended' : roster.quarantined ? 'Quarantined' : _.map(roster.schedules, (schedule:any, s:number) => { return <div key={s} style={{whiteSpace:'nowrap'}}><span style={{display:'inline-block',width:32}}>{this.dayOfWeek3(schedule.dayOfWeek)}.</span> {schedule.startTime} - {schedule.endTime}</div> })}</Table.Cell>
                    <Table.Cell>{roster.dateStart}</Table.Cell>
                    {this.props.class.attnType == 'hours' ? <Table.Cell>
                      {roster.aTime} | {roster.xTime} | {roster.zTime} | {roster.percentAttended}
                      {roster.accATime > 0 ? <Popup content={`Inmate has acculumated ${roster.accATime} hours in other classes`} position="left center" trigger={<Icon color="blue" name="calendar plus" style={{marginLeft:5}} />} /> : null}
                    </Table.Cell> : <Table.Cell>
                      {roster.aCount} | {roster.xCount} | {roster.zCount} | {roster.percentAttended}
                      {roster.accATime > 0 ? <Popup content={`Inmate has acculumated ${roster.accACount} sessions in other classes`} position="left center" trigger={<Icon color="blue" name="calendar plus" style={{marginLeft:5}} />} /> : null}
                    </Table.Cell>}
                  </Table.Row>
                ))}
                {_.filter(rosters, (roster:any) => roster.suspended && !roster.quarantined).length > 0 ? <Table.Row style={{background:'#fffaf3'}}>
                  <Table.Cell colSpan={9} style={{ fontWeight: 700, padding: '.5em' }}>Suspended</Table.Cell>
                </Table.Row> : null}
                {_.filter(rosters, (roster:any) => roster.suspended && !roster.quarantined).length > 0 ? _.filter(rosters, (roster:any) => roster.suspended && !roster.quarantined).map((roster:any, i:number) => (
                  <Table.Row error={roster.transferred || roster.lowAttendance || (roster.schedules ? roster.schedules.length == 0 : false)} key={roster.recordID}>
                    <Table.Cell>{i+1+_.filter(rosters, (roster:any) => !roster.suspended && !roster.quarantined).length}</Table.Cell>
                    <Table.Cell><Checkbox checked={this.props.class.bulk.rosters ? this.props.class.bulk.rosters.includes(roster.recordID) : false} onClick={() => this.props.toggleBulkRoster(this.props.num, roster.recordID)} /></Table.Cell>
                    <Table.Cell>
                      <Fragment>
                        {this.props.class.info.requiredHours && roster.aTime >= this.props.class.info.requiredHours ? <Popup content="Inmate has met required hours" position="top center" trigger={<Icon name="calendar check" color="green" />} /> : null}
                        {roster.status == 'G' ? <Popup content="Inmate is in pretrial" position="top center" trigger={<Icon name="gavel" />} /> : null}
                        {roster.ta ? <Popup content="Inmate is a TA" position="top center" trigger={<Icon name="graduation" color="blue" />} /> : null}
                        {roster.additional ? <Popup position="top center" trigger={<Icon name="calendar plus" color="blue" />}>
                          {roster.scheduled.length > 0 ? <>
                            <div style={{fontWeight:'bold'}}>Scheduled:</div>
                            {_.map(roster.scheduled, (schedule:any) => { return <div key={schedule.recordID} style={{whiteSpace:'nowrap'}}>{schedule.meetingDate} / {this.dayOfWeek3(schedule.dayOfWeek)}. {schedule.startTime} - {schedule.endTime}</div> })}
                          </> : null}
                          {roster.scheduled.length > 0 ? <>
                            <div style={{fontWeight:'bold',color:'#DB2828',marginTop:20}}>Removed:</div>
                            {_.map(roster.unscheduled, (schedule:any) => { return <div key={schedule.recordID} style={{whiteSpace:'nowrap'}}>{schedule.meetingDate} / {this.dayOfWeek3(schedule.dayOfWeek)}. {schedule.startTime} - {schedule.endTime}</div> })}
                          </> : null}
                        </Popup> : null}
                        {roster.transferred ? <Popup content="Inmate has been transferred to another facility" position="top center" trigger={<Icon name="exclamation" color="red" />} /> : null}
                        {roster.lowAttendance ? <Popup content="Inmate has low attendance" position="top center" trigger={<Icon name="calendar minus" color="red" />} /> : null}
                        {roster.schedules && roster.schedules.length == 0 ? <Popup content="Inmate has no schedules assigned" position="top center" trigger={<Icon name="calendar times" color="red" />} /> : null}
                        <div className='link' onClick={() => { (document.getElementById(`pane-${this.props.num}-content`) as any).scrollTop = 0; this.props.editRoster(this.props.num, roster) }} style={{whiteSpace:'normal',display:'inline-block'}}>{`${roster.lastName}, ${roster.firstName}`}</div>
                      </Fragment>
                    </Table.Cell>
                    <Table.Cell>{roster.sid}</Table.Cell>
                    <Table.Cell>{roster.housing}</Table.Cell>
                    <Table.Cell>{roster.suspended ? 'Suspended' : roster.quarantined ? 'Quarantined' : _.map(roster.schedules, (schedule:any, s:number) => { return <div key={s} style={{whiteSpace:'nowrap'}}><span style={{display:'inline-block',width:32}}>{this.dayOfWeek3(schedule.dayOfWeek)}.</span> {schedule.startTime} - {schedule.endTime}</div> })}</Table.Cell>
                    <Table.Cell>{roster.dateStart}</Table.Cell>
                    {this.props.class.attnType == 'hours' ? <Table.Cell>
                      {roster.aTime} | {roster.xTime} | {roster.zTime} | {roster.percentAttended}
                      {roster.accATime > 0 ? <Popup content={`Inmate has acculumated ${roster.accATime} hours in other classes`} position="left center" trigger={<Icon color="blue" name="calendar plus" style={{marginLeft:5}} />} /> : null}
                    </Table.Cell> : <Table.Cell>
                      {roster.aCount} | {roster.xCount} | {roster.zCount} | {roster.percentAttended}
                      {roster.accATime > 0 ? <Popup content={`Inmate has acculumated ${roster.accACount} sessions in other classes`} position="left center" trigger={<Icon color="blue" name="calendar plus" style={{marginLeft:5}} />} /> : null}
                    </Table.Cell>}
                  </Table.Row>
                )) : null}
                {_.filter(rosters, (roster:any) => roster.quarantined).length > 0 ? <Table.Row style={{background:'#fffaf3'}}>
                  <Table.Cell colSpan={9} style={{ fontWeight: 700, padding: '.5em' }}>Quarantined</Table.Cell>
                </Table.Row> : null}
                {_.filter(rosters, (roster:any) => roster.quarantined).length > 0 ? _.filter(rosters, (roster:any) => roster.quarantined).map((roster:any, i:number) => (
                  <Table.Row error={roster.transferred || roster.lowAttendance || (roster.schedules ? roster.schedules.length == 0 : false)} key={roster.recordID}>
                    <Table.Cell>{i+1+_.filter(rosters, (roster:any) => (!roster.suspended && !roster.quarantined) || (roster.suspended && !roster.quarantined)).length}</Table.Cell>
                    <Table.Cell><Checkbox checked={this.props.class.bulk.rosters ? this.props.class.bulk.rosters.includes(roster.recordID) : false} onClick={() => this.props.toggleBulkRoster(this.props.num, roster.recordID)} /></Table.Cell>
                    <Table.Cell>
                      <Fragment>
                        {this.props.class.info.requiredHours && roster.aTime >= this.props.class.info.requiredHours ? <Popup content="Inmate has met required hours" position="top center" trigger={<Icon name="calendar check" color="green" />} /> : null}
                        {roster.status == 'G' ? <Popup content="Inmate is in pretrial" position="top center" trigger={<Icon name="gavel" />} /> : null}
                        {roster.ta ? <Popup content="Inmate is a TA" position="top center" trigger={<Icon name="graduation" color="blue" />} /> : null}
                        {roster.additional ? <Popup position="top center" trigger={<Icon name="calendar plus" color="blue" />}>
                          {roster.scheduled.length > 0 ? <>
                            <div style={{fontWeight:'bold'}}>Scheduled:</div>
                            {_.map(roster.scheduled, (schedule:any) => { return <div key={schedule.recordID} style={{whiteSpace:'nowrap'}}>{schedule.meetingDate} / {this.dayOfWeek3(schedule.dayOfWeek)}. {schedule.startTime} - {schedule.endTime}</div> })}
                          </> : null}
                          {roster.scheduled.length > 0 ? <>
                            <div style={{fontWeight:'bold',color:'#DB2828',marginTop:20}}>Removed:</div>
                            {_.map(roster.unscheduled, (schedule:any) => { return <div key={schedule.recordID} style={{whiteSpace:'nowrap'}}>{schedule.meetingDate} / {this.dayOfWeek3(schedule.dayOfWeek)}. {schedule.startTime} - {schedule.endTime}</div> })}
                          </> : null}
                        </Popup> : null}
                        {roster.transferred ? <Popup content="Inmate has been transferred to another facility" position="top center" trigger={<Icon name="exclamation" color="red" />} /> : null}
                        {roster.suspended ? <Popup content="Inmate has been suspended" position="top center" trigger={<Icon name="dont" color="red" />} /> : null}
                        {roster.lowAttendance ? <Popup content="Inmate has low attendance" position="top center" trigger={<Icon name="calendar minus" color="red" />} /> : null}
                        {roster.schedules && roster.schedules.length == 0 ? <Popup content="Inmate has no schedules assigned" position="top center" trigger={<Icon name="calendar times" color="red" />} /> : null}
                        <div className='link' onClick={() => { (document.getElementById(`pane-${this.props.num}-content`) as any).scrollTop = 0; this.props.editRoster(this.props.num, roster) }} style={{whiteSpace:'normal',display:'inline-block'}}>{`${roster.lastName}, ${roster.firstName}`}</div>
                      </Fragment>
                    </Table.Cell>
                    <Table.Cell>{roster.sid}</Table.Cell>
                    <Table.Cell>{roster.housing}</Table.Cell>
                    <Table.Cell>{roster.suspended ? 'Suspended' : roster.quarantined ? 'Quarantined' : _.map(roster.schedules, (schedule:any, s:number) => { return <div key={s} style={{whiteSpace:'nowrap'}}><span style={{display:'inline-block',width:32}}>{this.dayOfWeek3(schedule.dayOfWeek)}.</span> {schedule.startTime} - {schedule.endTime}</div> })}</Table.Cell>
                    <Table.Cell>{roster.dateStart}</Table.Cell>
                    {this.props.class.attnType == 'hours' ? <Table.Cell>
                      {roster.aTime} | {roster.xTime} | {roster.zTime} | {roster.percentAttended}
                      {roster.accATime > 0 ? <Popup content={`Inmate has acculumated ${roster.accATime} hours in other classes`} position="left center" trigger={<Icon color="blue" name="calendar plus" style={{marginLeft:5}} />} /> : null}
                    </Table.Cell> : <Table.Cell>
                      {roster.aCount} | {roster.xCount} | {roster.zCount} | {roster.percentAttended}
                      {roster.accATime > 0 ? <Popup content={`Inmate has acculumated ${roster.accACount} sessions in other classes`} position="left center" trigger={<Icon color="blue" name="calendar plus" style={{marginLeft:5}} />} /> : null}
                    </Table.Cell>}
                  </Table.Row>
                )) : null}
              </Table.Body>
            </Table>
            {waitlist.length > 0 ? <Table compact celled striped style={{marginBottom:20}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan={6} style={{padding:'5px 7px',background:'#fffaf3'}}><b>Waitlist</b></Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>SID</Table.HeaderCell>
                  <Table.HeaderCell>Housing</Table.HeaderCell>
                  <Table.HeaderCell>Date Added</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {waitlist.map((inmate:any, i:number) => (
                  <Table.Row key={inmate.recordID}>
                    <Table.Cell>{i+1}</Table.Cell>
                    <Table.Cell>
                      {this.props.mode == 'dual' ? <Popup
                        hideOnScroll
                        on='click'
                        trigger={<div className='link' style={{whiteSpace:'normal'}}>{`${inmate.lastName}, ${inmate.firstName}`}</div>}
                      >
                        <Popup.Header>Open in pane</Popup.Header>
                        <Popup.Content>
                          <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(1, inmate.inmateID) }}>1</Label>
                          <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(2, inmate.inmateID) }}>2</Label>
                        </Popup.Content>
                      </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, inmate.inmateID) }}>{`${inmate.lastName}, ${inmate.firstName}`}</div>}
                    </Table.Cell>
                    <Table.Cell>{inmate.sid}</Table.Cell>
                    <Table.Cell>{inmate.housing}</Table.Cell>
                    <Table.Cell>{inmate.dateAdded}</Table.Cell>
                    <Table.Cell style={{textAlign:'center'}}><Button positive size='mini' content='Move to roster' onClick={_.debounce(() => this.props.convertClassWaitlist(this.props.num, inmate.recordID), 10000, true)} /><Button negative size='mini' content='Remove' onClick={_.debounce(() => this.props.removeClassWaitlist(this.props.num, inmate.recordID), 10000, true)} /></Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table> : null}
            {approvals.length > 0 ? <Table compact celled striped style={{marginBottom:20}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan={6} style={{padding:'5px 7px',background:'#fffaf3'}}><b>Approvals</b></Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>SID</Table.HeaderCell>
                  <Table.HeaderCell>Housing</Table.HeaderCell>
                  <Table.HeaderCell>Date Requested</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {approvals.map((inmate:any, i:number) => (
                  <Table.Row key={inmate.recordID}>
                    <Table.Cell>{i+1}</Table.Cell>
                    <Table.Cell>
                      {this.props.mode == 'dual' ? <Popup
                        hideOnScroll
                        on='click'
                        trigger={<div className='link' style={{whiteSpace:'normal'}}>{`${inmate.lastName}, ${inmate.firstName}`}</div>}
                      >
                        <Popup.Header>Open in pane</Popup.Header>
                        <Popup.Content>
                          <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(1, inmate.inmateID) }}>1</Label>
                          <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(2, inmate.inmateID) }}>2</Label>
                        </Popup.Content>
                      </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, inmate.inmateID) }}>{`${inmate.lastName}, ${inmate.firstName}`}</div>}
                    </Table.Cell>
                    <Table.Cell>{inmate.sid}</Table.Cell>
                    <Table.Cell>{inmate.housing}</Table.Cell>
                    <Table.Cell>{inmate.dateAdded}</Table.Cell>
                    <Table.Cell style={{textAlign:'center'}}><Button positive size='mini' content='Approve' onClick={_.debounce(() => this.props.approveClassApproval(this.props.num, inmate.recordID), 10000, true)} /><Button negative size='mini' content='Deny' onClick={_.debounce(() => this.props.denyClassApproval(this.props.num, inmate.recordID), 10000, true)} /></Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table> : null}
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, print: ownProps.print, mode: state.panes.mode } },
  PanesStore.actionCreators
)(ClassRosterInmates as any)