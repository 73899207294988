import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import InmateInfo from './InmateInfo'
import InmateClasses from './InmateClasses'
import InmateEducation from './InmateEducation'
import InmateEmployment from './InmateEmployment'
import InmateCommunity from './InmateCommunity'
import InmateHealth from './InmateHealth'
import InmateMilitary from './InmateMilitary'
import InmateTests from './InmateTests'
import InmateForms from './InmateForms'
import InmateDocuments from './InmateDocuments'
import InmateChangelog from './InmateChangelog'
import InmateReview from './InmateReview'
import InmateScreenings from './InmateScreenings'
import InmateANDs from './InmateANDs'
import InmatePhaseEntries from './InmatePhaseEntries'
import InmateUAs from './InmateUAs'
import InmateFollowups from './InmateFollowups'
import InmateLSIR from './InmateLSIR'
import {
  Container,
  Icon,
  Header,
  Tab,
  Image,
  Modal,
  Grid,
  Popup,
  Message,
  Button,
  Checkbox,
  Label
} from 'semantic-ui-react'
import FsLightbox from 'fslightbox-react'
const _ = require('underscore')

type PaneProps =
    { inmate: PanesStore.Inmate, num: 1 | 2, print: boolean, hide: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmatePane extends React.PureComponent<PaneProps> {
    public render() {
      if (window.location.host.split(".")[0] == "sms") {
        var panes = [
          { menuItem: 'Info', render: () => <Tab.Pane><InmateInfo inmate={this.props.inmate} num={this.props.num} print={this.props.print} hide={this.props.hide} /></Tab.Pane> },
          { menuItem: 'Classes', render: () => <Tab.Pane><InmateClasses inmate={this.props.inmate} num={this.props.num} print={this.props.print} hide={this.props.hide} /></Tab.Pane> },
          { menuItem: 'Tests', render: () => <Tab.Pane><InmateTests inmate={this.props.inmate} num={this.props.num} print={this.props.print} hide={this.props.hide} /></Tab.Pane> },
          { menuItem: 'Education', render: () => <Tab.Pane><InmateEducation inmate={this.props.inmate} num={this.props.num} print={this.props.print} hide={this.props.hide} /></Tab.Pane> },
          { menuItem: 'Employment', render: () => <Tab.Pane><InmateEmployment inmate={this.props.inmate} num={this.props.num} print={this.props.print} hide={this.props.hide} /></Tab.Pane> },
          { menuItem: 'Community', render: () => <Tab.Pane><InmateCommunity inmate={this.props.inmate} num={this.props.num} print={this.props.print} /></Tab.Pane> },
          { menuItem: 'Health', render: () => <Tab.Pane><InmateHealth inmate={this.props.inmate} num={this.props.num} print={this.props.print} hide={this.props.hide} /></Tab.Pane> },
          { menuItem: 'Military', render: () => <Tab.Pane><InmateMilitary inmate={this.props.inmate} num={this.props.num} print={this.props.print} /></Tab.Pane> },
          { menuItem: 'Review', render: () => <Tab.Pane><InmateReview inmate={this.props.inmate} num={this.props.num} print={this.props.print} hide={this.props.hide} /></Tab.Pane> },
          { menuItem: 'Forms', render: () => <Tab.Pane><InmateForms inmate={this.props.inmate} num={this.props.num} print={this.props.print} /></Tab.Pane> },
          { menuItem: 'Documents', render: () => <Tab.Pane><InmateDocuments inmate={this.props.inmate} num={this.props.num} print={this.props.print} /></Tab.Pane> },
          { menuItem: 'Changelog', render: () => <Tab.Pane><InmateChangelog inmate={this.props.inmate} num={this.props.num} print={this.props.print} /></Tab.Pane> }
        ]
      } else {
        var panes = [
          { menuItem: 'Info', render: () => <Tab.Pane><InmateInfo inmate={this.props.inmate} num={this.props.num} print={this.props.print} /></Tab.Pane> },
          { menuItem: 'Classes', render: () => <Tab.Pane><InmateClasses inmate={this.props.inmate} num={this.props.num} print={this.props.print} /></Tab.Pane> },
          { menuItem: 'Education', render: () => <Tab.Pane><InmateEducation inmate={this.props.inmate} num={this.props.num} print={this.props.print} /></Tab.Pane> },
          { menuItem: 'Military', render: () => <Tab.Pane><InmateMilitary inmate={this.props.inmate} num={this.props.num} print={this.props.print} /></Tab.Pane> },
          { menuItem: 'Screenings', render: () => <Tab.Pane><InmateScreenings inmate={this.props.inmate} num={this.props.num} print={this.props.print} /></Tab.Pane> },
          { menuItem: 'A&Ds', render: () => <Tab.Pane><InmateANDs inmate={this.props.inmate} num={this.props.num} print={this.props.print} /></Tab.Pane> },
          { menuItem: 'Followups', render: () => <Tab.Pane><InmateFollowups inmate={this.props.inmate} num={this.props.num} print={this.props.print} /></Tab.Pane> },
          { menuItem: 'UAs', render: () => <Tab.Pane><InmateUAs inmate={this.props.inmate} num={this.props.num} print={this.props.print} /></Tab.Pane> },
          { menuItem: 'Phase Entry', render: () => <Tab.Pane><InmatePhaseEntries inmate={this.props.inmate} num={this.props.num} print={this.props.print} /></Tab.Pane> },
          { menuItem: 'LSIR & ASUS', render: () => <Tab.Pane><InmateLSIR inmate={this.props.inmate} num={this.props.num} print={this.props.print} /></Tab.Pane> }
        ]
      }

      return (
        <Container fluid>
          {this.props.print ? null : <div className='close' style={{position:'absolute',top:0,left:0,zIndex:1}}>
            <Button circular color='red' icon='close' size='mini' onClick={() => this.props.togglePane(this.props.num)} />
          </div>}
          <Grid>
            <Grid.Column width={2}>
              <div>{this.props.inmate.data.photoID ? <img src={`https://sms.psd-hi.com/assets/images/${this.props.inmate.data.photoID}.JPG`} className='inmate-image' onClick={() => this.props.toggleImageModal(this.props.num)} style={{maxHeight:100,maxWidth:'100%'}} /> : <Icon name='user' style={{fontSize:'6em',marginTop:35,marginBottom:-10,color:this.props.inmate.data.sex == "M" ? "#89B0E0" : this.props.inmate.data.sex == "F" ? "#EF7D92" : "#bbb"}} />}</div>
            </Grid.Column>
            <Grid.Column width={10}>
              <Grid.Row>
                <Header as='h2' style={{whiteSpace:'normal'}}>{this.props.inmate.data.lastName}, {this.props.inmate.data.firstName} {this.props.inmate.data.mi}</Header>
              </Grid.Row>
              <Grid.Row style={{marginTop:10}}>
                <Grid>
                  <Grid.Column width={4}>
                    <div style={{fontSize:'1.2em',display:'inline-block'}}><Icon name="id badge" /> {this.props.inmate.data.sid}</div>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <div style={{fontSize:'1.2em',display:'inline-block',marginLeft:20}}><Icon name="building" /> {this.props.inmate.data.facilityAbbr}</div>
                  </Grid.Column>
                  {this.props.inmate.info.educationContract ? <Popup position='right center' style={{marginBottom:0}} content='Signed education contract' trigger={<Grid.Column width={1} style={{paddingTop:'1em'}}>
                    <div style={{fontSize:'1.2em',display:'inline-block'}}><Icon name="file alternate" color='green' /></div>
                  </Grid.Column>} /> : null}
                </Grid>
              </Grid.Row>
              <Grid.Row>
                <Grid>
                  <Popup position='top center' content={this.props.inmate.data.ssn} style={{marginBottom:0}} trigger={<Grid.Column width={4} style={{paddingTop:'0.5em'}}>
                    <div style={{fontSize:'1.2em',display:'inline-block'}}><Icon name="shield" /> ***-**-{this.props.inmate.data.ssn ? this.props.inmate.data.ssn.slice(-4) : '****'}</div>
                  </Grid.Column>} />
                  {this.props.inmate.data.housing ? <Grid.Column width={4} style={{paddingTop:'0.5em'}}>
                    <div style={{fontSize:'1.2em',display:'inline-block',marginLeft:20}}><Icon name="home" /> {this.props.inmate.data.housing}</div>
                  </Grid.Column> : null}
                </Grid>
              </Grid.Row>
              <Grid.Row>
                <Grid>
                  <Grid.Column width={4} style={{paddingTop:'0.5em'}}>
                    <div style={{fontSize:'1.2em',display:'inline-block'}}><Icon name="birthday" /> {this.props.inmate.data.dob}</div>
                  </Grid.Column>
                  {this.props.inmate.data.diplomaGEDVerified == "Y" ? <Popup position='top center' style={{marginBottom:0}} content={this.props.inmate.data.gradType == 'G' ? `GED${this.props.inmate.data.gradFrom ? ` ${this.props.inmate.data.gradFrom}` : ''}` : this.props.inmate.data.gradType == 'H' ? 'HiSET' : this.props.inmate.data.gradType == 'D' ? 'Diploma' : ''} trigger={<Grid.Column width={5} style={{paddingTop:'0.5em'}}>
                    <div style={{fontSize:'1.2em',display:'inline-block',marginLeft:20}}><Icon name="graduation" /> {this.props.inmate.data.gradDate}</div>
                  </Grid.Column>} /> : null}
                </Grid>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={4}>
              <Popup content={this.props.print ? `Exit from print screen mode.` : `Enter into print screen mode.`} position="bottom right" trigger={<Button color='blue' circular icon='print' onClick={() => this.props.togglePrint(this.props.num)} style={{float:'right',fontSize:'1.2em'}} />} />
              {this.props.print ? null : this.props.inmate.favorite ? <Popup content={`Remove the student from the favorites list.`} position="bottom right" trigger={<Icon className='favorite' name='star' color='blue' onClick={() => this.props.unfavoriteInmate(this.props.num)} style={{float:'right',fontSize:'2em',marginTop:13,marginRight:18}} />} /> : <Popup content={`Add student to the favorites list.`} position="bottom right" trigger={<Icon className='favorite' name='star outline' color='blue' onClick={() => this.props.favoriteInmate(this.props.num)} style={{float:'right',fontSize:'2em',marginTop:13,marginRight:18}} />} />}
              <div style={{float:'right',width:'100%'}}><div style={{float:'right',textAlign:'center',border:'2px solid rgb(219, 40, 40)',marginTop:15,padding:'6px 12px',fontSize:'1.2em',fontWeight:700,visibility:(this.props.inmate.info.pendingAttendance ? 'visible' : 'hidden')}}>Pending Attendance</div></div>
              {this.props.print ? <Checkbox label='Hide empty tables' checked={this.props.hide} onChange={() => this.props.togglePrintHide(this.props.num)} style={{marginRight:20,marginTop:15,float:'right'}} /> : null}
              {this.props.inmate.data.active ? null : <div style={{float:'right',width:'100%'}}><Message warning compact header='Archived' style={{float:'right',marginRight:10,marginTop:15}} /></div>}
            </Grid.Column>
          </Grid>
          <Tab menu={{ attached: false }} panes={this.props.print ? [panes[this.props.inmate.index]] : panes} activeIndex={this.props.print ? 0 : this.props.inmate.index} onTabChange={(e, { activeIndex }) => { if (typeof activeIndex == 'number') this.props.switchInmateTab(this.props.num, activeIndex) }} className='blue' style={{marginTop:10}} />
          <FsLightbox
            toggler={this.props.inmate.modals.image.open}
            sources={[`https://sms.psd-hi.com/assets/images/${this.props.inmate.data.photoID}.JPG`].concat(_.map(this.props.inmate.info.additionalImages, (image:string) => `https://sms.psd-hi.com/assets/images/${image}.JPG`))}
            type='image'
          />
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num, print: ownProps.options.print, hide: ownProps.options.hide } },
  PanesStore.actionCreators
)(InmatePane as any)