import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import * as StaffStore from '../../../store/reducers/staff'
import * as ReportsStore from '../../../store/reducers/reports'
import {
  Dimmer,
  Loader,
  Table,
  Container
} from 'semantic-ui-react'
const qs = require('qs')
const _ = require('underscore')

type ReportProps =
    { report: any, connected: boolean, options: any, settings: any } &
    typeof ReportsStore.actionCreators &
    typeof StaffStore.actionCreators &
    WithRouterProps<{}>

class GraduatedHTML extends React.PureComponent<ReportProps> {
    public componentDidMount() {
        this.props.fetchFacilities()
        this.props.fetchSettings()
        this.props.socketConnect()
    }

    public componentDidUpdate(prevProps:any) {
      if (!prevProps.connected && this.props.connected && !this.props.report) {
          this.props.generateGraduated()
      }
    }

    public render() {
      if (!this.props.report || this.props.report.loading) {
        return (
          <Fragment>
            <Dimmer active inverted>
              <Loader content='Generating report...' />
            </Dimmer>
          </Fragment>
        )
      } else {
        let params = qs.parse(window.location.search.substr(1, window.location.search.length));
        let data = this.props.report.generated
        let options = this.props.options
        let facilities = typeof params.facilities == 'string' ? [params.facilities] : params.facilities
        let num = 0

        const total =  _.reduce(data.facilities, (acc:number, facility:any) => acc + facility.inmates.length, 0)
        const totalG = _.reduce(data.facilities, (acc:number, facility:any) => acc + _.filter(facility.inmates, (inmate:any) => inmate.gradType == "G").length, 0)
        const totalH = _.reduce(data.facilities, (acc:number, facility:any) => acc + _.filter(facility.inmates, (inmate:any) => inmate.gradType == "H").length, 0)
        const totalC = _.reduce(data.facilities, (acc:number, facility:any) => acc + _.filter(facility.inmates, (inmate:any) => inmate.gradType == "C").length, 0)
        const totalD = _.reduce(data.facilities, (acc:number, facility:any) => acc + _.filter(facility.inmates, (inmate:any) => inmate.gradType == "D").length, 0)
        const totalU = _.reduce(data.facilities, (acc:number, facility:any) => acc + _.filter(facility.inmates, (inmate:any) => !['G','H','C','D'].includes(inmate.gradType)).length, 0)

        return (
          <Container fluid>
            <div style={{textAlign:'center',marginBottom:15}}>
              <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Graduated Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
            </div>
            <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
              <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Sort by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.sortBy == "name" ? 'Inmate name' : 'Grad date'}</div></div>
              <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Test type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.gradType == "G" ? "GED" : params.gradType == "H" ? "HiSET" : "HSE"}</div></div>
              <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Inmates w/o tests:</div><div style={{display:'inline-block',fontWeight:400}}>{params.graduated == 'true' ? 'Yes' : 'No'}</div></div>
              <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Inmates w/o facility:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facility == 'true' ? 'Yes' : 'No'}</div></div>
              <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
            </div>
            <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
              <Table.Body>
                {data.facilities.length == 0 ? <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                  <Table.HeaderCell style={{backgroundColor:'white',padding:6,whiteSpace:'nowrap'}}>No data available</Table.HeaderCell>
                </Table.Row> : <Fragment>
                  <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                    <Table.Cell style={{textAlign:'right',borderTop:0}}><b>Total</b></Table.Cell>
                    <Table.Cell colSpan={3} style={{borderTop:0}}><div style={{whiteSpace:'nowrap'}}>{total}</div></Table.Cell>
                    <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                  </Table.Row>
                  {['A','E','G'].includes(params.gradType) && totalG > 0 ? <Table.Row style={{backgroundColor: num % 2 === 0 ? 'rgba(0,0,50,.05)' : 'white'}}>
                    <Table.Cell style={{textAlign:'right'}}><b>GED</b></Table.Cell>
                    <Table.Cell colSpan={3}><div style={{whiteSpace:'nowrap'}}>{totalG}</div></Table.Cell>
                    <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                  </Table.Row> : null}
                  {['A','E','H'].includes(params.gradType) && totalH > 0 ? <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                    <Table.Cell style={{textAlign:'right'}}><b>HiSET</b></Table.Cell>
                    <Table.Cell colSpan={3}><div style={{whiteSpace:'nowrap'}}>{totalH}</div></Table.Cell>
                    <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                  </Table.Row> : null}
                  {['A','E','C'].includes(params.gradType) && totalC > 0 ? <Table.Row style={{backgroundColor: num % 2 === 0 ? 'rgba(0,0,50,.05)' : 'white'}}>
                    <Table.Cell style={{textAlign:'right'}}><b>CBCSDP</b></Table.Cell>
                    <Table.Cell colSpan={3}><div style={{whiteSpace:'nowrap'}}>{totalC}</div></Table.Cell>
                    <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                  </Table.Row> : null}
                  {['A','D'].includes(params.gradType) && totalD > 0 ? <Table.Row style={{backgroundColor: num % 2 === 0 ? 'rgba(0,0,50,.05)' : 'white'}}>
                    <Table.Cell style={{textAlign:'right'}}><b>Diploma</b></Table.Cell>
                    <Table.Cell colSpan={3}><div style={{whiteSpace:'nowrap'}}>{totalD}</div></Table.Cell>
                    <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                  </Table.Row> : null}
                  {params.gradType == "A" && totalU > 0 ? <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                    <Table.Cell style={{textAlign:'right',borderBottom:'1px solid rgba(34,36,38,0.15)'}}><b>Unknown</b></Table.Cell>
                    <Table.Cell colSpan={3} style={{borderBottom:'1px solid rgba(34,36,38,0.15)'}}><div style={{whiteSpace:'nowrap'}}>{totalU}</div></Table.Cell>
                    <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                  </Table.Row> : null}
                </Fragment>}
                {data.facilities.map((facility:any, f:number) => (
                  <Fragment key={facility.abbreviation}>
                    <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Inmate</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:90}}><div><span>SID</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:90}}><div><span>Grad Type</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Grad Date</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:60}}></span></div></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row positive>
                      <Table.HeaderCell colSpan={4} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.abbreviation ?? 'No Facility'}</b></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.HeaderCell>
                    </Table.Row>
                    {facility.inmates.map((inmate:any, i:number) => {
                      num += 1;
                      return (
                        <Table.Row style={{backgroundColor: num % 2 == 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={inmate.recordID}>
                          <Table.Cell warning={inmate.highlight}><div style={{whiteSpace:'nowrap'}}>{inmate.name}</div></Table.Cell>
                          <Table.Cell warning={inmate.highlight}>{inmate.sid}</Table.Cell>
                          <Table.Cell warning={inmate.highlight}>{inmate.gradType == "G" ? "GED" : inmate.gradType == "H" ? "HiSET" : inmate.gradType == "D" ? "Diploma" : inmate.gradType == "C" ? "CBCSDP" : "Unknown"}</Table.Cell>
                          <Table.Cell warning={inmate.highlight}>{inmate.gradDate}</Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })}
                    {(() => {
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell style={{textAlign:'right'}}><b>Total</b></Table.Cell>
                          <Table.Cell colSpan={3}><div style={{whiteSpace:'nowrap'}}>{facility.inmates.length}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                    {(() => {
                      const total = _.filter(facility.inmates, (inmate:any) => inmate.gradType == "G").length
                      if (!['A','E','G'].includes(params.gradType) || total == 0) return null
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell style={{textAlign:'right'}}><b>GED</b></Table.Cell>
                          <Table.Cell colSpan={3}><div style={{whiteSpace:'nowrap'}}>{total}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                    {(() => {
                      const total = _.filter(facility.inmates, (inmate:any) => inmate.gradType == "H").length
                      if (!['A','E','H'].includes(params.gradType) || total == 0) return null
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell style={{textAlign:'right'}}><b>HiSET</b></Table.Cell>
                          <Table.Cell colSpan={3}><div style={{whiteSpace:'nowrap'}}>{total}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                    {(() => {
                      const total = _.filter(facility.inmates, (inmate:any) => inmate.gradType == "C").length
                      if (!['A','E','C'].includes(params.gradType) || total == 0) return null
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell style={{textAlign:'right'}}><b>CBCSDP</b></Table.Cell>
                          <Table.Cell colSpan={3}><div style={{whiteSpace:'nowrap'}}>{total}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                    {(() => {
                      const total = _.filter(facility.inmates, (inmate:any) => inmate.gradType == "D").length
                      if (!['A','D'].includes(params.gradType) || total == 0) return null
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell style={{textAlign:'right'}}><b>Diploma</b></Table.Cell>
                          <Table.Cell colSpan={3}><div style={{whiteSpace:'nowrap'}}>{total}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                    {(() => {
                      const total = _.filter(facility.inmates, (inmate:any) => !['G','H','C','D'].includes(inmate.gradType)).length
                      if (params.gradType != "A" || total == 0) return null
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell style={{textAlign:'right'}}><b>Unknown</b></Table.Cell>
                          <Table.Cell colSpan={3}><div style={{whiteSpace:'nowrap'}}>{total}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                  </Fragment>
                ))}
              </Table.Body>
            </Table>
            <div className='footer'>
              <div className='left'>
                  {new Date().today() + " @ " + new Date().timeNow()}
              </div>
              <div className='right'>
                  {data.staffID} / {data.printID}
              </div>
              <div className='center'>{this.props.report.copyright}</div>
            </div>
          </Container>
        )
      }
    }
}

export default connect(
  (state: ApplicationState) => { return { report: state.reports.reports.graduated, connected: state.reports.connected, options: state.reports.options, settings: state.staff.settings } },
  { ...StaffStore.actionCreators, ...ReportsStore.actionCreators }
)(GraduatedHTML as any)
