import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import * as StaffStore from '../../../store/reducers/staff'
import * as ReportsStore from '../../../store/reducers/reports'
import {
  Dimmer,
  Loader,
  Table,
  Container
} from 'semantic-ui-react'
const qs = require('qs')
const _ = require('underscore')

type ReportProps =
    { report: any, options: any, settings: any } &
    typeof ReportsStore.actionCreators &
    typeof StaffStore.actionCreators &
    WithRouterProps<{}>

class ANDsHTML extends React.PureComponent<ReportProps> {
    public componentDidMount() {
        this.props.generateANDs()
        this.props.fetchSettings()
    }

    public render() {
      if (!this.props.report || this.props.report.loading) {
        return (
          <Fragment>
            <Dimmer active inverted>
              <Loader content='Generating report...' />
            </Dimmer>
          </Fragment>
        )
      } else {
        let params = qs.parse(window.location.search.substr(1, window.location.search.length));
        let data = this.props.report.generated
        var num = 0

        return (
            <Container fluid>
              <Table compact celled striped unstackable>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell colSpan={8}>
                      <div style={{minHeight:50}}>
                        <div style={{float:'right',color:'#ccc',textAlign:'left',minHeight:30}}>
                          <div><div style={{width:90,display:'inline-block',verticalAlign:'top'}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{data.facilities.length == 0 ? 'All' : _.map(data.facilities, (f:any) => f.abbreviation).join(', ')}</div></div>
                        </div>
                        <div style={{position:'absolute',width:'100%'}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-SA</div><div style={{fontSize:14}}>A&D Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
                      </div>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.facilities.length == 0 ? <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                    <Table.HeaderCell style={{backgroundColor:'white',padding:6,whiteSpace:'nowrap'}}>No data available</Table.HeaderCell>
                  </Table.Row> : null}
                  {data.facilities.map((facility:any, f:number) => (
                    <Fragment key={facility.abbreviation}>
                      <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                        <Table.HeaderCell style={{border:0,paddingTop:2,paddingBottom:2,borderTop: f > 0 ? '1px solid rgba(34,36,38,.1)' : ''}}></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0,paddingTop:2,paddingBottom:2,borderTop: f > 0 ? '1px solid rgba(34,36,38,.1)' : ''}}><div><span>Inmate</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0,paddingTop:2,paddingBottom:2,borderTop: f > 0 ? '1px solid rgba(34,36,38,.1)' : ''}}><div><span>SID</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0,paddingTop:2,paddingBottom:2,borderTop: f > 0 ? '1px solid rgba(34,36,38,.1)' : ''}}><div><span>Admission Date</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0,paddingTop:2,paddingBottom:2,borderTop: f > 0 ? '1px solid rgba(34,36,38,.1)' : ''}}><div><span>Discharge Date</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0,paddingTop:2,paddingBottom:2,borderTop: f > 0 ? '1px solid rgba(34,36,38,.1)' : ''}}><div><span>Primary Program</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0,paddingTop:2,paddingBottom:2,borderTop: f > 0 ? '1px solid rgba(34,36,38,.1)' : ''}}><div><span>Other</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0,paddingTop:2,paddingBottom:2,borderTop: f > 0 ? '1px solid rgba(34,36,38,.1)' : ''}}><div><span>Results</span></div></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row positive>
                        <Table.HeaderCell colSpan={8} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.abbreviation}</b></Table.HeaderCell>
                      </Table.Row>
                      {facility.ands.map((and:any, i:number) => (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={i}>
                          <Table.Cell>{i+1}</Table.Cell>
                          <Table.Cell>{and.inmateName}</Table.Cell>
                          <Table.Cell>{and.sid}</Table.Cell>
                          <Table.Cell>{and.admissionDate}</Table.Cell>
                          <Table.Cell>{and.dischargeDate}</Table.Cell>
                          <Table.Cell>{and.primary}</Table.Cell>
                          <Table.Cell>{and.primaryOther}</Table.Cell>
                          <Table.Cell>{and.dischargeType == "SC" ? "Successful Completion" : ""}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Fragment>
                  ))}
                </Table.Body>
              </Table>
              <div className='footer'>
                <div className='left'>
                    {new Date().today() + " @ " + new Date().timeNow()}
                </div>
                <div className='right'>
                    {data.staffID} / {data.printID}
                </div>
                <div className='center'>{this.props.report.copyright}</div>
              </div>
            </Container>
          )
      }
    }
}

export default connect(
  (state: ApplicationState) => { return { report: state.reports.reports.ands, options: state.reports.options, settings: state.staff.settings } },
  { ...StaffStore.actionCreators, ...ReportsStore.actionCreators }
)(ANDsHTML as any)
