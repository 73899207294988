import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import * as StaffStore from '../../../store/reducers/staff'
import * as ReportsStore from '../../../store/reducers/reports'
import {
  Dimmer,
  Loader,
  Table,
  Container
} from 'semantic-ui-react'
const qs = require('qs')
const _ = require('underscore')

type ReportProps =
    { report: any, connected: boolean, options: any, settings: any } &
    typeof StaffStore.actionCreators &
    typeof ReportsStore.actionCreators &
    WithRouterProps<{}>

class FundingHTML extends React.PureComponent<ReportProps> {
    public componentDidMount() {
        this.props.fetchFacilities()
        this.props.fetchSettings()
        this.props.socketConnect()
    }

    public componentDidUpdate(prevProps:any) {
      if (!prevProps.connected && this.props.connected && !this.props.report) {
          this.props.generateFunding()
      }
    }

    public render() {
      if (!this.props.report || this.props.report.loading) {
        return (
          <Fragment>
            <Dimmer active inverted>
              <Loader content='Generating report...' />
            </Dimmer>
          </Fragment>
        )
      } else {
        let params = qs.parse(window.location.search.substr(1, window.location.search.length))
        let data = this.props.report.generated
        let options = this.props.options
        let facilities = typeof params.facilities == 'string' ? [params.facilities] : params.facilities
        let num = 0

        return (
          <Container fluid>
            <div style={{textAlign:'center',marginBottom:15}}>
              <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Funding Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
            </div>
            <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
              <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
              <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.programs == '0' ? 'All' : _.map((typeof params.programs == 'string' ? [params.programs] : params.programs), (id:any) => _.any(data.programs, (p:any) => p.recordID == id) ? _.find(data.programs, (p:any) => p.recordID == id).name : '').join(', ')}</div></div>
              <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Funding:</div><div style={{display:'inline-block',fontWeight:400}}>{params.funding == 'all' ? 'All' : _.map((typeof params.funding == 'string' ? [params.funding] : params.funding), (id:any) => _.any(data.funding, (f:any) => f.fundingSource == id) ? _.find(data.funding, (f:any) => f.fundingSource == id).fundingSource : '').join(', ')}</div></div>
            </div>
            <Table compact celled striped definition>
              <Table.Body>
                {data.funding.map((funding:any, i:number) => (
                  <Table.Row key={i}>
                    <Table.Cell width={1}>{funding.fundingSource}</Table.Cell>
                    <Table.Cell>{funding.fundingDescription}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <Table compact celled striped unstackable className='slanted' style={{marginTop:0,borderTop:0}}>
              <Table.Body>
                {data.funding.length == 0 ? <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                  <Table.HeaderCell style={{backgroundColor:'white',padding:6,whiteSpace:'nowrap'}}>No data available</Table.HeaderCell>
                </Table.Row> : null}
                {data.funding.map((funding:any, f:number) => (
                  <Fragment key={f}>
                    <Table.Row positive>
                      <Table.HeaderCell colSpan={8} style={{borderTop:'2px solid rgba(34,36,38,.1)',borderBottom:'2px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{funding.fundingSource}</b></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell style={{paddingTop:4,paddingBottom:4}}>Facility</Table.HeaderCell>
                      <Table.HeaderCell style={{paddingTop:4,paddingBottom:4}}>Program</Table.HeaderCell>
                      <Table.HeaderCell style={{paddingTop:4,paddingBottom:4}}>Course</Table.HeaderCell>
                      <Table.HeaderCell style={{paddingTop:4,paddingBottom:4}}>Template</Table.HeaderCell>
                      <Table.HeaderCell style={{paddingTop:4,paddingBottom:4}}>Class</Table.HeaderCell>
                      <Table.HeaderCell style={{paddingTop:4,paddingBottom:4}}>{params.startDate != null && params.endDate != null ? `Enrolled` : `Currently Enrolled`}</Table.HeaderCell>
                      <Table.HeaderCell style={{paddingTop:4,paddingBottom:4}}>{params.startDate != null && params.endDate != null ? `Participated` : `Capacity`}</Table.HeaderCell>
                      <Table.HeaderCell style={{paddingTop:4,paddingBottom:4}}>{params.startDate != null && params.endDate != null ? `Completed` : `Percent Filled`}</Table.HeaderCell>
                    </Table.Row>
                    {funding.facilities.map((facility:any, y:number) =>
                      facility.programs.map((program:any, p:number) =>
                        program.courses.map((course:any, c:number) =>
                          course.templates.map((template:any, t:number) =>
                            template.classes.map((klass:any, k:number) => (
                              <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={`${y}:${p}:${c}:${t}:${k}`}>
                                <Table.Cell warning={p == 0 && c == 0 && t == 0 && k == 0 && facility.highlight}>{p == 0 && c == 0 && t == 0 && k == 0 ? `${facility.abbreviation}` : ''}</Table.Cell>
                                <Table.Cell warning={c == 0 && t == 0 && k == 0 && program.highlight}>{c == 0 && t == 0 && k == 0 ? `${program.name}` : ''}</Table.Cell>
                                <Table.Cell warning={t == 0 && k == 0 && course.highlight}>{t == 0 && k == 0 ? `${course.name}` : ''}</Table.Cell>
                                <Table.Cell warning={k == 0 && template.highlight}>{k == 0 ? `${template.name}` : ''}</Table.Cell>
                                <Table.Cell warning={klass.highlight}>{klass.name}</Table.Cell>
                                <Table.Cell warning={klass.rosterHighlight}>{klass.enrolled}</Table.Cell>
                                <Table.Cell>{params.startDate != null && params.endDate != null ? klass.participated : klass.capacity}</Table.Cell>
                                <Table.Cell>{params.startDate != null && params.endDate != null ? klass.completed : `${klass.percent}%`}</Table.Cell>
                              </Table.Row>
                            ))
                          )
                        )
                      )
                    )}
                  </Fragment>
                ))}
              </Table.Body>
            </Table>
            <div className='footer'>
              <div className='left'>
                  {new Date().today() + " @ " + new Date().timeNow()}
              </div>
              <div className='right'>
                  {data.staffID} / {data.printID}
              </div>
              <div className='center'>{this.props.report.copyright}</div>
            </div>
          </Container>
        )
      }
    }
}

export default connect(
  (state: ApplicationState) => { return { report: state.reports.reports.funding, connected: state.reports.connected, options: state.reports.options, settings: state.staff.settings } },
  { ...StaffStore.actionCreators, ...ReportsStore.actionCreators }
)(FundingHTML as any)
