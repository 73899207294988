import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as ClassesStore from '../../store/reducers/classes'
import * as DrawerStore from '../../store/reducers/drawer'
import * as PanesStore from '../../store/reducers/panes'
import ClassFilters from './ClassFilters'
import CourseGroup from './CourseGroup'
import InfiniteScroll from 'react-infinite-scroller'
import {
  Table,
  Dimmer,
  Loader
} from 'semantic-ui-react'

type TableProps =
    { open: boolean, loading: boolean, hasMore: boolean, tab: string, courses: ClassesStore.Course[] } &
    ClassesStore.ClassesState &
    typeof DrawerStore.actionCreators &
    typeof ClassesStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassesTable extends React.PureComponent<TableProps> {
    public render() {
        return (
          <Fragment>
            <ClassFilters />
            <div id='classes-table' style={{top:this.props.open?200:70}}>
              <Dimmer inverted active={this.props.loading}><Loader inverted content='Loading classes ...' /></Dimmer>
              <InfiniteScroll
                  pageStart={0}
                  loadMore={this.props.fetchNextClasses}
                  hasMore={!this.props.loading && this.props.hasMore}
                  initialLoad={false}
                  useWindow={false}
                  getScrollParent={() => document.getElementById('classes-table') }
              >
                <Table compact celled striped style={{borderTop:0,marginTop:0}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Facility</div></Table.HeaderCell>
                      <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Class ID</div></Table.HeaderCell>
                      <Table.HeaderCell width={6} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Class Name</div></Table.HeaderCell>
                      <Table.HeaderCell width={1} style={{ position:'sticky',top:0,zIndex:1,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Actions</div></Table.HeaderCell>
                      <Table.HeaderCell width={2} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Schedule</div></Table.HeaderCell>
                      <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Location</div></Table.HeaderCell>
                      <Table.HeaderCell width={2} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Instructor</div></Table.HeaderCell>
                      <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Funds</div></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.props.courses.map((course:any) => (
                      <CourseGroup course={course} key={course.key} />
                    ))}
                  </Table.Body>
                </Table>
              </InfiniteScroll>
            </div>
          </Fragment>
        )
    }
}

export default connect(
  (state: ApplicationState) => { return { open: state.classes.open, loading: state.classes.tabs.classes.loading, hasMore: state.classes.tabs.classes.hasMore, tab: state.drawer.tab, courses: state.classes.tabs.classes.data } },
  { ...DrawerStore.actionCreators, ...ClassesStore.actionCreators, ...PanesStore.actionCreators }
)(ClassesTable as any)