import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as ClassesStore from '../../store/reducers/classes'
import * as PanesStore from '../../store/reducers/panes'
import {
  Table,
  Popup,
  Label,
  Icon,
  Button
} from 'semantic-ui-react'
const _ = require('underscore')

type ClassProps =
    { class: ClassesStore.Class, filters: ClassesStore.ClassFilters, dates: boolean, tab: string, mode: string } &
    typeof DrawerStore.actionCreators &
    typeof ClassesStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassRow extends React.PureComponent<ClassProps> {
    private dayOfWeek3 = (day:number) => {
        switch(day) {
          case 0: {
            return 'Sun'
          }
          case 1: {
            return 'Mon'
          }
          case 2: {
            return 'Tue'
          }
          case 3: {
            return 'Wed'
          }
          case 4: {
            return 'Thu'
          }
          case 5: {
            return 'Fri'
          }
          case 6: {
            return 'Sat'
          }
          default: {
            return ''
          }
        }
    }

    private scheduleDate = (day:number) => {
        var date = new Date()
        date.setHours(0,0,0,0);
        var diff = day - date.getDay()
        date.setDate(date.getDate() + diff)
        return date
    }

    public render() {
        return (
          <Table.Row id={`class${this.props.class.recordID}`}>
            <Table.Cell>{this.props.class.facilityAbbr}</Table.Cell>
            <Table.Cell>
              <Popup
                hideOnScroll
                on='click'
                trigger={<div className='link' style={{whiteSpace:'normal'}}>{this.props.class.recordID}</div>}
                style={{overflowY:'auto',maxHeight:500}}
                basic
              >
                <Popup.Content>
                  <Table compact celled striped definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>Class</Table.Cell>
                        <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{this.props.class.className} - {this.props.class.recordID}</div></Table.Cell>
                      </Table.Row>
                      {this.props.class.instructorName ? <Table.Row>
                        <Table.Cell>Instructor</Table.Cell>
                        <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{this.props.class.instructorName}</div></Table.Cell>
                      </Table.Row> : null}
                      <Table.Row>
                        <Table.Cell>Dates</Table.Cell>
                        <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{this.props.class.dateBegins} - {this.props.class.dateEnds}</div></Table.Cell>
                      </Table.Row>
                      {this.props.class.hiatusBegins ? <Table.Row>
                        <Table.Cell>Hiatus</Table.Cell>
                        <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{this.props.class.hiatusBegins} - {this.props.class.hiatusEnds}</div></Table.Cell>
                      </Table.Row> : null}
                      {this.props.class.fundingSource ? <Table.Row>
                        <Table.Cell>Funding</Table.Cell>
                        <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{this.props.class.fundingSource}</div></Table.Cell>
                      </Table.Row> : null}
                      {this.props.class.schedules.length > 0 ? <Table.Row>
                        <Table.Cell>Schedule</Table.Cell>
                        <Table.Cell>{_.map(this.props.class.schedules, (schedule:any) => { return <div key={schedule.recordID} style={{whiteSpace:'nowrap'}}><span style={{display:'inline-block',width:32}}>{this.dayOfWeek3(schedule.dayOfWeek)}.</span> {schedule.startTime} - {schedule.endTime} / {schedule.location}{schedule.instructor ? ` / ${schedule.instructor}` : null}</div> })}</Table.Cell>
                      </Table.Row> : null}
                    </Table.Body>
                  </Table>
                  {this.props.class.rosters.length > 0 ? <Table compact celled striped style={{marginBottom:20}}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>SID</Table.HeaderCell>
                        <Table.HeaderCell>Housing</Table.HeaderCell>
                        <Table.HeaderCell>Attendance</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {_.map(this.props.class.rosters, (roster:any) => <Table.Row key={roster.recordID}>
                        <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{roster.lastName}, {roster.firstName}</div></Table.Cell>
                        <Table.Cell>{roster.sid}</Table.Cell>
                        <Table.Cell>{roster.housing}</Table.Cell>
                        <Table.Cell>{roster.percentAttended}</Table.Cell>
                      </Table.Row>)}
                    </Table.Body>
                  </Table> : null}
                  {this.props.mode == 'dual' ? <div className='flex justify-between'>
                    <Button size='mini' color='blue' content='Open in Pane 1' onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(1, this.props.class.recordID) }} />
                    <Button size='mini' color='blue' content='Open in Pane 2' onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(2, this.props.class.recordID) }} />
                  </div> : <div className='flex justify-between'>
                    <Button size='mini' color='blue' content='Open' onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(this.props.mode == 'left' ? 1 : 2, this.props.class.recordID) }} />
                  </div>}
                </Popup.Content>
              </Popup>
            </Table.Cell>
            <Table.Cell>
              {this.props.mode == 'dual' ? <Popup
                hideOnScroll
                on='click'
                trigger={<div className='link' style={{whiteSpace:'normal'}}>
                  {this.props.class.hasTransfers ? <Popup content="Inmates have been transferred to another facility" position="top center" trigger={<Icon name="exclamation" color="red" />} /> : null}
                  {this.props.class.hasSuspensions ? <Popup content="Inmates have been suspended" position="top center" trigger={<Icon name="dont" color="red" />} /> : null}
                  {this.props.class.hasQuarantines ? <Popup content="Inmates have been quarantined" position="top center" trigger={<Icon name="plus square" color="red" />} /> : null}
                  {this.props.class.lowAttendance ? <Popup content="Inmates have low attendance" position="top center" trigger={<Icon name="calendar minus" color="red" />} /> : null}
                  {this.props.class.missingSchedules ? <Popup content="Inmates have no schedules assigned" position="top center" trigger={<Icon name="calendar times" color="red" />} /> : null}
                  {this.props.class.capacity >= 0.8 && this.props.class.capacity < 1 ? <Popup content="Class is close to capacity" position="top center" trigger={<Icon name="hourglass half" color="red" />} /> : null}
                  {this.props.class.capacity >= 1 ? <Popup content="Class is at capacity" position="top center" trigger={<Icon name="hourglass full" color="red" />} /> : null}
                  {this.props.class.className}
                  {this.props.class.favorite ? <Popup content="Class is a favorite" position="top center" trigger={<Icon name="star" color="blue" style={{marginLeft:5}} />} /> : null}
                </div>}
              >
                <Popup.Header>Open in pane</Popup.Header>
                <Popup.Content>
                  <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(1, this.props.class.recordID) }}>1</Label>
                  <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(2, this.props.class.recordID) }}>2</Label>
                  {this.props.class.favorite ? <Label circular icon='star' className='zero' onClick={() => { this.props.unfavoriteClassRow(this.props.class.recordID) }} /> : <Label circular icon='star outline' className='zero' onClick={() => { this.props.favoriteClassRow(this.props.class.recordID) }} />}
                </Popup.Content>
              </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchClass(this.props.mode == 'left' ? 1 : 2, this.props.class.recordID) }}>
                {this.props.class.hasTransfers ? <Popup content="Inmates have been transferred to another facility" position="top center" trigger={<Icon name="exclamation" color="red" />} /> : null}
                {this.props.class.hasSuspensions ? <Popup content="Inmates have been suspended" position="top center" trigger={<Icon name="dont" color="red" />} /> : null}
                {this.props.class.hasQuarantines ? <Popup content="Inmates have been quarantined" position="top center" trigger={<Icon name="plus square" color="red" />} /> : null}
                {this.props.class.lowAttendance ? <Popup content="Inmates have low attendance" position="top center" trigger={<Icon name="calendar minus" color="red" />} /> : null}
                {this.props.class.missingSchedules ? <Popup content="Inmates have no schedules assigned" position="top center" trigger={<Icon name="calendar times" color="red" />} /> : null}
                {this.props.class.capacity >= 0.8 && this.props.class.capacity < 1 ? <Popup content="Class is close to capacity" position="top center" trigger={<Icon name="hourglass half" color="red" />} /> : null}
                {this.props.class.capacity >= 1 ? <Popup content="Class is at capacity" position="top center" trigger={<Icon name="hourglass full" color="red" />} /> : null}
                {this.props.class.className}
                {this.props.class.favorite ? <Popup content="Class is a favorite" position="top center" trigger={<Icon name="star" color="blue" style={{marginLeft:5}} />} /> : null}
              </div>}
            </Table.Cell>
            <Table.Cell>
              {this.props.filters.classStatus == 'active' ? <Button.Group style={{position:'relative'}}>
                {this.props.class.pendingAttendance ? <div className='class-pending-attendance' /> : null}
                {this.props.mode == 'dual' ? <Popup content={`Open Class Roster`} position="top center" trigger={<Button style={{width:50,padding:0}}><Popup
                  hideOnScroll
                  on='click'
                  trigger={this.props.class.rosterCount == this.props.class.activeCount ? <div>{this.props.class.rosterCount}</div> : <div><span style={{color:'#3C5998'}}>{this.props.class.activeCount}</span> / {this.props.class.rosterCount}</div>}
                >
                  <Popup.Header>Open in pane</Popup.Header>
                  <Popup.Content>
                    <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.actionSelect(this.props.class.recordID, 'roster', 1) }}>1</Label>
                    <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.actionSelect(this.props.class.recordID, 'roster', 2) }}>2</Label>
                  </Popup.Content>
                </Popup></Button>} /> : <Popup content={`Open Class Roster`} position="top center" trigger={<Button style={{width:50,padding:0}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.actionSelect(this.props.class.recordID, 'roster', this.props.mode == 'left' ? 1 : 2) }}>{this.props.class.rosterCount == this.props.class.activeCount ? <div>{this.props.class.rosterCount}</div> : <div><span style={{color:'#3C5998'}}>{this.props.class.activeCount}</span> / {this.props.class.rosterCount}</div>}</Button>} />}
                {this.props.mode == 'dual' ? <Popup content={`Open Class Attendance`} position="top center" trigger={<Button style={{width:50,padding:0}}><Popup
                  hideOnScroll
                  on='click'
                  trigger={<div style={{padding:10}}><Icon name='user' style={{margin: 0, color: this.props.class.facilityGender == 'M' || this.props.class.classGender == 'M' ? '#347DC1' : this.props.class.facilityGender == 'F' || this.props.class.classGender == 'F' ? '#CC6594' : ''}} /></div>}
                >
                  <Popup.Header>Open in pane</Popup.Header>
                  <Popup.Content>
                    <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.actionSelect(this.props.class.recordID, 'attendance', 1) }}>1</Label>
                    <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.actionSelect(this.props.class.recordID, 'attendance', 2) }}>2</Label>
                  </Popup.Content>
                </Popup></Button>} /> : <Popup content={`Open Class Attendance`} position="top center" trigger={<Button icon='user' style={{color: this.props.class.facilityGender == 'M' || this.props.class.classGender == 'M' ? '#347DC1' : this.props.class.facilityGender == 'F' || this.props.class.classGender == 'F' ? '#CC6594' : ''}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.actionSelect(this.props.class.recordID, 'attendance', this.props.mode == 'left' ? 1 : 2) }} />} />}
                {this.props.mode == 'dual' ? <Popup content={`Open Class Completions`} position="top center" trigger={<Button style={{width:35,padding:0}}><Popup
                  hideOnScroll
                  on='click'
                  trigger={<div style={{padding:10}}><Icon name='flag checkered' /></div>}
                >
                  <Popup.Header>Open in pane</Popup.Header>
                  <Popup.Content>
                    <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.actionSelect(this.props.class.recordID, 'completions', 1) }}>1</Label>
                    <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.actionSelect(this.props.class.recordID, 'completions', 2) }}>2</Label>
                  </Popup.Content>
                </Popup></Button>} /> : <Popup content={`Open Class Completions`} position="top center" trigger={<Button icon='flag checkered' onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.actionSelect(this.props.class.recordID, 'completions', this.props.mode == 'left' ? 1 : 2) }} />} />}
              </Button.Group> : <Button.Group>
                {this.props.mode == 'dual' ? <Popup content={`Open Class Attendance`} position="top center" trigger={<Button style={{width:35,padding:0}}><Popup
                  hideOnScroll
                  on='click'
                  trigger={<div style={{padding:10}}><Icon name='user' style={{margin: 0, color: this.props.class.facilityGender == 'M' || this.props.class.classGender == 'M' ? '#347DC1' : this.props.class.facilityGender == 'F' || this.props.class.classGender == 'F' ? '#CC6594' : ''}} /></div>}
                >
                  <Popup.Header>Open in pane</Popup.Header>
                  <Popup.Content>
                    <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.actionSelect(this.props.class.recordID, 'attendance', 1) }}>1</Label>
                    <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.actionSelect(this.props.class.recordID, 'attendance', 2) }}>2</Label>
                  </Popup.Content>
                </Popup></Button>} /> : <Popup content={`Open Class Attendance`} position="top center" trigger={<Button icon='user' style={{color: this.props.class.facilityGender == 'M' || this.props.class.classGender == 'M' ? '#347DC1' : this.props.class.facilityGender == 'F' || this.props.class.classGender == 'F' ? '#CC6594' : ''}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.actionSelect(this.props.class.recordID, 'attendance', this.props.mode == 'left' ? 1 : 2) }} />} />}
              </Button.Group>}
            </Table.Cell>
            <Table.Cell>
              {this.props.dates ? this.props.class.dateBegins ? <div style={{fontSize:13}}><b>{this.props.class.dateBegins}</b></div> : <div style={{fontSize:13}}><b>No start date</b></div> : null}
              {this.props.dates && this.props.class.hiatusBegins != null ? <div style={{color:'#db2828',marginBottom:3,fontSize:13}}><b>{this.props.class.hiatusBegins}</b></div> : null}
              {_.map(this.props.class.schedules, (schedule:any) => { return <div key={schedule.recordID} style={{whiteSpace:'nowrap'}}><span style={{display:'inline-block',width:32}}>{this.dayOfWeek3(schedule.dayOfWeek)}.</span> {schedule.startTime} - {schedule.endTime}{this.props.class.hiatusBegins != null && this.props.class.hiatusEnds != null && this.scheduleDate(schedule.dayOfWeek) >= new Date(this.props.class.hiatusBegins) && this.scheduleDate(schedule.dayOfWeek) <= new Date(this.props.class.hiatusEnds) ? <Icon name='calendar times outline' color='red' style={{marginLeft:5}} /> : null}</div> })}
              {this.props.dates && this.props.class.hiatusEnds != null ? <div style={{color:'#db2828',marginTop:3,fontSize:13}}><b>{this.props.class.hiatusEnds}</b></div> : null}
              {this.props.dates ? this.props.class.dateEnds ? <div style={{fontSize:13}}><b>{this.props.class.dateEnds}</b></div> : <div style={{fontSize:13}}><b>No end date</b></div> : null}
            </Table.Cell>
            <Table.Cell>
              {_.map(this.props.class.schedules, (schedule:any) => { return <div key={schedule.recordID} style={{whiteSpace:'nowrap'}}>{schedule.location}</div> })}
            </Table.Cell>
            <Table.Cell>{this.props.class.instructorName}</Table.Cell>
            <Table.Cell>{this.props.class.fundingSource}</Table.Cell>
          </Table.Row>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, filters: state.classes.filters, dates: state.classes.dates, tab: state.drawer.tab, mode: state.panes.mode } },
  { ...DrawerStore.actionCreators, ...PanesStore.actionCreators, ...ClassesStore.actionCreators }
)(ClassRow as any)