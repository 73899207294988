import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import * as StaffStore from '../../../store/reducers/staff'
import * as ReportsStore from '../../../store/reducers/reports'
import {
  Dimmer,
  Loader,
  Table,
  Container,
  Icon
} from 'semantic-ui-react'
const qs = require('qs')
const _ = require('underscore')

type ReportProps =
    { report: any, connected: boolean, options: any, settings: any } &
    typeof ReportsStore.actionCreators &
    typeof StaffStore.actionCreators &
    WithRouterProps<{}>

class TabeHTML extends React.PureComponent<ReportProps> {
    public componentDidMount() {
        this.props.fetchFacilities()
        this.props.fetchSettings()
        this.props.socketConnect()
    }

    public componentDidUpdate(prevProps:any) {
      if (!prevProps.connected && this.props.connected && !this.props.report) {
          this.props.generateTABE()
      }
    }

    public render() {
      if (!this.props.report || this.props.report.loading) {
        return (
          <Fragment>
            <Dimmer active inverted>
              <Loader content='Generating report...' />
            </Dimmer>
          </Fragment>
        )
      } else {
        let params = qs.parse(window.location.search.substr(1, window.location.search.length));
        let data = this.props.report.generated
        let options = this.props.options
        let facilities = typeof params.facilities == 'string' ? [params.facilities] : params.facilities
        let num = 0

        return (
          <Container fluid>
            <div style={{textAlign:'center',marginBottom:15}}>
              <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>TABE Report</div>{params.startDate && params.endDate ? <div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div> : null}</div>
            </div>
            <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
              <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'standard' ? 'Standard' : params.reportType == 'archived' ? 'Archived' : params.reportType == 'roster' ? 'Roster' : params.reportType == 'compare' ? 'Compare' : ''}</div></div>
              {params.reportType != 'roster' && params.reportType != 'compare' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Verified:</div><div style={{display:'inline-block',fontWeight:400}}>{params.verified ? 'Yes' : 'No'}</div></div> : null}
              {params.reportType != 'roster' && params.reportType != 'compare' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Filter by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.filteredBy ? typeof params.filteredBy == 'string' ? (params.filteredBy == 'learning' ? 'Learning issues' : 'Under 22') : _.map(params.filteredBy, (filter:string) => filter == 'learning' ? 'Learning issues' : 'Under 22').join(', ') : ''}</div></div> : null}
              {params.reportType != 'roster' && params.reportType != 'compare' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>No. of tests:</div><div style={{display:'inline-block',fontWeight:400}}>{params.num}</div></div> : null}
              {params.reportType == 'compare' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Filter by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.filteredBy ? typeof params.filteredBy == 'string' ? (params.filteredBy == 'abe' ? 'ABE students' : params.filteredBy == 'tabe1112' ? 'TABE 11/12 only' : params.filteredBy == 'rise' ? 'Level rises only' : 'Level falls only') : _.map(params.filteredBy, (filter:string) => filter == 'abe' ? 'ABE students' : filter == 'tabe1112' ? 'TABE 11/12 only' : filter == 'rise' ? 'Level rises only' : 'Level falls only').join(', ') : ''}</div></div> : null}
              {params.reportType == 'roster' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>ABE hours:</div><div style={{display:'inline-block',fontWeight:400}}>{!params.abeHours || params.abeHours == 0 ? 'All' : params.abeHours}</div></div> : null}
              <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
            </div>
            <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
              <Table.Body>
                {data.facilities.length == 0 ? <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                  <Table.HeaderCell style={{backgroundColor:'white',padding:6,whiteSpace:'nowrap'}}>No data available</Table.HeaderCell>
                </Table.Row> : null}
                {data.facilities.map((facility:any, f:number) => (
                  <Fragment key={facility.abbreviation}>
                    <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                      <Table.HeaderCell className='rotate' style={{border:0,width:20}}></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Inmate</span></div></Table.HeaderCell>
                      {params.reportType == 'standard' ? <Fragment>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>SID</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Date</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Test</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Reading LF</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Reading SS</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Reading GE</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Reading NRS</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Math LF</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Math SS</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Math GE</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Math NRS</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Language LF</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Language SS</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Language GE</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Language NRS</span></div></Table.HeaderCell>
                      </Fragment> : null}
                      {params.reportType == 'archived' ? <Fragment>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>SID</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Date</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Test</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Reading LF</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Reading SS</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Reading GE</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Math LF</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Math SS</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Math GE</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Language LF</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Language SS</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Language GE</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Math Comp. LF</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Math Comp. SS</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Math Comp. GE</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Applied Math LF</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Applied Math SS</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Applied Math GE</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Language Mech. LF</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Language Mech. SS</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Language Mech. GE</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Vocabulary LF</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Vocabulary SS</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Vocabulary GE</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Spelling LF</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Spelling SS</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Spelling GE</span></div></Table.HeaderCell>
                      </Fragment> : null}
                      {params.reportType == 'roster' || params.reportType == 'compare' ? <Fragment>
                        {params.reportType == 'roster' ? <Table.HeaderCell className='rotate' style={{border:0}}><div><span>DoB</span></div></Table.HeaderCell> : null}
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>SID</span></div></Table.HeaderCell>
                        {params.abeHours > 0 ? <Table.HeaderCell className='rotate' style={{border:0}}><div><span>ABE Hours</span></div></Table.HeaderCell> : null}
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Reading Date 1</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Reading LF 1</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Reading SS 1</span></div></Table.HeaderCell>
                        {params.reportType == 'compare' ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Reading GE 1</span></div></Table.HeaderCell> : null}
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Reading Date 2</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Reading LF 2</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Reading SS 2</span></div></Table.HeaderCell>
                        {params.reportType == 'compare' ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Reading GE 2</span></div></Table.HeaderCell> : null}
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Math Date 1</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Math LF 1</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Math SS 1</span></div></Table.HeaderCell>
                        {params.reportType == 'compare' ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Math GE 1</span></div></Table.HeaderCell> : null}
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Math Date 2</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Math LF 2</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Math SS 2</span></div></Table.HeaderCell>
                        {params.reportType == 'compare' ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Math GE 2</span></div></Table.HeaderCell> : null}
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Language Date 1</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Language LF 1</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Language SS 1</span></div></Table.HeaderCell>
                        {params.reportType == 'compare' ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Language GE 1</span></div></Table.HeaderCell> : null}
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Language Date 2</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Language LF 2</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Language SS 2</span></div></Table.HeaderCell>
                        {params.reportType == 'compare' ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Language GE 2</span></div></Table.HeaderCell> : null}
                      </Fragment> : null}
                      <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:20}}></span></div></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row positive>
                      <Table.HeaderCell colSpan={params.reportType == 'standard' ? 17 : params.reportType == 'archived' ? 29 : params.reportType == 'compare' ? 27 : 22} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.abbreviation}</b></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.HeaderCell>
                    </Table.Row>
                    {facility.inmates.map((inmate:any, i:number) =>
                      inmate.tests.map((tabe:any, t:number) => {
                          num += 1
                          switch (params.reportType) {
                          case "standard":
                            return (<Table.Row warning={facility.highlight || inmate.highlight || tabe.highlight} style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={`${i}:${t}`}>
                              <Table.Cell>{t == 0 ? num : null}</Table.Cell>
                              <Table.Cell>{t == 0 ? inmate.name : null}</Table.Cell>
                              <Table.Cell>{t == 0 ? inmate.sid : null}</Table.Cell>
                              <Table.Cell>{tabe.testDate}</Table.Cell>
                              <Table.Cell><div style={{whiteSpace:'normal'}}>{tabe.test}</div></Table.Cell>
                              <Table.Cell style={{textAlign:'right',borderLeftColor:'black'}}>{tabe.reLF}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.reLF == null && (tabe.reSS == null || tabe.reSS == 0) ? null : tabe.reOR ? "N/A" : `${tabe.reSS}${tabe.reSA ? tabe.reSA : ''}`}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.reLF == null && (tabe.reSS == null || tabe.reSS == 0) ? null : tabe.reOR ? "-" : tabe.reGE}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.reLF == null && (tabe.reSS == null || tabe.reSS == 0) ? null : tabe.reOR ? "O/R" : tabe.reNRS}</Table.Cell>
                              <Table.Cell style={{textAlign:'right',borderLeftColor:'black'}}>{tabe.tmLF}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.tmLF == null && (tabe.tmSS == null || tabe.tmSS == 0) ? null : tabe.tmOR ? "N/A" : `${tabe.tmSS}${tabe.tmSA ? tabe.tmSA : ''}`}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.tmLF == null && (tabe.tmSS == null || tabe.tmSS == 0) ? null : tabe.tmOR ? "-" : tabe.tmGE}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.tmLF == null && (tabe.tmSS == null || tabe.tmSS == 0) ? null : tabe.tmOR ? "O/R" : tabe.tmNRS}</Table.Cell>
                              <Table.Cell style={{textAlign:'right',borderLeftColor:'black'}}>{tabe.laLF}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.laLF == null && (tabe.laSS == null || tabe.laSS == 0) ? null : tabe.laOR ? "N/A" : `${tabe.laSS}${tabe.laSA ? tabe.laSA : ''}`}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.laLF == null && (tabe.laSS == null || tabe.laSS == 0) ? null : tabe.laOR ? "-" : tabe.laGE}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.laLF == null && (tabe.laSS == null || tabe.laSS == 0) ? null : tabe.laOR ? "O/R" : tabe.laNRS}</Table.Cell>
                              <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                            </Table.Row>)
                            break
                          case "archived":
                            return (<Table.Row warning={facility.highlight || inmate.highlight || tabe.highlight} style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={`${i}:${t}`}>
                              <Table.Cell>{t == 0 ? num : null}</Table.Cell>
                              <Table.Cell>{t == 0 ? inmate.name : null}</Table.Cell>
                              <Table.Cell>{t == 0 ? inmate.sid : null}</Table.Cell>
                              <Table.Cell>{tabe.testDate}</Table.Cell>
                              <Table.Cell><div style={{minWidth:240}}>{tabe.test}</div></Table.Cell>
                              <Table.Cell style={{textAlign:'right',borderLeftColor:'black'}}>{tabe.reLF}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.reLF == null && (tabe.reSS == null || tabe.reSS == 0) ? null : tabe.reOR ? "N/A" : `${tabe.reSS}${tabe.reSA ? tabe.reSA : ''}`}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.reLF == null && (tabe.reSS == null || tabe.reSS == 0) ? null : tabe.reOR ? "-" : tabe.reGE}</Table.Cell>
                              <Table.Cell style={{textAlign:'right',borderLeftColor:'black'}}>{tabe.tmLF}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.tmLF == null && (tabe.tmSS == null || tabe.tmSS == 0) ? null : tabe.tmOR ? "N/A" : `${tabe.tmSS}${tabe.tmSA ? tabe.tmSA : ''}`}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.tmLF == null && (tabe.tmSS == null || tabe.tmSS == 0) ? null : tabe.tmOR ? "-" : tabe.tmGE}</Table.Cell>
                              <Table.Cell style={{textAlign:'right',borderLeftColor:'black'}}>{tabe.laLF}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.laLF == null && (tabe.laSS == null || tabe.laSS == 0) ? null : tabe.laOR ? "N/A" : `${tabe.laSS}${tabe.laSA ? tabe.laSA : ''}`}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.laLF == null && (tabe.laSS == null || tabe.laSS == 0) ? null : tabe.laOR ? "-" : tabe.laGE}</Table.Cell>
                              <Table.Cell style={{textAlign:'right',borderLeftColor:'black'}}>{tabe.mcLF}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.mcLF == null && (tabe.mcSS == null || tabe.mcSS == 0) ? null : tabe.mcOR ? "N/A" : `${tabe.mcSS}${tabe.mcSA ? tabe.mcSA : ''}`}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.mcLF == null && (tabe.mcSS == null || tabe.mcSS == 0) ? null : tabe.mcOR ? "-" : tabe.mcGE}</Table.Cell>
                              <Table.Cell style={{textAlign:'right',borderLeftColor:'black'}}>{tabe.amLF}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.amLF == null && (tabe.amSS == null || tabe.amSS == 0) ? null : tabe.amOR ? "N/A" : `${tabe.amSS}${tabe.amSA ? tabe.reSA : ''}`}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.amLF == null && (tabe.amSS == null || tabe.amSS == 0) ? null : tabe.amOR ? "-" : tabe.amGE}</Table.Cell>
                              <Table.Cell style={{textAlign:'right',borderLeftColor:'black'}}>{tabe.lmLF}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.lmLF == null && (tabe.lmSS == null || tabe.lmSS == 0) ? null : tabe.lmOR ? "N/A" : `${tabe.lmSS}${tabe.lmSA ? tabe.reSA : ''}`}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.lmLF == null && (tabe.lmSS == null || tabe.lmSS == 0) ? null : tabe.lmOR ? "-" : tabe.lmGE}</Table.Cell>
                              <Table.Cell style={{textAlign:'right',borderLeftColor:'black'}}>{tabe.voLF}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.voLF == null && (tabe.voSS == null || tabe.voSS == 0) ? null : tabe.voOR ? "N/A" : `${tabe.voSS}${tabe.voSA ? tabe.reSA : ''}`}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.voLF == null && (tabe.voSS == null || tabe.voSS == 0) ? null : tabe.voOR ? "-" : tabe.voGE}</Table.Cell>
                              <Table.Cell style={{textAlign:'right',borderLeftColor:'black'}}>{tabe.spLF}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.spLF == null && (tabe.spSS == null || tabe.spSS == 0) ? null : tabe.spOR ? "N/A" : `${tabe.spSS}${tabe.spSA ? tabe.reSA : ''}`}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.spLF == null && (tabe.spSS == null || tabe.spSS == 0) ? null : tabe.spOR ? "-" : tabe.spGE}</Table.Cell>
                              <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                            </Table.Row>)
                            break
                          case "roster":
                            return (<Table.Row warning={facility.highlight || inmate.highlight || tabe.highlight} style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={`${i}:${t}`}>
                              <Table.Cell>{t == 0 ? num : null}</Table.Cell>
                              <Table.Cell>{t == 0 ? inmate.name : null}</Table.Cell>
                              <Table.Cell>{t == 0 ? inmate.dob : null}</Table.Cell>
                              <Table.Cell>{t == 0 ? inmate.sid : null}</Table.Cell>
                              {params.abeHours > 0 ? <Table.Cell>{t == 0 ? inmate.abeHours : null}</Table.Cell> : null}
                              <Table.Cell style={{borderLeftColor:'black'}}>{tabe.reDate1}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.reLF1}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.reLF1 == null && (tabe.reSS1 == null || tabe.reSS1 == 0) ? null : tabe.reOR1 ? "N/A" : `${tabe.reSS1}${tabe.reSA1 ? tabe.reSA1 : ''}`}</Table.Cell>
                              <Table.Cell>{tabe.reDate2}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.reLF2}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.reLF2 == null && (tabe.reSS2 == null || tabe.reSS2 == 0) ? null : tabe.reOR2 ? "N/A" : `${tabe.reSS2}${tabe.reSA2 ? tabe.reSA2 : ''}`}</Table.Cell>
                              <Table.Cell style={{borderLeftColor:'black'}}>{tabe.tmDate1}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.tmLF1}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.tmLF1 == null && (tabe.tmSS1 == null || tabe.tmSS1 == 0) ? null : tabe.tmOR1 ? "N/A" : `${tabe.tmSS1}${tabe.tmSA1 ? tabe.tmSA1 : ''}`}</Table.Cell>
                              <Table.Cell>{tabe.tmDate2}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.tmLF2}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.tmLF2 == null && (tabe.tmSS2 == null || tabe.tmSS2 == 0) ? null : tabe.tmOR2 ? "N/A" : `${tabe.tmSS2}${tabe.tmSA2 ? tabe.tmSA2 : ''}`}</Table.Cell>
                              <Table.Cell style={{borderLeftColor:'black'}}>{tabe.laDate1}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.laLF1}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.laLF1 == null && (tabe.laSS1 == null || tabe.laSS1 == 0) ? null : tabe.laOR1 ? "N/A" : `${tabe.laSS1}${tabe.laSA1 ? tabe.laSA1 : ''}`}</Table.Cell>
                              <Table.Cell>{tabe.laDate2}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.laLF2}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.laLF2 == null && (tabe.laSS2 == null || tabe.laSS2 == 0) ? null : tabe.laOR2 ? "N/A" : `${tabe.laSS2}${tabe.laSA2 ? tabe.laSA2 : ''}`}</Table.Cell>
                              <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                            </Table.Row>)
                            break
                          case "compare":
                            return (<Table.Row warning={facility.highlight || inmate.highlight || tabe.highlight} style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={`${i}:${t}`}>
                              <Table.Cell>{t == 0 ? num : null}</Table.Cell>
                              <Table.Cell>{t == 0 ? inmate.name : null}</Table.Cell>
                              <Table.Cell>{t == 0 ? inmate.sid : null}</Table.Cell>
                              {params.abeHours > 0 ? <Table.Cell>{t == 0 ? inmate.abeHours : null}</Table.Cell> : null}
                              <Table.Cell style={{borderLeftColor:'black'}}>{tabe.reDate1}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.reLF1}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.reLF1 == null && (tabe.reSS1 == null || tabe.reSS1 == 0) ? null : tabe.reOR1 ? "N/A" : `${tabe.reSS1}${tabe.reSA1 ? tabe.reSA1 : ''}`}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.reLF1 == null && (tabe.reSS1 == null || tabe.reSS1 == 0) ? null : tabe.reOR1 ? "-" : tabe.reGE1}{tabe.reGE1 == null || tabe.reGE2 == null ? null : tabe.reGE2 < tabe.reGE1 ? <Icon name='long arrow alternate up' color='green' style={{position:'absolute',fontSize:'1.3em'}} /> : tabe.reGE2 > tabe.reGE1 ? <Icon name='long arrow alternate down' color='red' style={{position:'absolute',fontSize:'1.3em'}} /> : null}</Table.Cell>
                              <Table.Cell>{tabe.reDate2}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.reLF2}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.reLF2 == null && (tabe.reSS2 == null || tabe.reSS2 == 0) ? null : tabe.reOR2 ? "N/A" : `${tabe.reSS2}${tabe.reSA2 ? tabe.reSA2 : ''}`}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.reLF2 == null && (tabe.reSS2 == null || tabe.reSS2 == 0) ? null : tabe.reOR2 ? "-" : tabe.reGE2}</Table.Cell>
                              <Table.Cell style={{borderLeftColor:'black'}}>{tabe.tmDate1}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.tmLF1}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.tmLF1 == null && (tabe.tmSS1 == null || tabe.tmSS1 == 0) ? null : tabe.tmOR1 ? "N/A" : `${tabe.tmSS1}${tabe.tmSA1 ? tabe.tmSA1 : ''}`}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.tmLF1 == null && (tabe.tmSS1 == null || tabe.tmSS1 == 0) ? null : tabe.tmOR1 ? "-" : tabe.tmGE1}{tabe.tmGE1 == null || tabe.tmGE2 == null ? null : tabe.tmGE2 < tabe.tmGE1 ? <Icon name='long arrow alternate up' color='green' style={{position:'absolute',fontSize:'1.3em'}} /> : tabe.tmGE2 > tabe.tmGE1 ? <Icon name='long arrow alternate down' color='red' style={{position:'absolute',fontSize:'1.3em'}} /> : null}</Table.Cell>
                              <Table.Cell>{tabe.tmDate2}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.tmLF2}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.tmLF2 == null && (tabe.tmSS2 == null || tabe.tmSS2 == 0) ? null : tabe.tmOR2 ? "N/A" : `${tabe.tmSS2}${tabe.tmSA2 ? tabe.tmSA2 : ''}`}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.tmLF2 == null && (tabe.tmSS2 == null || tabe.tmSS2 == 0) ? null : tabe.tmOR2 ? "-" : tabe.tmGE2}</Table.Cell>
                              <Table.Cell style={{borderLeftColor:'black'}}>{tabe.laDate1}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.laLF1}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.laLF1 == null && (tabe.laSS1 == null || tabe.laSS1 == 0) ? null : tabe.laOR1 ? "N/A" : `${tabe.laSS1}${tabe.laSA1 ? tabe.laSA1 : ''}`}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.laLF1 == null && (tabe.laSS1 == null || tabe.laSS1 == 0) ? null : tabe.laOR1 ? "-" : tabe.laGE1}{tabe.laGE1 == null || tabe.laGE2 == null ? null : tabe.laGE2 < tabe.laGE1 ? <Icon name='long arrow alternate up' color='green' style={{position:'absolute',fontSize:'1.3em'}} /> : tabe.laGE2 > tabe.laGE1 ? <Icon name='long arrow alternate down' color='red' style={{position:'absolute',fontSize:'1.3em'}} /> : null}</Table.Cell>
                              <Table.Cell>{tabe.laDate2}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.laLF2}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.laLF2 == null && (tabe.laSS2 == null || tabe.laSS2 == 0) ? null : tabe.laOR2 ? "N/A" : `${tabe.laSS2}${tabe.laSA2 ? tabe.laSA2 : ''}`}</Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}>{tabe.laLF2 == null && (tabe.laSS2 == null || tabe.laSS2 == 0) ? null : tabe.laOR2 ? "-" : tabe.laGE2}</Table.Cell>
                              <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                            </Table.Row>)
                            break
                          }
                      })
                    )}
                  </Fragment>
                ))}
              </Table.Body>
            </Table>
            <div className='footer'>
              <div className='left'>
                  {new Date().today() + " @ " + new Date().timeNow()}
              </div>
              <div className='right'>
                  {data.staffID} / {data.printID}
              </div>
              <div className='center'>{this.props.report.copyright}</div>
            </div>
          </Container>
        )
      }
    }
}

export default connect(
  (state: ApplicationState) => { return { report: state.reports.reports.tabe, connected: state.reports.connected, options: state.reports.options, settings: state.staff.settings } },
  { ...StaffStore.actionCreators, ...ReportsStore.actionCreators }
)(TabeHTML as any)
